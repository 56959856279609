import { Modal } from "@mui/material";

const TableModal = ({ handleShowModal, handleCloseModal, children }) => {

  return (
    <Modal
      open={handleShowModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>{children}</div>
    </Modal>
  );
};

export default TableModal;
