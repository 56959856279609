import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useTheme } from '@mui/material/styles'
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  Grid,
} from '@mui/material'
import { CropSquareSharp } from '@mui/icons-material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const MultiSelect = ({
  userList,
  label,
  emailId,
  setEmailId,
  setCancelEmail = null,
}) => {
  const theme = useTheme()
  //   const [emailId, setEmailId] = useState([])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setEmailId(value)
  }

  return (
    // <Grid item xs={3}>
    <FormControl fullWidth size='small'>
      <InputLabel
        id={`demo-multiple-chip-label-${label}`}
      >{`${label}`}</InputLabel>
      <Select
        labelId={`demo-multiple-chip-label-${label}`}
        id={`demo-multiple-chip-${label}`}
        multiple
        className='form_style'
        size='small'
        value={emailId}
        onChange={(e) => handleChange(e)}
        input={
          <OutlinedInput
            id={`select-multiple-chip-${label}`}
            label={`${label}`}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected?.map((value) => (
              <>
                <Chip
                  key={value}
                  label={value}
                  onDelete={() => {
                    setCancelEmail && setCancelEmail((pre) => [...pre, value])
                    setEmailId(emailId.filter((item) => item !== value))
                  }}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                />
              </>
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {userList &&
          [...userList].reverse().map((email) => (
            <MenuItem
              key={email}
              value={String(email)}
              style={getStyles(email, emailId, theme)}
            >
              {email}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
    // </Grid>
  )
}

export default MultiSelect
