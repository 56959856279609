import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    id: null,
    role: 0,
    isAuthenticated: false,
    isLoading : false,
    subRole  : null,
    details : null,
    menu_list : null
  });
  

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
