import { driver } from "driver.js";
import "driver.js/dist/driver.css";
// import "./driver.css"

export const driverObj = driver({
  showProgress: true,
  // allowClose : false,
  overlayColor :"black",
  overlayOpacity :  0.9,
  popoverClass : "driverjs-theme",
  steps: [
    {
      element: "#profile",
      popover: {
        title: "Profile Menu",
        description:
          "Explore your profile menu to access various features and settings.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#notification",
      popover: {
        title: "All Notification",
        description:
          "View all your notifications here. Stay informed about updates and activities.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#CreateQuery",
      popover: {
        title: "Create Query",
        description:
          "Create a new query using this button. Let's walk you through the process.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#oneWay",
      popover: {
        title: "One Way Query",
        description:
          "Create a one-way query by clicking on this button. For one-way query you need to select only city flight from ",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#twoWay",
      popover: {
        title: "Create Two Way Query",
        description:
            "Create a Two-Way and Mult-icity query by clicking on this button. For two-way query you need to select only city flight from and Return Date.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#from",
      popover: {
        title: "Select Departure City",
        description:
          "Choose the departure city for your query.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#to",
      popover: {
        title: "Select Arrival City",
        description:
          "Choose the arrival city for your query.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#to2",
      popover: {
        title: "Select for Multicity",
        description:
          "Choose the city for multi-city query.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#departuredate",
      popover: {
        title: "Select Departure Date",
        description:
          "Pick the departure date for your query.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#returnDate",
      popover: {
        title: "Select Return Date",
        description:
          "Pick the return date for your query.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#passenger",
      popover: {
        title: "Add Passenger Details",
        description:
          "Click on this button to add passenger and other details details to your query.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#Profile",
      popover: {
        title: "Profile Details",
        description:
          "Review and update your profile details. Ensure your information is accurate.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#AllQuery",
      popover: {
        title: "All Query",
        description:
          "Access a list of all queries submitted. Stay organized and track your queries.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#MyBooking",
      popover: {
        title: "My Booking",
        description:
          "View and manage your bookings. Keep track of your reservations with ease.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#BookingPolicy",
      popover: {
        title: "Booking Policy",
        description:
          "Review the booking policy to understand terms and conditions. Stay informed.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#Terms",
      popover: {
        title: "Terms and Conditions",
        description:
          "Explore the terms and conditions for using this platform. Understand the guidelines.",
        side: "left",
        align: "start",
      },
    },
  ],
});
