import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import {
  AirplaneTicket,
  CurrencyRupee,
  PendingActions,
  Sell,
  Storefront,
} from "@mui/icons-material";

import { LineChart } from "@mui/x-charts";
import { List } from "@mui/material";
import DataCard from "../../../components/dashboard_layout/dash_component/DataCard";
import { useContext, useEffect, useState } from "react";
import { getDashboardApi } from "../../../services/admin.configService";
import { SnackbarContext } from "../../../context/Snackbar";
import NotificationListComp from "../../../components/header/NotificationListComp";
import { months } from "../../../utils/constant";

const FinDash = () => {

  const { notificationlist } = useContext(SnackbarContext);
  const [cardData, setCardData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  async function dash() {
    try {
      const res = await getDashboardApi();
      if (res !== 204 && res !== 401) {
        setCardData(res["card_data"]);
        setGraphData(res["graph_data"]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    dash();
  }, []);

  return (
    <>
      <Grid p={2} container spacing={1} bgcolor={'aliceblue'} style={{minHeight :"calc(100vh - 4rem)"}} >
        <Grid item md={2.4} xs={6}>
          <DataCard
            value={cardData && cardData[0]?.bookings}
            icon={AirplaneTicket}
            heading={"Bookings"}
            thisMonth={20}
            color="green"
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <DataCard
            value={80}
            icon={Storefront}
            heading={"Buying"}
            thisMonth={20}
            color="yellow"
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <DataCard
            value={cardData && cardData[0]?.revenue}
            icon={Sell}
            heading={"Revenue"}
            thisMonth={10}
            color="blue"
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <DataCard
            value={10}
            icon={CurrencyRupee}
            heading={"Profit"}
            thisMonth={6}
            color="green"
          />
        </Grid>
        <Grid item md={2.4} xs={12}>
          <DataCard
            value={cardData && cardData[0]?.pending}
            icon={PendingActions}
            heading={"Payment Pending"}
            color="grey"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LineChart
            xAxis={[
              {
                scaleType: "point",
                data: graphData?.map((item)=>months[item.month-1]),
                dataKey: "month",
              },
            ]}
            series={[
              {
                data: graphData?.map((item) => item["bookings"]),
                label: "Bookings",
                color: "#335276",
                area: true,
              },
            ]}
            height={250}
            dataset={graphData}
          />
          <LineChart
            xAxis={[
              {
                scaleType: "point",
                data: graphData?.map((item)=>months[item.month-1]),
                dataKey: "month",
              },
            ]}
            series={[
              {
                data: graphData?.map((item) => item["revenue"]),
                label: "Selling",
                color: "orange",
                area: true,
              },
            ]}
            height={250}
            dataset={graphData}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LineChart
            xAxis={[
              {
                scaleType: "point",
                data: graphData?.map((item)=>months[item.month-1]),
                dataKey: "month",
              },
            ]}
            series={[
              {
                data: graphData?.map((item) => item["buying"]),
                label: "Buying",
                color: "rgb(85, 105, 255)",
                area: true,
              },
            ]}
            height={250}
            dataset={graphData}
          />
          <LineChart
            xAxis={[
              {
                scaleType: "point",
                data: graphData?.map((item)=>months[item.month-1]),
                dataKey: "month",
              },
            ]}
            series={[
              {
                data: graphData?.map((item) => item["profit"]),
                label: "Profit",
                color: "green",
                area: true,
              },
            ]}
            height={250}
            dataset={graphData}
          />
        </Grid>
        {/* <Grid item md={4} xs={12}>
          <Card sx={{ height: "450px", width: "100%",overflow:"auto" }}>
            <CardHeader
              title={<Typography variant="h6">Recents</Typography>}
            ></CardHeader>
            <CardContent sx={{ padding: 1 }}>
              <List>
                {notificationlist.length > 0 ? (
                  notificationlist.map((item, index) => (
                    <div key={index}>
                      <NotificationListComp item={item} />
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "20px !mportant",
                      flexDirection: "column",
                      fontSize: "26px",
                      color: "rgba(0,0,0,.4)",
                      marginTop: "50px",
                    }}
                  >
                    <p>
                      <img
                        src="../images/wolf.svg"
                        alt="Avatar"
                        width={138}
                        height={130}
                        style={{ borderRadius: "50%" }}
                      />
                    </p>
                    <p style={{ color: "#172B4D" }}>No notification</p>
                  </div>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </>
  );
};

export default FinDash;
