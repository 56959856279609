// import Link from 'next/link'
import * as React from 'react'
// import { Plane, Briefcase } from 'lucide-react';
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
  Grid,
  IconButton,
} from '@mui/material'
import { AirplanemodeActiveOutlined, CasesTwoTone } from '@mui/icons-material'
import Link from '@mui/material/Link'
import DashboardLayout from '../dashboard_layout/DashboardLayout'
import { alpha, styled } from '@mui/material/styles'
const ODD_OPACITY = 0.2
export default function Managepaylinks() {
  return (
    <DashboardLayout
      heading='Manage Flights'
      pathName='Manage Flights'
      button={false}
    >
      <div
        style={{
          padding: '24px',
          maxWidth: '1200px',
          margin: '0 auto',
          background: '#f6f9fc',
        }}
      >
        <Typography variant='h5' component='h1' gutterBottom></Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Link href='/payment-link' passHref>
              <Card
                className='plan'
                sx={{
                  '&:hover': {
                    boxShadow: 10,
                    backgroundColor: alpha('#FFFF00', ODD_OPACITY),
                    '@media (hover: none)': {
                      backgroundColor: 'transparent',
                    },
                  },
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

                  transition: 'box-shadow 0.3s',
                }}
              >
                <CardHeader
                  className='inner'
                  avatar={
                    <IconButton>
                      <CasesTwoTone className='h-6 w-6' />
                    </IconButton>
                  }
                  title={
                    <Typography variant='h6' sx={{ color: '#184E81' }}>
                      Generate Payment Links
                    </Typography>
                  }
                  subheader='Manage and view all payment links'
                  subheaderTypographyProps={{ fontWeight: 500 }}
                />
                <CardContent>
                  <Typography sx={{ fontWeight: 500 }}>
                    Control Copy Link, Delete link, view link status and
                    Generate Links.
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  )
}
