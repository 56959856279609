import React from 'react'
import Card from '@mui/material/Card'
import { Create } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Smtplisting from './Smtplisting'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import SMTPConfigForm from './SmtpConfig'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  }

const Smtpmaindash = () => {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    // const handleClose = () => setOpen(false)
  
    return (
      <Card sx={{ height: '100%' }} elevation={0}>
        <DashboardLayout
          heading='SMTP Details'
          pathName='SMTP Details'
          button={true}
          onClick={handleOpen}
          btnText={'Create'}
          btnIcon={<Create />}
        >
          <Smtplisting open={open} setOpen={setOpen} />
        </DashboardLayout>
       
      </Card>
    )
  }
export default Smtpmaindash