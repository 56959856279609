import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/header";

const Layout = () =>{ 
    const {pathname} = useLocation();
    return (
        <main>
            {(pathname  !== '/login' && pathname !== '/signup' && pathname !== '/admin') && 
            <Header/>
            }
            <Outlet/>
        </main>
    )
}

export default Layout