import React, { Suspense } from 'react'
import CustomLinearProgress from '../mui/CustomLinearProgress'

const Loadable = (Component) => (props)=>{
  return (
    <Suspense fallback={<CustomLinearProgress progress={100}/>}>
      <Component {...props} />
    </Suspense>
  )
}

export default Loadable