import React from 'react'
import './policystyle.css'

const Shippingpolicy = () => {
  return (
    <>
    <header className='bg-gray-900 py-6 px-4 md:px-6'>
      <div className='container1 mx-auto'>
        <h1 className='text-2xl font-bold text-white'>Refund and Cancellation Policy </h1>
      </div>
    </header>
    <div className='container1 mx-auto my-12 px-4 md:px-6'>
      <div className='grid grid-cols-1 md:grid-cols-[200px_1fr] gap-8'>
        <div className='space-y-12'>
          <section id='introduction'>
            <h2 className='text-2xl font-bold mb-4'>Shipping Policy</h2>
            <p className='text-gray-700 dark:text-gray-400'>
            At <strong>Zeal Global Services Limited</strong>, we want to remind you of the essential moments before your flight with Azerbaijan Airlines:
Please ensure you have all the necessary documents, visas, and travel permits under the transportation regulations.
please familiarize yourself with the requirements for documents and the laws of the country of departure, destination, and transit, as well as follow them.
The airline is always ready to assist you in this process to the best of its competence but is not responsible for failure to inform you of the above requirements or your non-compliance with them.

            </p>
          </section>
          
         
       
          
        </div>
      </div>
    </div>
  </>
  )
}

export default Shippingpolicy