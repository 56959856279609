import React, { useContext, useEffect } from 'react'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'

import Chargehead from './Chargeheadlisting'
import {
  Cancel,
  Create,
  FlightLand,
  FlightTakeoff,
  Save,
} from '@mui/icons-material'
import {
  Card,
  IconButton,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { handleChangeGlobal } from '../../../utils/common'
import {
  getAirport,
  getChargehead,
  getCreateChargeHead,
} from '../../../services/configService'
import { SnackbarContext } from '../../../context/Snackbar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { getChargesApi } from '../../../services/admin.configService'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
}

const Chargeheadmain = () => {
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState({})
  const [flightFrom, setFlightFrom] = React.useState('')
  const [flightTo, setFLightTo] = React.useState('')
  const [flightFromCode, setFlightFromCode] = React.useState('')
  const [flightToCode, setFlightToCode] = React.useState('')
  const [airport, setAirport] = React.useState([])
  const [fieldError, setFieldError] = React.useState({})
  const [excluded, setExcluded] = React.useState('')
  const [tripType, setTripType] = React.useState('one way')
  const [chargelist, setChargelist] = React.useState([])
  const [isUpdate, setIsUpdate] = React.useState(false)
  const [createCharge, setCreateCharge] = React.useState({
    charge_master_id: '',
    charge_master_code: '',
    amount: '',
    effective_date: '',
  })
  const handleChargeId = (event) => {
    setCreateCharge((prevState) => ({
      ...prevState,
      charge_master_id: event.target.value,
    }))
  }

  const filteredChargeList = chargelist.filter(
    (item) => item?.id === createCharge?.charge_master_id
  )

  const handleChargecode = (event) => {
    setCreateCharge((prevState) => ({
      ...prevState,
      charge_master_code: event.target.value,
    }))
  }

  const handleOpen = () => {
    setOpen(true)
    getChargeList()
  }
  const handleClose = () => setOpen(false)

  const handleCheckboxChange = (e) => {
    setExcluded(e.target.checked)
  }
  console.log('Excluded', excluded)
  async function getAirportsData() {
    try {
      const res = await getAirport()
      if (res !== 204) setAirport(res.data)
      console.log('@@', res.data)
      if (res === 204) {
        setSeverity('warning')
        setMes('No airport data found')
        setOn(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdate = async () => {
    const sendData = {
      charge_master_id: createCharge.charge_master_id,
      charge_master_code: createCharge.charge_master_code,
      origin: flightFrom,
      destination: flightTo,
      is_return: tripType === 'one way' ? 0 : 1,
      amount: createCharge.amount,
      is_excluded: excluded === true ? 1 : 0,
      effective_date: dayjs(createCharge.effective_date).format('YYYY-MM-DD'),
    }

    try {
      const res = await getCreateChargeHead(sendData, setError)
      console.log('res', res)
      if (res !== 406) {
        setOpen(false)
        // getBookingList()
        setOn(true)
        setIsUpdate(!isUpdate)
        setSeverity('success')
        setMes('Created successfully')
      }
      // const res = await handlePreloginpayment(sendData.total_fare, sendData.billing_phone, sendData.billing_name)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }
  async function getChargeList() {
    try {
      const res = await getChargesApi()
      if (res !== 204 && res !== 404) {
        setChargelist(res?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAirportsData()
  }, [])

  return (
    <>
      <DashboardLayout
        heading={'Charge Head'}
        btnIcon={<Create />}
        onClick={handleOpen}
        button={true}
        btnText={'Create'}
        pathName={'Charge Head'}
      >
        <Chargehead isUpdate={isUpdate} />
      </DashboardLayout>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography
                id='modal-modal-title'
                sx={{ fontSize: '19px', fontWeight: 510 }}
              >
                Charge Head
              </Typography>
              <Typography
                id='modal-modal-description'
                sx={{ fontSize: '15px', color: '#A9A9A9' }}
              >
                Create Charge Head
              </Typography>
            </div>
            <IconButton aria-label='delete' onClick={handleClose}>
              <Cancel />
            </IconButton>
          </div>

          <CardContent sx={{ background: '#edf1f4', borderRadius: 2, mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item md={7}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={tripType}
                    onChange={(e) => {
                      setTripType(e.target.value)
                    }}
                  >
                    <FormControlLabel
                      value='one way'
                      control={<Radio />}
                      label={
                        <span style={{ fontWeight: 505, fontSize: '15px' }}>
                          One Way
                        </span>
                      }
                    />
                    <FormControlLabel
                      value='Return Trip'
                      control={<Radio />}
                      label={
                        <span style={{ fontWeight: 505, fontSize: '15px' }}>
                          Return Trip
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        // checked={
                        //   defaultChecked.includes(item.menu_id) ||
                        //   mapData.some(
                        //     (map) =>
                        //       map.role_view === 1 &&
                        //       map.menu_id === item.menu_id
                        //   )
                        // }
                      />
                    }
                    label='Excluded'
                    onClick={(e) => handleCheckboxChange(e)}
                  />
                </FormGroup>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth size='small'>
                  <InputLabel
                    id='demo-simple-select-label'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FlightTakeoff sx={{ mr: 1 }} /> From where?
                  </InputLabel>
                  <Select
                    className='form_style'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={flightFrom}
                    label={
                      <span>
                        <FlightTakeoff sx={{ mr: 1 }} /> From where?
                      </span>
                    }
                    onChange={(e) => {
                      const selectedAirport = airport.find(
                        (item) => item.id === e.target.value
                      )
                      setFlightFromCode(selectedAirport?.code)
                      setFlightFrom(e.target.value)
                      setFieldError((prev) => ({
                        ...prev,
                        flightFrom: '',
                      }))
                    }}
                    error={fieldError && fieldError.flightFrom}
                    renderValue={(selected) => {
                      const selectedAirport = airport.find(
                        (item) => item.id === selected
                      )
                      return selectedAirport
                        ? `${selectedAirport.cityName} (${selectedAirport.code}) `
                        : 'no data'
                    }}
                  >
                    {airport.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        sx={{
                          fontSize: '14px',
                          fontWeight: '500',
                          padding: '20px',
                        }}
                      >
                        <Grid
                          container
                          direction='column'
                          // justifyContent='space-evenly'
                          p={0.5}
                        >
                          <Grid item>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                              }}
                            >
                              <Typography
                                gutterBottom
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  color: '#318CE7',
                                }}
                                component='div'
                              >
                                {item.cityName}, {item.countryCode}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  color: '#318CE7',
                                }}
                              >
                                {item.code}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item>
                            <Typography variant='body2' component='div'>
                              {item.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth size='small'>
                  <InputLabel
                    id='demo-simple-select-label'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FlightLand sx={{ mr: 1 }} /> Where to?
                  </InputLabel>
                  <Select
                    className='form_style'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    // value={age}
                    label={
                      <span>
                        <FlightLand sx={{ mr: 1 }} /> Where to?
                      </span>
                    }
                    value={flightTo}
                    onChange={(e) => {
                      const selectedAirport = airport.find(
                        (item) => item.id === e.target.value
                      )
                      setFlightToCode(selectedAirport?.code)
                      setFLightTo(e.target.value)
                      setFieldError((prev) => ({
                        ...prev,
                        flightTo: '',
                      }))
                    }}
                    renderValue={() => {
                      const selectedAirport = airport.find(
                        (item) => item.id === flightTo
                      )
                      return selectedAirport
                        ? `${selectedAirport.cityName} (${selectedAirport.code}) `
                        : 'no data'
                    }}
                  >
                    {airport
                      .filter((airport) => airport.code !== flightFromCode)
                      .map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            padding: '20px',
                          }}
                        >
                          <Grid
                            container
                            direction='column'
                            // justifyContent='space-evenly'
                            p={0.5}
                          >
                            <Grid item>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexDirection: 'row',
                                }}
                              >
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    color: '#318CE7',
                                  }}
                                  component='div'
                                >
                                  {item.cityName}, {item.countryCode}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    color: '#318CE7',
                                  }}
                                >
                                  {item.code}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item>
                              <Typography variant='body2' component='div'>
                                {item.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                  </Select>
                  {fieldError.flightTo && (
                    <TextField error helperText='Please select Flight To' />
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                {/* <TextField
                  className='form_style'
                  label='Charge Master Id'
                  variant='outlined'
                  name='charge_master_id'
                  size='small'
                  value={createCharge?.charge_master_id}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setCreateCharge,

                      'charge_master_id'
                    )
                  }
                  fullWidth
                /> */}
                {/* <FormControl fullWidth variant='outlined' size='small'>
                  <InputLabel id='demo-simple-select-label'>Charge Master Id</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    className='form_style'
                    value={createCharge?.charge_master_id}
                    onChange={(e) =>
                      handleChangeGlobal(e, setCreateCharge, 'charge_master_id')
                    }
                    label='Charge Master Id'
                  >
                    <MenuItem value='option1'>Option 1</MenuItem>
                    <MenuItem value='option2'>Option 2</MenuItem>
                  </Select>
                </FormControl> */}
                <FormControl fullWidth variant='outlined' size='small'>
                  <InputLabel id='demo-simple-select-label'>
                    Charge Master Id
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    className='form_style'
                    value={createCharge?.charge_master_id}
                    label='Charge Master Id'
                    onChange={handleChargeId}
                  >
                    {chargelist.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.charge_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth variant='outlined' size='small'>
                  <InputLabel id='demo-simple-select-label'>
                    Charge Master Code
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    className='form_style'
                    value={createCharge?.charge_master_code}
                    label='Charge Master Code'
                    onChange={handleChargecode}
                  >
                    {filteredChargeList.map((item) => (
                      <MenuItem key={item?.id} value={item?.charge_code}>
                        {item?.charge_code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <TextField
                  className='form_style'
                  label='Amount'
                  variant='outlined'
                  name='amount'
                  size='small'
                  value={createCharge?.amount}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setCreateCharge,

                      'amount'
                    )
                  }
                  fullWidth
                />
              </Grid>

              <Grid item md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Effective Date'
                    className='form_style'
                    name='effective_date'
                    format='DD-MM-YYYY'
                    value={dayjs(createCharge?.effective_date)}
                    onChange={(newDate) => {
                      setCreateCharge((prevDetails) => ({
                        ...prevDetails,
                        effective_date: newDate,
                      }))
                    }}
                    // error={error && error['starting_from']}
                    slotProps={{
                      textField: {
                        size: 'small',
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </CardContent>
          <Button
            onClick={handleUpdate}
            startIcon={<Save />}
            variant='contained'
            sx={{ background: '#01357E', float: 'right', mt: 1 }}
          >
            save
          </Button>
        </Card>
      </Modal>
    </>
  )
}

export default Chargeheadmain
