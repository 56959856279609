import React from 'react'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'
import StripedGrid from './Mainbookinglist'

const Customerbooking = () => {
  return (
    <>
      <DashboardLayout heading='Customer Booking' pathName='Customer Booking'>
        <StripedGrid />
      </DashboardLayout>
    </>
  )
}

export default Customerbooking
