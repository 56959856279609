import React, { useState } from 'react'
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material'
import {
  Dns as DnsIcon,
  Router as RouterIcon,
  Person as PersonIcon,
  Lock as LockIcon,
  Email,
  VisibilityOff,
  Visibility,
} from '@mui/icons-material'
import { createSMTPApi, updateSMTP } from '../../../../services/configService'
import { SnackbarContext } from '../../../../context/Snackbar'

export default function SMTPConfigForm({
  onSave,
  onCancel,
  updatedRowData,
  setRefreshData,
  refreshData,
  setUpdatedRowData,
}) {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [showPassword, setShowPassword] = useState(false)

  const [error, setError] = useState([])
  const [smtpConfig, setSmtpConfig] = useState({
    email: '' || updatedRowData?.email,
    hostName: '' || updatedRowData?.mail_host,
    port: '' || updatedRowData?.mail_port,
    userId: '' || updatedRowData?.smtp_userid,
    password: '' || updatedRowData?.smtp_userpass,
    isPrimary: false || updatedRowData?.status === 'active' ? true : false,
  })
  console.log('error', error)
  const handleChange = (event) => {
    const { name, value, checked } = event.target
    setSmtpConfig((prevConfig) => ({
      ...prevConfig,
      [name]: name === 'isPrimary' ? checked : value,
    }))
  }
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    // onSave({ ...smtpConfig, id: Date.now().toString() })
    const sendData = {
      smtp_userid: smtpConfig.userId,
      mail_host: smtpConfig.hostName,
      mail_port: smtpConfig.port,
      email: smtpConfig.email,
      smtp_userpass: smtpConfig.password,
      status: smtpConfig.isPrimary === true ? 'active' : 'inactive',
    }

    try {
      const res = await createSMTPApi(sendData, setError)
      if (res.status !== 204 && res.status !== 406) {
        console.log('res.ssssss', res, res.status)
        setSeverity('success')
        setMes('Created Successfully')
        setOn(true)

        setSmtpConfig({
          email: '',
          hostName: '',
          port: '',
          userId: '',
          password: '',
          isPrimary: false,
        })
        onCancel()
        setRefreshData(true)
      }
    } catch (error) {
      console.log(error)
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }
  const handleUpdate = async (event) => {
    event.preventDefault()
    // onSave({ ...smtpConfig, id: Date.now().toString() })
    const sendData = {
      id: updatedRowData.id,
      mail_host: smtpConfig.hostName,
      mail_port: smtpConfig.port,
      email: smtpConfig.email,
      smtp_userpass: smtpConfig.password,
      status: smtpConfig.isPrimary === true ? 'active' : 'inactive',
      smtp_userid: smtpConfig.userId,
    }

    try {
      const res = await updateSMTP(sendData, setError)
      if (res.status !== 204 && res.status !== 406) {
        setSeverity('success')
        setMes('Updated Successfully')
        setOn(true)

        setSmtpConfig({
          email: '',
          hostName: '',
          port: '',
          userId: '',
          password: '',
          isPrimary: false,
        })
        onCancel()
        setRefreshData(true)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }

  console.log('updatedRowData', updatedRowData)

  React.useEffect(() => {
    if (error.duplicate_entry) {
      setSeverity('error')
      setMes('SMTP Configration already exists')
      setOn(true)
    }
  }, [error])
  console.log(smtpConfig, 'smtpConfig')

  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 1 }}>
      <CardHeader
        title='SMTP Configuration'
        subheader='Enter your SMTP server details'
        sx={{
          bgcolor: '#54616d',
          color: 'primary.contrastText',
          '& .MuiCardHeader-subheader': {
            color: 'primary.contrastText',
          },
        }}
      />
      <CardContent>
        <Box component='form' sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label='SMTP Host Name'
                name='hostName'
                value={smtpConfig.hostName}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <DnsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='Email'
                name='email'
                value={smtpConfig.email}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='SMTP Port'
                name='port'
                value={smtpConfig.port}
                onChange={handleChange}
                fullWidth
                required
                type='number'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RouterIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='SMTP User ID'
                name='userId'
                value={smtpConfig.userId}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='SMTP Password'
                name='password'
                value={smtpConfig.password}
                onChange={handleChange}
                fullWidth
                required
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleTogglePasswordVisibility}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={smtpConfig.isPrimary}
                    onChange={handleChange}
                    name='isPrimary'
                    color='primary'
                    // defaultChecked={
                    //   updatedRowData.status === 'active' ? true : false
                    // }
                  />
                }
                label='Primary SMTP'
              />
            </Grid>
          </Grid>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}
          >
            <Button variant='outlined' color='secondary' onClick={onCancel}>
              Cancel
            </Button>
            {updatedRowData ? (
              <Button
                type='submit'
                variant='outlined'
                onClick={handleUpdate}
                color='primary'
              >
                Update Configuration
              </Button>
            ) : (
              <Button
                type='submit'
                variant='outlined'
                onClick={handleSubmit}
                color='primary'
              >
                Save Configuration
              </Button>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
