import * as React from 'react'
import dayjs from 'dayjs'
import Badge from '@mui/material/Badge'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import {
  Accessible,
  AirplanemodeInactive,
  CheckBox,
  FiberManualRecord,
  NotAccessible,
} from '@mui/icons-material'
import { getFlightListApi } from '../../services/admin.configService'
import { availableStatus } from '../../services/configService'

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min)
}

function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth()
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      )

      resolve({ daysToHighlight })
    }, 500)

    signal.onabort = () => {
      clearTimeout(timeout)
      reject(new DOMException('aborted', 'AbortError'))
    }
  })
}
const date = new Date()
date.toLocaleDateString()
const initialValue = dayjs(date)

export default function Usercalendar() {
  const requestAbortController = React.useRef(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15])
  const [flightList, setFlightList] = React.useState([])
  const [flightavailable, setFlightavailable] = React.useState([])
  const [flightFromCode, setFlightFromCode] = React.useState('DEL')
  const [selectedFlightCode, setSelectedFlightCode] = React.useState('')
  const fetchHighlightedDays = (date) => {
    const controller = new AbortController()
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight)
        setIsLoading(false)
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== 'AbortError') {
          throw error
        }
      })

    requestAbortController.current = controller
  }

  const getFlightList = async () => {
    try {
      const res = await getFlightListApi()
      if (res !== 204 && res !== 401) {
        setFlightList(res?.data)
      }
    } catch (error) {
      alert(error.message)
    }
  }

  console.log('@!@#@#', flightList, '@@@@', flightavailable)

  const disableDateFrom = (calDate) => {
    // let days = flightList
    //   .filter((item) => item.flight_departure_from == flightFromCode)[0]
    //   ?.flight_days.split(',')
    //   .map(Number)

      let days = flightList
      .filter((item) => item.flight_departure_from == flightFromCode)[0]
      ?.day_time.map((item)=> item.flight_day)

    setFlightavailable(
      flightList.filter(
        (item) => item.flight_departure_from == flightFromCode
      )[0]?.flight_available
    )

    const flatDays = days?.flat(1)
    console.log("flatDays",flatDays);
    const currentDay = new Date(calDate).getDay()

    return !flatDays?.includes(currentDay)
  }

  function ServerDay(props) {
    const {
      highlightedDays = [],
      day,
      minDate,
      outsideCurrentMonth,
      ...other
    } = props

    const isOutsideCurrentMonth = outsideCurrentMonth
      ? '1px solid #ccc'
      : 'none'

    return (
      <Badge
        key={props.day.toString()}
        overlap='circular'
        // badgeContent={
        //   flightavailable?.find(
        //     (item) => item.date == dayjs(day).format('DD-MM-YYYY')
        //   )?.is_available ? (
        //     <>
        //       {!outsideCurrentMonth && (
        //         // <FiberManualRecord fontSize='small' color='success' />
        //         ""
        //       )}
        //     </>
        //   ) : (
        //     <>
        //       {outsideCurrentMonth
        //         ? ''
        //         : flightavailable?.find(
        //             (item) => item.date == dayjs(day).format('DD-MM-YYYY')
        //           )? "": <AirplanemodeInactive fontSize='small' sx={{color:"#696969"}}/>}
        //     </>
        //   )
        // }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          className={`abc ${outsideCurrentMonth && 'disabled'}`}
          onClick={(e, v) => console.log({ ...e.target })}
          style={{
            // padding: '5px', // Adjust day width as needed
            fontSize: '15px', // Adjust font size as needed
            fontWeight: 510,
            borderRadius: '50%',
           
            // border: isOutsideCurrentMonth, // Apply grey border for days outside current month
            background: `${
              flightavailable?.find(
                (item) => item.date == dayjs(day).format('DD-MM-YYYY')
              )?.is_available
                ? '#03C03C'
                : !outsideCurrentMonth && '#ff363699'
            }`,
          }}
        />
      </Badge>
    )
  }

  React.useEffect(() => {
    fetchHighlightedDays(initialValue)
    getFlightList()
    // abort request on unmount
    return () => requestAbortController.current?.abort()
  }, [])

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort()
    }

    setIsLoading(true)
    setHighlightedDays([])
    fetchHighlightedDays(date)
  }

  return (
    <div style={{background:'#dce3ea'}}>
      <div
        style={{
          display: 'flex',
          marginBottom: '10px',
          marginTop: '10px',
          gap: '5px',
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>
            Select Origin
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            // className='form_style'
            value={flightFromCode}
            label='Select Origin'
            onChange={(e) => {
              setFlightFromCode(e.target.value)
            }}
            size='small'
          >
            <MenuItem value={'DEL'}>Delhi</MenuItem>
            <MenuItem value={'BOM'}>Mumbai</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: '20px' }} fullWidth size='small'>
          <TextField
            id='outlined-basic'
            label='Destination'
            variant='outlined'
            value={'Baku'}
            size='small'
          />
        </FormControl>
      </div>
      <div style={{ display:"flex", gap:"5px" }}>
        <Chip
          label='Seat Available'
          sx={{ background: '#50C878', color:"black", fontWeight: 500 }}
          icon={<FiberManualRecord fontSize='small' color='success' />}
        />
        <Chip
          label='Seats Sold'
          sx={{ background: 'rgb(255 172 176)', color:"black", fontWeight: 500 }}
          icon={<FiberManualRecord fontSize='small' color='error' />}
        />
        <Chip
          label='Flight Not Available'
          sx={{ background: 'rgba(129, 129, 129, 0.425)  ', color: 'black', fontWeight: 550 }}
          // icon={<AirplanemodeInactive fontSize='small'  />}
        />
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
         views={['day']}
          defaultValue={initialValue}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays,
            },
          }}
          shouldDisableDate={(date) => disableDateFrom(date)}
          minDate={dayjs(date)}
          className='customdays'
          sx={{
            marginTop: '10px',
            width: '80%',
            boxShadow:"rgba(0, 0, 0, 0.1) 0px 4px 12px",
            background: 'white',
            paddingBottom: '20px',
            '& .MuiDayCalendar-header': {
              gap: '1px', // Add a gap of 60px to MuiDayCalendar-header
            },
           
          }}
        />
      </LocalizationProvider>
    </div>
  )
}
