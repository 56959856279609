import React from 'react'
import './policystyle.css'

const Refund = () => {
  return (
    <>
      <header className='bg-gray-900 py-6 px-4 md:px-6'>
        <div className='container1 mx-auto'>
          <h1 className='text-2xl font-bold text-white'>Refund and Cancellation Policy </h1>
        </div>
      </header>
      <div className='container1 mx-auto my-12 px-4 md:px-6'>
        <div className='grid grid-cols-1 md:grid-cols-[200px_1fr] gap-8'>
          <div className='space-y-12'>
            <section id='introduction'>
              <h2 className='text-2xl font-bold mb-4'>Economy class Information</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                <strong>Service Class:</strong> Economy
                <br />
                <strong>Booking Classes:</strong> “Y”
                <br />
                <strong>Fare basis:</strong> “YOWN”, “YOWCH” (CH - children) or
                “YOWİN” (IN - infant)
                <br />
                <strong>Ticket refund:</strong>
                <br />
                Before the flight: penalty charge 10% of fare amount
                <br />
                After departure: penalty charge 25% of fare amount
                <br />
                Refund Amount will be paid within 7 Working Days
                <br/>
                <strong>Rebooking/Revalidate:</strong>
                <br />
                Before the flight: penalty charge 10% of fare amount
                <br />
                After departure: penalty charge 25% of fare amount
                <br />
                <strong>Issuance of “Open date” ticket:</strong> not allowed
                <br />
                <strong>Free baggage allowance:</strong> one pc (23 kg), hand
                luggage one pc (10 kg)
                <br />
                <strong>Combination of fares:</strong> not allowed
                <br />
                <strong>Maximum effectiveness:</strong> 1 year
                <br />
                <strong>Ticket refund:</strong> unused taxes and fees are
                refundable
                <br />
                <strong>Discounts for Child and Infant:</strong> For all fares
                shown above, for children aged 2-12 - 25% “CHD” discount is
                applicable. For infants aged 0-2, 90% “INF” discount is
                applicable.
                <br />
                For infants aged 0-2 traveling with “Classic” fare, baggage
                allowance is one pc not exceeding 10 kg.
                <br />
                <strong>Taxes and fee:</strong> are applied according to taxes
                and fees published in the GDS.
                <br />
                <strong>“Before departure”:</strong> a passenger who wishes to
                rebook or to refund ticket more than 60 minutes before departure
                is considered a passenger who has arrived for check-in.
                <br />
                <strong>“VOID”:</strong> Cancelation of a ticket is allowed only
                within 3 (three) hours from the moment of ticket issuance. Also,
                it can be cancelled with the “VOID” status if it is issued more
                than 3 hours before departure. Cancellation with the “VOID”
                status of “NO SHOW” status tickets is strictly prohibited. In
                such cases, only “Refund” is allowed by deducting an appropriate
                penalty.
                <br />
                <strong>“After departure” (NO SHOW):</strong> a passenger who
                wishes to rebook or to refund ticket less than 60 minutes before
                departure is considered a passenger who has not arrived for
                check-in
                <br />
                <strong>“Maximum Effectiveness”:</strong> Validity of the
                ticket. When combining fares with different maximum
                effectiveness, a longer “maximum effectiveness” is applied.
                <br />
                <strong>Ticket refund / Rebooking and Revalidate:</strong>{' '}
                Refund and rebooking penalties are applied to each flight
                segment in accordance with the fare rules.
                <br />
                If a ticket is changed from one class or brand to a higher
                class/brand through the "UPGRADE" procedure (with additional
                payment), the refund/rebooking/revalidate of the ticket must be
                carried out according to the following rules:
                <br />
                • Rebooking/Revalidating – according to the rules of new
                rebooked class.
                <br />
                • Ticket refund after full refund of fare difference paid for
                the “UPGRADE” procedure, the amount paid for the initial class
                is refunded to the passenger by deducting a penalty in
                accordance with the applicable rules of that class.
                <br />
                If a ticket is issued based on combination of two or several
                classes/brands:
                <br />
                • Rebooking/Revalidating operations are carried out according to
                the applicable rules of the relevant rebooking classes/brands by
                segments
                <br />
                • Unused Ticket refund is carried out in accordance with the
                rules applicable to the lower class/brand in the ticket
                <br />
                • Partially used ticket refund or revalidating rules are applied
                only to unused/actual flight segment
                <br />
                Changing the class/brand level is not allowed (within the same
                service class to a lower class)
                <br />
                Rebooking/Revalidating is carried out with the deduction of fare
                difference based on the class/brand available in GDS (Global
                Distribution System) and corresponding fare rules.
                <br />
                “Rerouting” is performed by charging the difference between the
                fares for the new route/direction and fees/charges and by
                charging a fee in the amount of 50 EUR for each direction
                (segment) (the brand used in the original ticket should be
                applied). The difference between the fares is not refundable.
                <br />
                When “REROUTING” to the different airports in the same city, the
                difference between the class and the fees/charges in Global
                Distribution System (GDS) should be chargeable. The difference
                between the fares is not refundable.
              </p>
            </section>
            
            <section id='definitions'>
              <h2 className='text-2xl font-bold mb-4'>
                Business class Information
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                <strong>Service Class:</strong> Business
                <br />
                <strong>Booking Classes:</strong> “C”
                <br />
                <strong>Fare basis:</strong> “COWIN”
                <br />
                <strong>Ticket refund:</strong>
                <br />
                Before the flight: penalty charge 10% of fare amount
                <br />
                After departure: penalty charge 25% of fare amount
                <br />
                Refund Amount will be paid within 7 Working Days
                <br/>
                <strong>Rebooking/Revalidate:</strong>
                <br />
                Before the flight: penalty charge 10% of fare amount
                <br />
                After departure: penalty charge 25% of fare amount
                <br />
                <strong>Free baggage allowance:</strong> one pc not exceeding 32
                kg, hand luggage one pc (10 kg)
                <br />
                <strong>Maximum effectiveness:</strong> 1 year
                <br />
                <strong>Ticket refund:</strong> unused taxes and fees are
                refundable
                <br />
                <strong>Discounts for Child and Infant:</strong> For all fares
                shown above, for children aged 2-12 - 25% “CHD” discount is
                applicable. For infants aged 0-2, 90% “INF” discount is
                applicable.
                <br />
                For infants aged 0-2 traveling with “Classic” fare, baggage
                allowance is one pc not exceeding 10 kg.
                <br />
                <strong>Taxes and fee:</strong> are applied according to taxes
                and fees published in the GDS.
                <br />
                <strong>“Before departure”:</strong> a passenger who wishes to
                rebook or to refund ticket more than 60 minutes before departure
                is considered a passenger who has arrived for check-in.
                <br />
                <strong>“VOID”:</strong> Cancelation of a ticket is allowed only
                within 3 (three) hours from the moment of ticket issuance. Also,
                it can be cancelled with the “VOID” status if it is issued more
                than 3 hours before departure. Cancellation with the “VOID”
                status of “NO SHOW” status tickets is strictly prohibited. In
                such cases, only “Refund” is allowed by deducting an appropriate
                penalty.
                <br />
                <strong>“After departure” (NO SHOW):</strong> a passenger who
                wishes to rebook or to refund ticket less than 60 minutes before
                departure is considered a passenger who has not arrived for
                check-in
                <br />
                <strong>“Maximum Effectiveness”:</strong> Validity of the
                ticket. When combining fares with different maximum
                effectiveness, a longer “maximum effectiveness” is applied.
                <br />
                <strong>Ticket refund / Rebooking and Revalidate:</strong>{' '}
                Refund and rebooking penalties are applied to each flight
                segment in accordance with the fare rules.
                <br />
                If a ticket is changed from one class or brand to a higher
                class/brand through the "UPGRADE" procedure (with additional
                payment), the refund/rebooking/revalidate of the ticket must be
                carried out according to the following rules:
                <br />
                • Rebooking/Revalidating – according to the rules of new
                rebooked class.
                <br />
                • Ticket refund after full refund of fare difference paid for
                the “UPGRADE” procedure, the amount paid for the initial class
                is refunded to the passenger by deducting a penalty in
                accordance with the applicable rules of that class.
                <br />
                If a ticket is issued based on combination of two or several
                classes/brands:
                <br />
                • Rebooking/Revalidating operations are carried out according to
                the applicable rules of the relevant rebooking classes/brands by
                segments
                <br />
                • Unused Ticket refund is carried out in accordance with the
                rules applicable to the lower class/brand in the ticket
                <br />
                • Partially used ticket refund or revalidating rules are applied
                only to unused/actual flight segment
                <br />
                Changing the class/brand level is not allowed (within the same
                service class to a lower class)
                <br />
                Rebooking/Revalidating is carried out with the deduction of fare
                difference based on the class/brand available in GDS (Global
                Distribution System) and corresponding fare rules.
                <br />
                “Rerouting” is performed by charging the difference between the
                fares for the new route/direction and fees/charges and by
                charging a fee in the amount of 50 EUR for each direction
                (segment) (the brand used in the original ticket should be
                applied). The difference between the fares is not refundable.
                <br />
                When “REROUTING” to the different airports in the same city, the
                difference between the class and the fees/charges in Global
                Distribution System (GDS) should be chargeable. The difference
                between the fares is not refundable.
              </p>
            </section>
         
            
          </div>
        </div>
      </div>
    </>
  )
}

export default Refund
