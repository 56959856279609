import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  Select,
  TextField,
  Button,
  Typography,
  Paper,
  IconButton,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { handleChangeGlobal } from '../../../../utils/common'
import { SnackbarContext } from '../../../../context/Snackbar'
import { Add, DeleteSweepTwoTone, EditTwoTone, Save } from '@mui/icons-material'
import MuiPhoneNumber from 'mui-phone-number'
import {
  subUserCreation,
  subUserDelete,
  subUserList,
  subUserUpdating,
} from '../../../../services/configService'
import useAuth from '../../../../hooks/useAuth'
import Carousel from 'react-material-ui-carousel'

const Subusercreation = ({ selectedrowdata, customerId }) => {
  const { auth } = useAuth()
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const [listData, setListData] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [saveid, setSaveid] = useState('')
  const [details, setDetails] = useState({
    f_name: '',
    l_name: '',
    email_id: '',
    mobile_no: '',
  })

  const handleChange = (e, v, e1) => {
    let x = e
    let num = x.replace(/\D/g, '')
    let dialCode = num.slice(0, 2)
    setDetails((pre) => ({
      ...pre,
      mobile_no: num,
      //   dialCode: dialCode,
    }))
  }
  console.log('selectedrowdata', selectedrowdata)

  const handleSubUserCreation = async () => {
    try {
      const sendData = {
        agent_id: selectedrowdata
          ? selectedrowdata?.id
          : customerId
          ? customerId
          : auth?.id,
        f_name: details.f_name,
        l_name: details.l_name,
        mobile_no: details.mobile_no,
        email_id: details.email_id,
      }
      const res = await subUserCreation(sendData)

      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Added Successfully')
        setOn(true)
        setDetails({
          f_name: '',
          l_name: '',
          email_id: '',
          mobile_no: '',
        })
        getSubuserListing()
      }
    } catch (error) {
      setSeverity('error')
      setMes('Please Filled Correct Details')
      setOn(true)
    }
  }

  const handleSubUserUpdate = async (subuser) => {
    try {
      const sendData = {
        agent_contact_id: subuser?.id,
        agent_id: subuser?.agent_id,
        f_name: details.f_name,
        l_name: details.l_name,
        mobile_no: details.mobile_no,
        email_id: details.email_id,
      }
      const res = await subUserUpdating(sendData)

      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Updated Successfully')
        setOn(true)
        setDetails({
          f_name: '',
          l_name: '',
          email_id: '',
          mobile_no: '',
        })
        setEditMode(false)
        getSubuserListing()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubUserDelete = async (subuser) => {
    try {
      const sendData = {
        agent_contact_id: subuser?.id,
      }
      const res = await subUserDelete(sendData)

      if (res !== 204 && res !== 406) {
        setSeverity('error')
        setMes('Deleted Successfully')
        setOn(true)
        setDetails({
          f_name: '',
          l_name: '',
          email_id: '',
          mobile_no: '',
        })
        setEditMode(false)

        getSubuserListing()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSubuserListing = async () => {
    try {
      const sendData = {
        type: 'Active',
        agent_id: selectedrowdata
        ? selectedrowdata?.id
        : customerId
        ? customerId
        : auth?.id,
      }
      const res = await subUserList(sendData)

      if (res !== 204 && res !== 406) {
        setListData(res?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = (id) => {
    // Find the passenger by ID and populate the form with their details for editing
    setSaveid(id)
    const subUserToEdit = listData?.find((item) => item?.id === id)
    if (subUserToEdit) {
      setDetails({ ...subUserToEdit })
      setEditMode(true)
    }
  }

  useEffect(() => {
    getSubuserListing()
  }, [])
  console.log('deṭails', listData)
  console.log('deṭails', details)
  return (
    <Card sx={{ height: '80vh' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item md={5} spacing={2}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={8} xs={8}>
                  <div>
                    <Typography
                      id='modal-modal-title'
                      sx={{ fontSize: '18px', fontWeight: 510 }}
                    >
                      ADD SUB-USER
                    </Typography>
                    <Typography
                      id='modal-modal-description'
                      sx={{ fontSize: '15px', color: '#A9A9A9' }}
                    >
                      Fill in the details for a new sub-user account.
                    </Typography>
                  </div>
                </Grid>

                <Grid item md={4} xs={4}>
                  <Button
                    variant='contained'
                    startIcon={<Add />}
                    onClick={() => handleSubUserCreation()}
                    sx={{ background: '#01357E', float: 'right' }}
                    disabled={editMode}
                  >
                    ADD
                  </Button>
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField
                    id='outlined-basic'
                    label='First name'
                    variant='outlined'
                    size='small'
                    name='f_name'
                    value={details.f_name}
                    onChange={(e) =>
                      handleChangeGlobal(e, setDetails, 'f_name')
                    }
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item md={6} xs={6}>
                  <TextField
                    id='outlined-basic'
                    label='Last name'
                    variant='outlined'
                    size='small'
                    name='l_name'
                    value={details.l_name}
                    onChange={(e) =>
                      handleChangeGlobal(e, setDetails, 'l_name')
                    }
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    id='outlined-basic'
                    label='Email address'
                    variant='outlined'
                    size='small'
                    name='email_id'
                    value={details.email_id}
                    onChange={(e) =>
                      handleChangeGlobal(e, setDetails, 'email_id')
                    }
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <MuiPhoneNumber
                    //   className="form_style"
                    variant='outlined'
                    name='mobile_no'
                    value={details.mobile_no}
                    defaultCountry={'in'}
                    onChange={(e, v) => handleChange(e, v, 'contact_number')}
                    //   error={(error && error["contact_number"]) || error["contact"]}
                    size='small'
                    fullWidth
                    required
                  />
                  {/* <FormHelperText style={{ color: "red", marginLeft: "4px" }}>
                  {error && error["contact_number"]}
                </FormHelperText> */}
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <Grid container item md={7}>
            <Typography
              sx={{
                // pb: 2,
                fontSize: '16px',
                fontWeight: 550,
                color: '#01357E',
                borderBottom: 2,
              }}
            >
              SUB-USER LISTING
            </Typography>
            <CardContent
              sx={{
                background: '#edf1f4',
                width: '100%',
                height: '70vh',
                overflowY: 'auto',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                marginTop: '10px',
              }}
            >
              {listData && [...listData].reverse().map((subuser) => (
                <Paper
                 
                  key={subuser.id}
                  sx={{
                    width: '100%',
                    marginBottom: 2,
                    display: 'flex',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                  }}
                >
                  <Grid container spacing={0}>
                    
                    <Grid item md={2} xs={12}>
                      <Typography sx={{ p: 2, fontWeight: 500 }}>
                        {subuser?.f_name} {subuser?.l_name}
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Typography sx={{ p: 2, fontWeight: 500 }}>
                        {subuser?.mobile_no}
                      </Typography>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Typography sx={{ p: 2, fontWeight: 500 }}>
                        {subuser?.email_id}
                      </Typography>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      
                      {editMode && subuser?.id === saveid ? (
                        <IconButton
                          aria-label='edit'
                          sx={{ paddingTop: 2 }}
                          color='info'
                          size='large'
                          onClick={() => handleSubUserUpdate(subuser)}
                        >
                          <Save />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label='edit'
                          sx={{ paddingTop: 2 }}
                          color='info'
                          size='large'
                          onClick={() => handleEdit(subuser?.id)}
                        >
                          <EditTwoTone />
                        </IconButton>
                      )}
                      <IconButton
                        aria-label='delete'
                        sx={{ paddingTop: 2 }}
                        color='error'
                        size='large'
                        onClick={() => handleSubUserDelete(subuser)}
                      >
                        <DeleteSweepTwoTone />
                      </IconButton>
                      
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </CardContent>
               {/* <CardContent
      sx={{
        background: '#edf1f4',
        width: '100%',
        height: '70vh',
        overflowY: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
        marginTop: '10px',
      }}
    >
      <Carousel>
        {listData.map((subuser) => (
          <Paper
            key={subuser.id}
            sx={{
              width: '100%',
              marginBottom: 2,
              display: 'flex',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
            }}
          >
            <Grid container spacing={0}>
              <Grid item md={2} xs={12}>
                <Typography sx={{ p: 2, fontWeight: 500 }}>
                  {subuser?.f_name} {subuser?.l_name}
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography sx={{ p: 2, fontWeight: 500 }}>
                  {subuser?.mobile_no}
                </Typography>
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography sx={{ p: 2, fontWeight: 500 }}>
                  {subuser?.email_id}
                </Typography>
              </Grid>
              <Grid item md={2} xs={12}>
                {editMode && subuser?.id === saveid ? (
                  <IconButton
                    aria-label='edit'
                    sx={{ paddingTop: 2 }}
                    color='info'
                    size='large'
                    onClick={() => handleSubUserUpdate(subuser)}
                  >
                    <Save />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label='edit'
                    sx={{ paddingTop: 2 }}
                    color='info'
                    size='large'
                    onClick={() => handleEdit(subuser?.id)}
                  >
                    <EditTwoTone />
                  </IconButton>
                )}
                <IconButton
                  aria-label='delete'
                  sx={{ paddingTop: 2 }}
                  color='error'
                  size='large'
                  onClick={() => handleSubUserDelete(subuser)}
                >
                  <DeleteSweepTwoTone />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Carousel>
    </CardContent> */}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Subusercreation
