import { Alert, Snackbar } from '@mui/material'
import React, { createContext, useState } from 'react'
import { getNotification } from '../services/configService'
import Slide from '@mui/material/Slide';

export const SnackbarContext = createContext()
function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export function SnackbarProvider(props) {

  const [on, setOn] = useState(false)
  const [severity, setSeverity] = useState('info')
  const [mes, setMes] = useState('')
  const [notificationlist, setNotificationlist] = useState([])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOn(false)
  }

  async function getnotifications(temp) {
    try {
      const res = await getNotification()
      if(res !== 204 && res !== 401) {
        setNotificationlist(res.data)
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  return (
    <SnackbarContext.Provider
      value={{
        setOn,
        setSeverity,
        setMes,
        handleClose,
        notificationlist,
        setNotificationlist,
        getnotifications,
      }}
    >
      <Snackbar
        open={on}
        autoHideDuration={3000}
        TransitionComponent={SlideTransition}
        onClose={handleClose}
        sx={{marginTop : "44px",marginRight :"-18px"}}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {mes}
        </Alert>
      </Snackbar>{' '}
      {props.children}
    </SnackbarContext.Provider>
  )
}
