import React from 'react'
import './policystyle.css'

const Privacy = () => {
  return (
    <>
      <header className='bg-gray-900 py-6 px-4 md:px-6'>
        <div className='container1 mx-auto'>
          <h1 className='text-2xl font-bold text-white'>Privacy Policy</h1>
        </div>
      </header>
      <div className='container1 mx-auto my-12 px-4 md:px-6'>
        <div className='grid grid-cols-1 md:grid-cols-[200px_1fr] gap-8'>
          <div className='space-y-12'>
            <section id='introduction'>
              <h2 className='text-2xl font-bold mb-4'>Introduction</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                <strong>Zeal Global Services Limited</strong>, (hereinafter
                referred to as the ‘Company’), understands that you (a visitor
                or a registered user) care about how information about you is
                used and shared. We appreciate your trust that we will handle
                your information carefully and sensibly. We aim to give you as
                much control as possible over your personal information.
                However, you may not visit and use our site without telling us
                who you are or revealing the required information about
                yourself. To the Company, our most important asset is our
                relationship with you. We are committed to maintaining the
                confidentiality, integrity, and security of any personal
                information about our users on our site. We are proud of our
                privacy practices and the strength of our site security. This
                notice describes our privacy policy. By visiting this Website,
                you accept the practices described in this Privacy Policy. You
                further agree and consent to our collection, use, processing,
                disclosure, and transfer of your personal data according to the
                provisions of this Privacy Policy.
              </p>
            </section>
            <section id='definitions'>
              <h2 className='text-2xl font-bold mb-4'>
                Information shared by you
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                We receive any information you enter on our Website or give us
                in any other way, which is stored on the Company’s secure
                servers. Personal information includes but is not limited to
                name, email, website user account log-in ID and password, mobile
                and landline phone numbers, gender, DOB, and address. This
                excludes Credit Card, Debit Card, or Net-Banking Details, all of
                which are stored on the Company’s secure servers and/or in
                cookies on your computer. You can choose not to provide certain
                information, but then you might not be able to take advantage of
                many of our features. We use the information that you provide
                for purposes such as responding to your requests, customizing
                future commercial transactions, and communicating with you. You
                can review, add, or update certain information by writing to us
                at <stronge>info@zeal-global.com</stronge>. When you update
                information, we usually keep a copy of the prior version for our
                records.
              </p>
            </section>
            <section id='user-accounts'>
              <h2 className='text-2xl font-bold mb-4'>
                Automatic collection of information
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                We receive and store certain types of information whenever you
                interact with us, including operating system version, hardware
                models, unique device identifiers, advertising identifiers,
                serial numbers, mobile network information, and installed
                applications. Our server logs your activities for various
                diagnostic and analytical purposes. However, other than the IP
                address of your machine from where you are accessing the
                service, there is no other personal information maintained by
                the company in the log.
              </p>
            </section>
            <section id='content'>
              <h2 className='text-2xl font-bold mb-4'>Information retention</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                We will retain your personal data for a period of 5 years or as
                long as it is necessary to fulfill the purposes outlined in this
                Privacy Policy.
              </p>
            </section>
            <section id='intellectual-property'>
              <h2 className='text-2xl font-bold mb-4'>Communications</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                To help us make e-mails more useful and interesting, we may
                request to receive a confirmation when you open e-mail from your
                end if your computer supports such capabilities.
              </p>
            </section>
            <section id='termination'>
              <h2 className='text-2xl font-bold mb-4'>
                Sharing of information received by the company
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                For the purposes of providing you with our services, we may
                share your information with third parties such as affiliates of
                the company, service providers, etc. When we disclose your
                personal data to such third parties, we make appropriate
                arrangements as required under applicable law to restrict
                further disclosure. By using our services, You consent to such
                storage and processing, and your personal data may be
                transferred, stored, and processed outside India.
              </p>
            </section>
            <section id='disclaimer-of-warranties'>
              <h2 className='text-2xl font-bold mb-4'>Transfer of business</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                As we continue to develop our business, we might be acquired
                completely or merge with any other company. In such
                transactions, customer information generally is one of the
                transferred business assets. In such a case, we will ensure the
                protection of Your information as per these policies and
                guidelines. You hereby specifically consent and grant permission
                to the disclosure and transferring of information to such third
                parties.
              </p>
            </section>
            <section id='limitation-of-liability'>
              <h2 className='text-2xl font-bold mb-4'>
                Protection of the company and others
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                We release account and other personal information when we
                believe release is appropriate to comply with law; to enforce or
                apply our Terms and Conditions and other agreements; or protect
                the rights, property, or safety of Company, our users, or
                others. This includes exchanging information with other
                companies and organizations for fraud protection and other
                similar matters. You hereby specifically consent and grant
                permission to the disclosure and transferring of information to
                such third parties, as maybe required.
              </p>
            </section>
            <section id='indemnification'>
              <h2 className='text-2xl font-bold mb-4'>Information Security</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                We work to protect the security of your information during
                transmission by using software during the part where you enter
                your credit card or net banking details, which encrypts
                information you input. We constantly re-evaluate our privacy and
                security policies and adapt them as necessary to deal with new
                challenges. We do not and will not sell or rent your personal
                information to anyone, for any reason, at any time unless it is
                in (i) in response to a valid legal request by a law enforcement
                officer or government agency or (ii) where you have given your
                consent or (iii) utilize the same for some statistical or other
                representation without disclosing personal data. The information
                collected from you will be stored on the Company’s secured
                server and systems. The Company maintains secure and reasonable
                security practices and procedures that are proprietary and
                unique to the Company (“Security Practice”). Such Security
                Practice involves measures such as but not limited to password
                protection, limitation of access to specified personnel on a
                need-to-know basis, and security measures, including encryption
                if required, and other physical security measures to guard
                against unauthorized access to the server and systems and
                information residing on such server and systems. The Company’s
                Security Practice protects against unauthorized access to, or
                unauthorized alteration, disclosure or destruction of the
                information. The Company’s Security Practice contains
                managerial, technical, operational and physical security control
                measures that are commensurate with the information assets being
                protected and with the nature of business. You acknowledge and
                agree that the Security Practice and procedures as mentioned
                above are reasonable and are designed to protect the information
                provided by you. We only reveal those numbers of your account as
                required to enable us to access and provide you the required
                services relating to your accounts. We make every effort to
                allow you to retain the anonymity of your personal identity, and
                you are free to choose a Login ID email address and password
                that keeps your personal identity anonymous. Access to your
                Registration Information and your personal financial data is
                strictly restricted to those of our Company employees and
                contractors, strictly on a need-to-know basis, in order to
                operate, develop, or improve the Service. These employees or
                contractors may be subject to discipline, including termination
                and criminal prosecution, if they fail to meet these
                obligations. With the exception of a Login ID in the form of an
                email address, which may be provided on an anonymous basis, and
                your Third Party Account Information, which is required for
                providing the services, the Company does not require any
                information from you that might constitute personally
                identifiable information. It is important for You to protect
                against unauthorized access to Your password and to Your
                computer. Be sure to sign off when finished using a shared
                computer. As described in this Agreement and with Your consent,
                the Company will from time to time connect electronically to
                your online bank, credit card and other online financial
                accounts to process your Order. Company will also use other
                third parties like courier companies to deliver products that
                you order on our website, to your delivery address.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>NDNC Policy</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                By using the website and/or registering yourself at
                www.zeal-global.com you authorize us to contact you via email or
                phone call or sms and offer you our services, imparting product
                knowledge, offer promotional offers running on website & offers
                offered by the associated third parties, for which reasons,
                personally identifiable information may be collected. And
                Irrespective of the fact if also you have registered yourself
                under DND or DNC or NCPR service, you still authorize us to give
                you a call from Zeal Global for the above mentioned purposes
                till 365 days of your registration with us. This Privacy Policy
                covers Zeal Global’s treatment of personally identifiable
                information that Zeal Global collects when you are on the Zeal
                Global site, and when you use Zeal Global’s services. This
                policy also covers Zeal Global’s treatment of any personally
                identifiable information that Zeal Global’s business partners
                share with Zeal Global. This policy does not apply to the
                practices of companies that Zeal Global does not own or control
                or to people that Zeal Global does not employ or manage. Zeal
                Global collects personally identifiable information when you
                register for a Zeal Global account, when you use certain Zeal
                Global products or services, when you visit Zeal Global pages,
                and when you enter promotions. Zeal Global may also receive
                personally identifiable information from our business partners.
                When you register with Zeal Global, we ask for your first name,
                last name, contact no, email, Date of birth and gender. Once you
                register with Zeal Global and sign in to our services, you are
                not anonymous to us. Also during registration, you may be
                requested to register your mobile phone and email id, pager, or
                other device to receive text messages, notifications, and other
                services to your wireless device. By registration you authorize
                us to send sms/email alerts to you for your login details and
                any other service requirements or some advertising
                messages/emails from us.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Cookie Policy</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                A cookie is a small text file that we place on your mobile
                device to enable various features of{' '}
                <strong>www.zeal-global.com</strong>. “Cookies” are used to
                store user preferences and to track user trends, so as to
                enhance your interactive experience and generally improve our
                services to you. You can change your cookie settings to accept
                or not accept cookies in your browser settings. If you do accept
                a “cookie”, you thereby agree to our use of any personal
                information collected by us using that cookie. If you do accept
                a “cookie”, you thereby agree to our use of any personal
                information collected by us using that cookie. Whenever we uses
                a third party to host and serve content, we may arrange for a
                common identifier to be shared between the third party and us in
                order to enable accurate measurement of traffic and usage. This
                includes but is not limited to the use of AMP Client IDs in
                Google Analytics for Accelerated Mobile Pages served by Google
                on our behalf.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Grievance Addressal</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                The Company would be glad to provide assistance to you in case
                you have any queries or have any issues pertaining to this
                Security and Privacy policy. Kindly contact{' '}
                <strong>info@zeal-global.com</strong>
              </p>
            </section>
           
          </div>
        </div>
      </div>
    </>
  )
}

export default Privacy
