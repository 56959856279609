import React, { useContext } from 'react'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'
import ImapLisiting from './ImapLisiting'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Organizations from './Organizations'
import {
  Button,
  Modal,
  TextField,
  Card,
  CardContent,
  IconButton,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import MuiPhoneNumber from 'mui-phone-number'
import CustomTextArea from '../../../components/common/CustomTextArea'
import {
  Add,
  ConfirmationNumberTwoTone,
  Create,
  DeleteForeverTwoTone,
  DeleteOutlineTwoTone,
  DeleteSweepTwoTone,
  Diversity3TwoTone,
  GroupAddTwoTone,
  Info,
} from '@mui/icons-material'
import {
  createOrganizationApi,
  deleteOrg,
  updateCollaborator,
} from '../../../services/configService'
import { SnackbarContext } from '../../../context/Snackbar'
import ParticularuserOrg from './ParticularuserOrg'
import Userdirectory from './Userdirectory'

import { IconDropdown } from 'react-day-picker'
import Deletecard from '../../../components/card/Deletecard'
// import Userticketlisting from './Userticketlisting'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #2A67AC',
  boxShadow: 24,
  p: 2,
}

const Orgmain = () => {
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const [value, setValue] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const [refreshorglist, setRefreshorglist] = React.useState(false)
  const [selectedOrgDltId, setSelectedOrgDltId] = React.useState([])
  const [organizationDetails, setOrganizationDetails] = React.useState({
    domain: '',
    name: '',
    phone: '',
    address: '',
    internal_note: '',
  })

  const [deleteRow, setDeleteRow] = React.useState(false)
  const handleDeleteModal = (row) => {
    setDeleteRow(true)
  }
  const handledeleteModalClose = () => setDeleteRow(false)
  console.log(selectedOrgDltId)

  const handleDeleteOrg = async (row) => {
    const sendData = {
      ids: selectedOrgDltId,
    }
    try {
      const res = await deleteOrg(sendData)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Organization Deleted Successfully')
        setOn(true)
        setRefreshorglist(true)
        handledeleteModalClose()
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }
  const handleChangePhone = (e, v, v1, v2) => {
    let x = e
    let num = x.replace(/\D/g, '')
    // let dialCode = num.slice(0, 2)
    setOrganizationDetails((pre) => ({
      ...pre,
      phone: num,
    }))
  }
  const handleSetvalue = (e) => {
    const { name, value } = e.target
    setOrganizationDetails((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleopen = () => {
    setOpen(true)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  async function createOrganization() {
    if (organizationDetails.name === '') {
      setSeverity('warning')
      setMes('Please Enter Organization Name')
      setOn(true)
      return
    }
    const data = {
      name: organizationDetails.name,
      phone: organizationDetails.phone,
      domain: organizationDetails.domain,
      note_description: organizationDetails.internal_note,
      address: organizationDetails.address,
    }
    try {
      const res = await createOrganizationApi(data)
      if (res.status !== 204 && res.status !== 406) {
        setSeverity('success')
        setMes('organization created successfully ')
        setOn(true)
        setOrganizationDetails({
          domain: '',
          name: '',
          phone: '',
          address: '',
          internal_note: '',
        })
        setOpen(false)
        setRefreshorglist(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    // <DashboardLayout heading='Organizations' pathName='Organizations'>
    <div>
      <Box sx={{ width: '100%' }}>
        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Organizations'
              icon={<Diversity3TwoTone />}
              iconPosition='start'
              {...a11yProps(0)}
            />
            <Tab
              label='Tickets'
              icon={<ConfirmationNumberTwoTone />}
              iconPosition='start'
              {...a11yProps(1)}
            />
            <Tab
              label='User Directory'
              icon={<GroupAddTwoTone />}
              iconPosition='start'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant='h6'
            sx={{
              ml: 2,
              mt: 1,
              color: '#184E81',
              textDecoration: 'underline',
              fontWeight: '600',
            }}
          >
            Organization
          </Typography>
          <div style={{ display: 'flex', gap: '5px' }}>
            {selectedOrgDltId.length ? (
              <IconButton
                sx={{
                  borderRadius: '20%',
                  m: 1,
                  backgroundColor: 'lightcoral',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'grey',
                  },
                  transition: 'background-color 0.3s ease',
                }}
                onClick={handleDeleteModal}
              >
                <DeleteSweepTwoTone />
              </IconButton>
            ) : (
              ''
            )}
            <Button
              variant='contained'
              startIcon={<Add />}
              onClick={handleopen}
              sx={{ m: 1 }}
            >
              Add organization
            </Button>
          </div>
        </div>

        <Organizations
          refreshorglist={refreshorglist}
          setRefreshorglist={setRefreshorglist}
          setSelectedOrgDltId={setSelectedOrgDltId}
        />

        {/* </CustomTabPanel> */}
        {/*         
          <Typography
            variant='h6'
            sx={{
              m: 1,
              color: '#184E81',
              textDecoration: 'underline',
              fontWeight: '600',
            }}
          >
            Ticket
          </Typography> */}
        {/* <ImapLisiting /> */}

        {/* <CustomTabPanel value={value} index={2}>
          <Userdirectory />
          
        </CustomTabPanel> */}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            color={'#2A67AC'}
            borderBottom={'1px solid #C8C8C8'}
          >
            Add New Organization
          </Typography>
          <Typography
            sx={{
              mt: 2,
              fontSize: '15px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
            bgcolor={'#d9edf7'}
            color={'#3a87ad'}
            border={'1px solid #bce8f1'}
          >
            {' '}
            <Info />
            Complete the form below to add a new organization.
          </Typography>
          <CardContent
            sx={{ padding: '0px', marginTop: '10px', marginBottom: '20px' }}
          >
            <Typography
              sx={{
                mt: 2,
                fontSize: '15px',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
              bgcolor={'#eee'}
              color={'#000'}
              border={'1px dotted #bbb'}
            >
              {' '}
              <Create />
              Create New Organization: Details on user organization
            </Typography>
          </CardContent>

          <CardContent>
            <Grid container spacing={2} sx={{ gap: 2, marginLeft: '10px' }}>
              <Grid
                itm
                md={11}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>Name:</Typography>
                <TextField
                  label='Name'
                  size='small'
                  sx={{ width: '350px' }}
                  name='name'
                  value={organizationDetails.name}
                  // error={error && error['full_name']}
                  onChange={(e) => handleSetvalue(e, 'name')}
                />
              </Grid>
              <Grid
                itm
                md={11}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>Phone No:</Typography>
                <MuiPhoneNumber
                  className='form_style'
                  variant='outlined'
                  name='phone'
                  value={organizationDetails.phone}
                  defaultCountry={'in'}
                  onChange={(e, v) => handleChangePhone(e, v)}
                  // error={error && error['contact']}
                  size='small'
                  sx={{ width: '350px' }}
                />
              </Grid>
              <Grid
                itm
                md={11}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>Website:</Typography>

                <TextField
                  label='Website'
                  size='small'
                  sx={{ width: '350px' }}
                  name='domain'
                  value={organizationDetails.domain}
                  // error={error && error['full_name']}
                  onChange={(e) => handleSetvalue(e, 'domain')}
                />
              </Grid>
              <Grid
                itm
                md={11}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>Address:</Typography>
                <div style={{ padding: '0px', width: '350px' }}>
                  <CustomTextArea
                    placeholder={'Address...'}
                    name={'address'}
                    value={organizationDetails.address}
                    onChange={(e) => handleSetvalue(e, 'address')}
                  />
                </div>
              </Grid>
              <Grid
                itm
                md={11}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  Internal Note
                </Typography>
                <div style={{ padding: '0px', width: '350px' }}>
                  <CustomTextArea
                    placeholder={'Message...'}
                    name={'internal_note'}
                    value={organizationDetails.internal_note}
                    onChange={(e) => handleSetvalue(e, 'internal_note')}
                  />
                </div>
              </Grid>
            </Grid>
          </CardContent>

          <Button
            sx={{
              float: 'right',
              background: '#3a87ad',
              '&:hover': {
                color: 'black',
                backgroundColor: '#d9edf7',
                // transition: 'backgroundColor 0.3s ease',
              },
            }}
            variant='contained'
            onClick={createOrganization}
          >
            Add Organization
          </Button>
        </Card>
      </Modal>
      <Modal
        open={deleteRow}
        onClose={handledeleteModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Deletecard
          btnText={'Delete'}
          onClickBack={handledeleteModalClose}
          onClickSubmit={handleDeleteOrg}
          subheading={'Delete this Organization?'}
        />
      </Modal>
    </div>
  )
}

export default Orgmain
