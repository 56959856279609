import {
  Grid,
} from "@mui/material";
import SegmentIcon from "@mui/icons-material/Segment";
import {
  AirplaneTicket,
  Checklist,
  Close,
  CurrencyRupee,
  Done,
  Sell,
  Storefront,
} from "@mui/icons-material";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts";
import DataCard from "../../../components/dashboard_layout/dash_component/DataCard";
import { getDashboardApi } from "../../../services/admin.configService";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../context/Snackbar";
import { months } from "../../../utils/constant";

const Statics = () => {
  const [cardData, setCardData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const chartSetting = {
    yAxis: [
      {
        label: "No. of quotes",
      },
    ],

    height: 400,
  };

  async function dash() {
    try {
      const res = await getDashboardApi();
      if (res !== 204 && res !== 401) {
        setCardData(res["card_data"]);
        setGraphData(res["graph_data"]);
      }
    } catch (error) {
      console.log("error ", error.res)
    }
  }

  useEffect(() => {
    dash();
  }, []);
  
  return (
    <>
      <Grid p={2} container spacing={1} bgcolor={'aliceblue'} style={{minHeight :"calc(100vh - 4rem)"}} alignContent={'flex-start'} >
        <Grid item md={2.4} xs={6}>
          <DataCard
            value={cardData[0]?.total}
            icon={SegmentIcon}
            heading={"Enquiries"}
            thisMonth={20}
            color="blue"
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <DataCard
            value={cardData[0]?.quoted}
            icon={Checklist}
            heading={"Quoted"}
            thisMonth={20}
            color="red"
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <DataCard
            value={cardData[0]?.accepted}
            icon={Done}
            heading={"Accepted"}
            thisMonth={10}
            color="green"
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <DataCard
            value={cardData[0]?.rejected}
            icon={Close}
            heading={"Rejected"}
            thisMonth={6}
            color="red"
          />
        </Grid>
        <Grid item md={2.4} xs={6}>
          <DataCard
            value={cardData[1]?.bookings}
            icon={AirplaneTicket}
            heading={"Bookings"}
            thisMonth={20}
            color="blue"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <DataCard
            value={cardData[1]?.buying}
            icon={Storefront}
            heading={"Buying"}
            thisMonth={20}
            color="red"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <DataCard
            value={cardData[1]?.revenue}
            icon={Sell}
            heading={"Revenue"}
            thisMonth={10}
            color="yellow"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <DataCard
            value={cardData[1]?.profit}
            icon={CurrencyRupee}
            heading={"Profit"}
            thisMonth={6}
            color="green"
          />
        </Grid>

        {/* finance */}
        {graphData["finance"] && graphData['finance'].length > 0 && (
          <>
            <Grid item md={4} xs={12}>
              <LineChart
                dataset={graphData["finance"]?.bookings}
                xAxis={[
                  {
                    scaleType: "point",
                    data: graphData["finance"]?.map((item) => months[item.month-1]),
                    dataKey: "month",
                  },
                ]}
                series={[
                  {
                    data: graphData["finance"]?.map((item) =>
                      Number(item.bookings)
                    ),
                    label: "Bookings",
                    area: true,
                    color: "#5D3587",
                  },
                ]}
                height={250}
              ></LineChart>
              <LineChart
                xAxis={[
                  {
                    scaleType: "point",
                    data: graphData["finance"]?.map((item) =>months[item.month-1]),
                    dataKey: "month",
                  },
                ]}
                series={[
                  {
                    data: graphData["finance"]?.map((item) =>
                      Number(item.revenue)
                    ),
                    label: "Selling",
                    // color: "orange",
                    area: true,
                  },
                ]}
                height={250}
                dataset={graphData["finance"]}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <LineChart
                xAxis={[
                  {
                    scaleType: "point",
                    data: graphData["finance"]?.map((item) => months[item.month-1]),
                    dataKey: "month",
                  },
                ]}
                series={[
                  {
                    data: graphData["finance"]?.map((item) =>
                      Number(item.buying)
                    ),
                    label: "Buying",
                    color: "rgb(85, 105, 255)",
                    area: true,
                  },
                ]}
                height={250}
                dataset={graphData["finance"]}
              />
              <LineChart
                xAxis={[
                  {
                    scaleType: "point",
                    data: graphData["finance"]?.map((item) => months[item.month-1]),
                    dataKey: "month",
                  },
                ]}
                series={[
                  {
                    data: graphData["finance"].map((item) =>
                      Number(item.profit)
                    ),
                    label: "Profit",
                    color: "green",
                    area: true,
                  },
                ]}
                height={250}
                dataset={graphData["finance"]?.bookings}
              />
            </Grid>
            {/* <Grid item md={4} xs={12}>
              <Card sx={{ height: "450px", width: "100%",overflow:"auto" }}>
                <CardHeader
                  title={<Typography variant="h6">Recents</Typography>}
                ></CardHeader>
                <CardContent sx={{ padding: 1 }}>
                  <List>
                    {notificationlist.map((item, index) => (
                      <div key={index}>
                        <NotificationListComp item={item} key={index} />
                      </div>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid> */}
          </>
        )}

        {/* sales graph */}
        {graphData["sales"] && graphData['sales'].length > 0 && (
          <Grid item md={8}>
            <BarChart
              dataset={graphData["sales"]}
              xAxis={[
                {
                  scaleType: "band",
                  dataKey: "month",
                  data: months,
                },
              ]}
              series={[
                { dataKey: "quoted", label: "Quoted" },
                { dataKey: "total", label: "Total" },
              ]}
              {...chartSetting}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Statics;
