import React, { useContext } from 'react'
import {
  Card,
  Grid,
  Typography,
  CardContent,
  Button,
  TextField,
  IconButton,
  Divider,
} from '@mui/material'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import {
  Add,
  AddToDrive,
  ConfirmationNumber,
  DeleteSweepTwoTone,
  DownloadTwoTone,
  Edit,
  EditTwoTone,
  HighlightOffOutlined,
  People,
  PushPin,
  TextSnippetTwoTone,
} from '@mui/icons-material'
import Particularorguser from './Particularorguser'
import MyStatefulEditor from '../../../components/Text Editor/Texteditor'
import Reactquilleditor from '../../../components/Text Editor/Reactquilleditor'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { SnackbarContext } from '../../../context/Snackbar'
import {
  getOrgNote,
  getUserNote,
  noteReply,
} from '../../../services/configService'
import { downloadAttachment } from '../../../utils/common'
import ImapLisiting from './ImapLisiting'
import ParticularUserticketlisting from './Userticketlisting'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Userticketlisting = () => {
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const { state } = useLocation()
  const [value, setValue] = React.useState(0)
  const [orgNote, setOrgNote] = React.useState([])
  const [error, setError] = React.useState(null)
  const [opentexter, setOpentexter] = React.useState(false)
  const [editorHtml, setEditorHtml] = React.useState('')

  const handleTextChange = (html) => {
    setEditorHtml(html)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleOpen = () => {
    setOpentexter(true)
  }
  console.log(editorHtml, 'state')

  const handleCreate = async () => {
    const data = {
      user_id: state?.id,
      body: editorHtml,
      type: 'U', // U or O
    }
    try {
      const res = await noteReply(data, setError)

      if (res !== 204 && res !== 406) {
        // Handle success response

        setEditorHtml('')
        // getData()
        // setEditorContentdata(true)
        userNote()
        setSeverity('success')
        setMes('Note Created Successfully')
        setOn(true)
        setOpentexter(false)
        // setLoader(false)
      }
    } catch (error) {
      console.error('Error in generate quote', error)
      setSeverity('warning')
      setMes('Failed to send the reply.')
      setOn(true)
    } finally {
      // setLoader(false)
      // Ensure loader is stopped in all cases
    }
  }

  async function userNote() {
    try {
      const res = await getUserNote(state.id)
      if (res.status !== 204) {
        console.log('res.data', res)

        setOrgNote(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const noteList = orgNote
    ?.map((item) => {
      if (item.type === 'U') {
        return {
          message: item.body,
          created_at: item.created,
          poster: item.poster,
          type: item.type,
          title: item.title,
        }
      }
      return null // or filter out falsy values later
    })
    .filter((item) => item !== null)

  const messages = [...noteList.map((msg) => ({ ...msg, isAdmin: true }))]

  messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

  React.useEffect(() => {
    userNote()
  }, [])

  return (
    <Card>
      <CardContent>
        <Typography variant='h6'>{state?.name}</Typography>
        <CardContent
          sx={{
            background: '#e9f4ff',
            borderTop: '1px solid lightgrey',
            marginTop: '8px',
            padding: '6px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                  margin: '16px',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>Name:</Typography>
                <Typography style={{ fontWeight: '500', color: '#002244' }}>
                  {state?.name}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                  margin: '16px',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>Created:</Typography>
                <Typography style={{ fontWeight: '500', color: '#002244' }}>
                  {dayjs(state?.created).format('DD-MM-YYYY hh:mm A')}
                </Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                  margin: '16px',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  Account Manager:
                </Typography>
                <Typography style={{ fontWeight: '500', color: '#002244' }}>
                  poster
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                  margin: '16px',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  Last Update:
                </Typography>
                <Typography style={{ fontWeight: '500', color: '#002244' }}>
                  {dayjs(state?.updated).format('DD-MM-YYYY hh:mm A')}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent
          sx={{
            // background: '#e9f4ff',
            marginTop: '8px',
            padding: '6px',
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              background: '#e9f4ff',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
            >
              <Tab
                label='Ticket'
                icon={<ConfirmationNumber />}
                iconPosition='start'
                {...a11yProps(0)}
              />
              <Tab
                label='Note'
                icon={<PushPin />}
                iconPosition='start'
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <ParticularUserticketlisting />
            {/* <ImapLisiting /> */}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CardContent sx={{ padding: '10px', borderRadius: '0px' }}>
              {messages.length == 0 ? (
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    margin: '10px',
                  }}
                  variant='h6'
                  color={'GrayText'}
                >
                  No Note Found
                </Typography>
              ) : (
                messages.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: item.isAdmin ? 'flex-start' : 'flex-end',
                    }}
                  >
                    <div
                      style={{ display: 'flex', gap: '10px', width: '100%' }}
                    >
                      <Card elevation={5} sx={{ width: '100%' }}>
                        <CardContent
                          sx={{
                            background:
                              item.type === 'M'
                                ? '#C3D9FF'
                                : item.type === 'N'
                                ? '#ffffc1'
                                : '#D0F0C0',
                            borderBottom: '1px dotted #008E97',
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <TextSnippetTwoTone
                              fontSize='small'
                              sx={{ mr: 1 }}
                            />
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: '15px',
                                color: item.isAdmin ? '#002244' : '#002244',
                              }}
                            >
                              {item.isAdmin
                                ? 'CargoDash Support '
                                : item.poster}
                            </Typography>
                            <Typography
                              variant='caption'
                              display='block'
                              sx={{
                                marginLeft: '10px',
                                fontWeight: 550,
                                fontSize: '15px',

                                // marginLeft: item.isAdmin ? '0' : '0px',
                              }}
                            >
                              Created on{' '}
                              {dayjs(item.created_at).format(
                                'DD/MM/YY hh:mm:ss a'
                              )}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <IconButton
                              aria-label='edit'
                              sx={{ borderRadius: '0px' }}
                            >
                              <EditTwoTone color='success' />
                            </IconButton>
                            <IconButton aria-label='delete'>
                              <DeleteSweepTwoTone color='error' />
                            </IconButton>
                          </div>
                        </CardContent>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: 500 }}
                            // color='text.secondary'
                            gutterBottom
                          >
                            {/* <pre>{item.message}</pre> */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.message.replace(/\n/g, '<br/>'),
                              }}
                            />
                          </Typography>
                        </CardContent>
                      </Card>
                      {/* {!item.isAdmin && <AccountCircle fontSize='large' />} */}
                    </div>

                    <div style={{ display: 'flex', gap: 4 }}>
                      {item.file && (
                        <IconButton
                          aria-label='delete'
                          size='small'
                          color='info'
                          onClick={() => {
                            downloadAttachment(item.file, 'Attachment File')
                          }}
                        >
                          <DownloadTwoTone />
                        </IconButton>
                      )}
                    </div>
                    <Divider sx={{ marginBottom: '15px' }} />
                  </div>
                ))
              )}
            </CardContent>

            <Card
              sx={{
                width: '100%',
                border: '1px solid #184E81',
                padding: '6px',
              }}
            >
              {!opentexter && (
                <Button
                  variant='text'
                  startIcon={<Add />}
                  sx={{ color: '#184E81' }}
                  onClick={() => handleOpen()}
                >
                  Click to Create a New Note
                </Button>
              )}
              {opentexter && (
                <>
                  <IconButton aria-label='cancel' sx={{ float: 'right' }}>
                    <HighlightOffOutlined
                      onClick={() => setOpentexter(false)}
                    />
                  </IconButton>
                  {/* <MyStatefulEditor
                    setEditorContentdata={setEditorContentdata}
                    editorContentdata={editorContentdata}
                    onChange={handleEditorChange}
                  /> */}
                  <Reactquilleditor
                    theme='snow'
                    placeholder='Enter Note'
                    setEditorHtml={setEditorHtml}
                    editorHtml={editorHtml}
                    handleChange={handleTextChange}
                  />
                </>
              )}

              {opentexter && <Button onClick={handleCreate}>Create</Button>}
            </Card>
          </CustomTabPanel>
        </CardContent>
      </CardContent>
    </Card>
  )
}

export default Userticketlisting
