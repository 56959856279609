import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import Receiptslistingtable from './Receiptslistingtable'
import Card from '@mui/material/Card'
import { Create } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
const Receiptmain = () => {
  const navigate = useNavigate()
  function createReceipt() {
    navigate('/create-receipt')
  }
  return (
    <Card sx={{ height: '100%' }} elevation={0}>
      <DashboardLayout
        heading='Receipt'
        pathName='Receipt'
        button={true}
        onClick={createReceipt}
        btnText={'Create'}
        btnIcon={<Create />}
      >
        <Receiptslistingtable />
      </DashboardLayout>
    </Card>
  )
}

export default Receiptmain
