import { ArrowBack, CheckCircle, Download } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Confetti from 'react-confetti'; // Import Confetti library
import { downloadAttachment, getSessionStorage } from '../../../utils/common';

const Preloginpayment = ({link}) => {

  const navigate = useNavigate();
  const location = useLocation();
  let  pdfstate  = location.state || null;
  console.log("gasdghsaghdhgsad",pdfstate?.pdf || null);
  const [showConfetti, setShowConfetti] = useState(false); // State to control confetti visibility

  const handleBack = () => {
    navigate('/');
  };
  const storedpdf = getSessionStorage('res')
  const handleDownload =()=>{
    downloadAttachment(storedpdf?.pdf, "Acknowledgement")
    console.log("hellllllsdsdadsahgdahgsd");
  }

  useEffect(() => {
    setShowConfetti(true); // Set showConfetti to true to trigger confetti animation
    const timer = setTimeout(() => {
      navigate("/");
    }, 150000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div
    style={{
      width: "100%",
      height: "77vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "10px",
 
      }}
    >
      {showConfetti && <Confetti />} {/* Render Confetti component when showConfetti is true */}
      <CheckCircle
        style={{ width: '100px', height: '100px', color: '#17B169' }}
        fontSize='large'
      />
      <h1>Payment Successful</h1>
      <h5>
        The payment has been done successfully. Thanks for being there with us.
      </h5>
      <h4>Transaction ID :  </h4>
      <div style={{ display: 'flex', gap: '20px' }}>
        <Button
          variant='contained'
          onClick={handleBack}
          sx={{
            background: '#000000d6',
            '&:hover': {
              backgroundColor: '#000000a8',
            },
          }}
          startIcon={<ArrowBack />}
        >
          Back to Booking
        </Button>
        {/* <a href={`https://zealticketing-dev.s3.amazonaws.com/PAYMENT/${id}.pdf`} target='blank'> */}
        <Button
          variant='contained'
          sx={{
            background: '#17B169',
            '&:hover': {
              backgroundColor: '#17b1699c',
            },
          }}
          startIcon={<Download />}
          onClick={handleDownload}
        >
          Acknowledgement
        </Button>
        {/* </a> */}
      </div>
    </div>
  );
};

export default Preloginpayment;
