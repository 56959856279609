import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import Subusercreation from './Subusercreation'

const Subusermanagement = () => {
  return (
    <DashboardLayout heading='create sub user' pathName='create sub user'>
    <Subusercreation />
  </DashboardLayout>
  )
}

export default Subusermanagement