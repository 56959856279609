import {Grid} from "@mui/material";
import SegmentIcon from "@mui/icons-material/Segment";
import { Checklist, Close, Done, PendingActions, } from "@mui/icons-material";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import {  useEffect, useState } from "react";
import { getDashboardApi } from "../../../../services/admin.configService";
import DataCard from "../../../../components/dashboard_layout/dash_component/DataCard";
import { months } from "../../../../utils/constant";
import CustomLinearProgress from "../../../../components/mui/CustomLinearProgress";

const chartSetting = {
  yAxis: [
    {
      label: "Quoted and Queries",
    },
  ],

  height: 400,
  sx: {
    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
      transform: "translate(0px, 0)",
    },
  },
};

const SalesDash = () => {

  const [cardData, setCardData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getData() {
    try {
      setLoading(false);
      const res = await getDashboardApi();
      if (res !== 204 && res !== 401) {
        setCardData(res.card_data);
        setGraphData(res.graph_data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);


  if(loading){
    return(<CustomLinearProgress/>)
  }

  return (
    <>
      <Grid p={2} container spacing={1} marginTop={0} bgcolor={'aliceblue'} style={{minHeight :"calc(100vh - 4rem)"}} >
        {cardData.length > 0 && (
          <Grid container spacing={1}>
            <Grid item md={2.4} xs={6}>
              <DataCard
                value={cardData[0].total}
                icon={SegmentIcon}
                heading={"Enquiries"}
                thisMonth={0}
                color="blue"
              />
            </Grid>
            <Grid item md={2.4} xs={6}>
              <DataCard
                value={cardData[0].quoted}
                icon={Checklist}
                heading={"Quoted"}
                thisMonth={6}
                color="yellow"
              />
            </Grid>
            <Grid item md={2.4} xs={6}>
              <DataCard
                value={cardData[0].accepted}
                icon={Done}
                heading={"Accepted"}
                thisMonth={6}
                color="green"
              />
            </Grid>
            <Grid item md={2.4} xs={6}>
              <DataCard
                value={cardData[0].rejected}
                icon={Close}
                heading={"Rejected"}
                thisMonth={6}
                color="red"
              />
            </Grid>
            <Grid item md={2.4} xs={12}>
              <DataCard
                value={cardData[0].pending}
                icon={PendingActions}
                heading={"Pending"}
                thisMonth={6}
                color="gray"
              />
            </Grid>
          </Grid>
        )}
        {graphData.length > 0 && (
          <Grid item md={8} xs={12}>
            <BarChart
              dataset={graphData}
              xAxis={[{ scaleType: "band", dataKey: "month", data: months }]}
              series={[
                { dataKey: "total", label: "Queries" },
                { dataKey: "quoted", label: "Quoted" },
              ]}
              {...chartSetting}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SalesDash;
