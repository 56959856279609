import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import styles for the editor
import PropTypes from 'prop-types'
import './style.css'

const Reactquilleditor = ({ placeholder, editorHtml, handleChange ,theme}) => {
  return (
    <div>
      <ReactQuill
        theme={theme}
        onChange={handleChange}
        value={editorHtml}
        modules={Reactquilleditor.modules}
        formats={Reactquilleditor.formats}
        bounds={'.app'}
        placeholder={placeholder}
      />
    </div>
  )
}
Reactquilleditor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: true, // Toggle to add extra line breaks when pasting HTML
  },
}

Reactquilleditor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
]

Reactquilleditor.propTypes = {
  placeholder: PropTypes.string,
}

/*
 * Render component on page
 */
export default Reactquilleditor
