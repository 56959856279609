import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  gridClasses,
  GridToolbar,
  GridPagination,
} from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import {
  ArrowRightAlt,
  Cancel,
  Delete,
  Download,
  Edit,
  Groups2TwoTone,
  Info,
  Inventory,
  List,
  Save,
  SyncAlt,
  Upload,
} from '@mui/icons-material'
import {
  Button,
  Tooltip,
  Grid,
  Popover,
  Typography,
  Card,
  IconButton,
  CardContent,
  TextField,
  Modal,
  Box,
} from '@mui/material'

import dayjs from 'dayjs'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { CalendarIcon } from '@mui/x-date-pickers'
import { SnackbarContext } from '../../../../context/Snackbar'
import { getFlightListApi } from '../../../../services/admin.configService'
import {
  getCustomerbooking,
  getFlightInventorydetailsApi,
  getPassengerInventorydetailsApi,
  getSeatinventoryreport,
} from '../../../../services/configService'
import TableMenu from '../../../../components/table_modal/TableMenu'
import Inventorypassengers from './Inventorypassengers'
import Prepassengerdetails from './Prepassengerdetails'
import { currencyFormatter } from '../../../../utils/common'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

const CustomFooter = () => {
  return (
    <Grid
      container
      gap={2}
      paddingLeft={2}
      // height={"50px"}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderTop={'1px solid #e5e5e5'}
    >
      <Grid item display={'flex'} gap={1}>
        <Typography variant='subtitle1' color='initial'>
          Tags
        </Typography>
        <Stack direction={'row'} spacing={1}>
          <Chip
            label='Not Available'
            sx={{ backgroundColor: '#f123', width: '100%' }}
          />
          <Chip
            label='(E) Economy Seat'
            sx={{ backgroundColor: '#2376', width: '100%', color: 'white' }}
          />
          {/* <Chip
            label='(B) Business Seat'
            sx={{ backgroundColor: '#2376', width: '100%', color: 'white' }}
          /> */}
        </Stack>
      </Grid>
      <GridPagination />
    </Grid>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  borderRadius: '0px',
  background: '#edf1f4',

  boxShadow: 24,
  p: 2,
}

export default function Seatinventorylisting({ bookings }) {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  // const [bookings, setBookings] = React.useState([])
  const [openmodal, setOpenmodal] = React.useState(false)
  const [preOpenmodal, setPreOpenmodal] = React.useState(false)
  const [openpassengermodal, setOpenpassengermodal] = React.useState(false)
  const [openprepassengermodal, setOpenprepassengermodal] =
    React.useState(false)
  const [flightInventory, setFlightInventory] = React.useState([])
  const [prow, setProw] = React.useState([])
  const [rowCode, setRowCode] = React.useState(null)
  const [preRowCode, setPreRowCode] = React.useState(null)
  const [refresh, setRefresh] = React.useState([])
  const [preRefresh, setPreRefresh] = React.useState([])
  const [preProw, setPreProw] = React.useState([])
  const [inventorydetailsrow, setInventorydetailrow] = React.useState([])
  const [seatsSums, setSeatsSums] = React.useState({})
  const [preSeatsSums, setPreSeatsSums] = React.useState({})
  const [preinventorydetailsrow, setPreInventorydetailrow] = React.useState([])
  const [passengerdetails, setPassengerdetails] = React.useState([])
  const [prePassengerdetails, setPrePassengerdetails] = React.useState([])
  const [preflightInventory, setPreflightInventory] = React.useState([])

  const handleClosemodal = () => {
    setOpenmodal(false)
    setSeatsSums({})
    setFlightInventory([])
  }
  const handlepreClosemodal = () => {
    setPreOpenmodal(false)
    setPreSeatsSums({})
    setPreflightInventory([])
  }
  const handlePassengerClosemodal = () => setOpenpassengermodal(false)

  const handlePrePassengerClosemodal = () => setOpenprepassengermodal(false)

  // async function getInventoryList() {
  //   try {
  //     const res = await getSeatinventoryreport()
  //     if (res !== 204 && res !== 401) {
  //       setBookings(res.data)
  //       console.log('@!@!!@', res.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  async function getFlightInventorydetails(id) {
    try {
      const res = await getFlightInventorydetailsApi(id)
      if (res !== 204) {
        console.log('flightInventory', res.data)
        setFlightInventory(res.data[0] ?? [])
        setPreflightInventory(res.data[1] ?? [])
        const querySeatsSums = res.data[0].reduce(
          (acc, item) => {
            if (item.balance_amount == 0) {
              acc.confirm += item.e_avail_seat
            } else {
              acc.pending += item.e_avail_seat
            }
            return acc
          },
          { pending: 0, confirm: 0 }
        )
        setSeatsSums(querySeatsSums)
        const preSeatsSums = res.data[1].reduce(
          (acc, item) => {
            if (item.is_paid == 1) {
              acc.confirm += item.total_adult + item.total_child
            }
            return acc
          },
          { pending: 0, confirm: 0 }
        )
        setPreSeatsSums(preSeatsSums)
      }
    } catch (error) {
      console.log(error)
    }
  }

  console.log('$$$$$$$$$$', seatsSums)

  async function getPassengerInventorydetails(id) {
    try {
      const res = await getPassengerInventorydetailsApi(id)
      //  console.log('passengerdetails', res.data[0]);
      if (res !== 204) {
        setPassengerdetails(res.data[0] ?? [])
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getPrePassengerInventorydetails(id) {
    try {
      const res = await getPassengerInventorydetailsApi(id)
      //  console.log('passengerdetails', res.data[0]);
      if (res !== 204) {
        setPrePassengerdetails(res.data[1] ?? [])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenmodal = (row) => {
    setInventorydetailrow(row)
    setOpenmodal(true)
    const code =
      dayjs(row?.d_date, 'DD-MM-YYYY').format('YYYY-MM-DD') + row?.flight_code
    getFlightInventorydetails(code)
    //  console.log(code)
  }

  const handlePreOpenmodal = (row) => {
    setPreInventorydetailrow(row)
    setPreOpenmodal(true)
    const code =
      dayjs(row?.d_date, 'DD-MM-YYYY').format('YYYY-MM-DD') + row?.flight_code
    getFlightInventorydetails(code)
    //  console.log(code)
  }

  console.log('open passenger modal', openpassengermodal)

  const handlePassengerOpenmodal = (row) => {
    console.log('checking')
    setProw(row)
    setOpenpassengermodal(true)
    const code =
      dayjs(row?.d_date, 'DD-MM-YYYY').format('YYYY-MM-DD') + row?.flight_code
    // getFlightInventorydetails(code)
    //  console.log(code)
    setRowCode(code)
    getPassengerInventorydetails(code)
  }

  const handlePrePassengerOpenmodal = (row) => {
    setPreProw(row)
    setOpenprepassengermodal(true)
    const code =
      dayjs(row?.d_date, 'DD-MM-YYYY').format('YYYY-MM-DD') + row?.flight_code
    // getFlightInventorydetails(code)
    //  console.log(code)
    setPreRowCode(code)
    getPrePassengerInventorydetails(code)
  }

  //   console.log('flightInventory', flightInventory)

  // React.useEffect(() => {
  //   getInventoryList()
  //   // getFlightList()
  // }, [])

  React.useEffect(() => {
    if (rowCode) getPassengerInventorydetails(rowCode)
  }, [refresh])

  React.useEffect(() => {
    if (preRowCode) getPrePassengerInventorydetails(preRowCode)
  }, [preRefresh])

  const columns = [
    { field: 'id', headerName: 'ID', width: 40 },
    {
      field: 'flight_code',
      headerName: 'Flight code',
      // flex: 1,
      width: 140,
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.flight_code}{' '}
          <p style={{ color: '#318CE7', fontWeight: '500' }}>
            ({params.row.flight_departure_from} To{' '}
            {params.row.flight_departure_to})
          </p>
        </div>
        // console.log("params",params)
      ),
    },
    { field: 'd_date', headerName: 'Date', width: 130 },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 1,
    //   width: 140,
    //   renderCell: (params) =>
    //     params.row.is_available === 1 ? (
    //       <Chip label='Available' variant='outlined' color='success' />
    //     ) : (
    //       <Chip label='Not Available' variant='outlined' color='error' />
    //     ),
    // },
    {
      field: 'e_total_seat',
      headerName: '(E) Total',
      width: 100,
      flex: 1,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>Total</p>
        </div>
      ),
    },
    // {
    //   field: 'b_total_seat',
    //   headerName: '(B) Total',

    //   width: 100,
    //   renderHeader: (params) => (
    //     <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
    //       <p style={{ color: '#ADFF2F' }}>(B)</p>
    //       <p>Total</p>
    //     </div>
    //   ),
    // },
    {
      field: 'e_hold_without_adv',
      headerName: '(E) Without adv',
      flex: 1,
      width: 130,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>Without adv</p>
        </div>
      ),
    },
    // {
    //   field: 'b_hold_without_adv',
    //   headerName: '(B) Without adv',

    //   width: 130,
    //   renderHeader: (params) => (
    //     <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
    //       <p style={{ color: '#ADFF2F' }}>(B)</p>
    //       <p>Without adv</p>
    //     </div>
    //   ),
    // },
    {
      field: 'e_hold_with_adv',
      headerName: '(E) With adv',
      flex: 1,
      width: 120,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>With adv</p>
        </div>
      ),
    },
    // {
    //   field: 'b_hold_with_adv',
    //   headerName: '(B) With adv',

    //   width: 120,
    //   renderHeader: (params) => (
    //     <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
    //       <p style={{ color: '#ADFF2F' }}>(B)</p>
    //       <p>With adv</p>
    //     </div>
    //   ),
    // },
    {
      field: 'e_confirmed_seat',
      headerName: '(E) Confirmed',
      flex: 1,
      width: 130,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>Confirmed</p>
        </div>
      ),
    },
    // {
    //   field: 'b_confirmed_seat',
    //   headerName: '(B) Confirmed',
    //   width: 130,
    //   renderHeader: (params) => (
    //     <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
    //       <p style={{ color: '#ADFF2F' }}>(B)</p>
    //       <p>Confirmed</p>
    //     </div>
    //   ),
    // },
    {
      field: 'e_available_seat',
      headerName: '(E) Available',
      flex: 1,
      width: 130,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>Available</p>
        </div>
      ),
    },
    // {
    //   field: 'b_available_seat',
    //   headerName: '(B) Available ',
    //   width: 130,
    //   renderHeader: (params) => (
    //     <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
    //       <p style={{ color: '#ADFF2F' }}>(B)</p>
    //       <p>Available</p>
    //     </div>
    //   ),
    // },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
            <TableMenu
              data={[
                {
                  id: 1,
                  title: 'Inventory Flight Details',
                  icon: <Inventory fontSize='small' color='success' />,
                  // disable: row?.pdf ? true : false,
                  fn: () => handleOpenmodal(row),
                },
                {
                  id: 4,
                  title: 'Pre-Login Inventory Flight Details',
                  icon: <Inventory fontSize='small' color='warning' />,
                  // disable: row?.pdf ? true : false,
                  fn: () => handlePreOpenmodal(row),
                },
                {
                  id: 2,
                  title: 'Passenger Details',
                  icon: <Groups2TwoTone fontSize='small' color='primary' />,
                  // disable: row?.pdf ? true : false,
                  fn: () => handlePassengerOpenmodal(row),
                },
                {
                  id: 23,
                  title: 'Pre-login Passenger Details',
                  icon: <Groups2TwoTone fontSize='small' color='secondary' />,
                  // disable: row?.pdf ? true : false,
                  fn: () => handlePrePassengerOpenmodal(row),
                },
              ]}
            />
          </>,
        ]
      },
    },
  ]

  const detailscolumns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'query_code', headerName: 'Query Code', flex: 1, minWidth: 130 },
    { field: 'agent', headerName: 'Agent Name', flex: 1, minWidth: 130 },
    {
      field: 'quoted_date',
      headerName: 'Quoted Date',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.quoted_date
          ? dayjs(params.row.quoted_date).format('DD-MM-YYYY')
          : 'N.A'
      },
    },

    { field: 'total_fare', headerName: 'Total Amt', flex: 1, minWidth: 110 },
    {
      field: 'balance_amount',
      headerName: 'Balance Amt',
      flex: 1,
      minWidth: 110,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Status',

      width: 130,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
            {row.balance_amount == 0 ? (
              <Tooltip title='Confirmed'>
                <IconButton>
                  <Chip
                    label='Fully Paid'
                    color='success'
                    variant='outlined'
                    size='medium'
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <div>
                    <Typography color='inherit'>
                      <div>
                        <p style={{ fontSize: '14px' }}>
                          Token Amt :-{' '}
                          {currencyFormatter.format(row.token_amount ?? 0)}
                        </p>
                        <p style={{ fontSize: '14px' }}>
                          Advance Amt :-{' '}
                          {currencyFormatter.format(row.advance_amount ?? 0)}
                        </p>
                        <p style={{ fontSize: '14px' }}>
                          Final Amt :-{' '}
                          {currencyFormatter.format(row.final_amount ?? 0)}
                        </p>
                      </div>
                    </Typography>
                  </div>
                }
              >
                <IconButton>
                  <Chip
                    label='Pending'
                    color='warning'
                    variant='outlined'
                    size='medium'
                  />
                </IconButton>
              </Tooltip>
            )}
          </>,
        ]
      },
    },

    {
      field: 'e_avail_seat',
      headerName: 'No of Seats',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => {
        ;<div>
          <p>{params.row.e_avail_seat}</p>
        </div>
      },
    },
  ]

  const predetailscolumns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'billing_name',
      headerName: 'Billing name',
      flex: 1,
      minWidth: 130,
    },
    { field: 'billing_phone', headerName: 'Phone No.', flex: 1, minWidth: 130 },
    {
      field: 'created_date',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.created_date
          ? dayjs(params.row.created_date).format('DD-MM-YYYY')
          : 'N.A'
      },
    },

    { field: 'total_fare', headerName: 'Total Amount', flex: 1, minWidth: 130 },
    {
      field: 'transaction_id',
      headerName: 'Transaction Id',
      flex: 1,
      minWidth: 130,
    },

    {
      field: 'totalPassenger',
      disableColumnMenu: 'true',
      headerName: 'No of seats',
      minWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <div>
          <Tooltip
            title={
              <div>
                <Typography color='inherit'>
                  <div>
                    <p>Adult : {params.row.total_adult}</p>
                    <p>Child : {params.row.total_child ?? 0}</p>
                    <p>Infant : {params.row.total_infant ?? 0}</p>
                  </div>
                </Typography>
              </div>
            }
          >
            <span>{`Total : ${
              params?.row?.total_adult + params?.row?.total_child
            } ${
              params.row.total_infant
                ? '(+' + params.row.total_infant + ' Infant)'
                : ''
            }`}</span>
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 9rem)',
        backgroundColor: 'white',
      }}
    >
      <StripedDataGrid
        loading={false} // Set loading to false or true depending on your needs
        rows={bookings?.map((item, index) => ({
          ...item,

          id: index + 1,
        }))}
        getRowId={(row) => row?.id}
        columns={columns}
        getRowClassName={(params) =>
          params.row.is_available == 0
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar, footer: CustomFooter }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <Modal
        open={openmodal}
        // onClose={handleClosemodal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div >
                <Typography
                  id='modal-modal-title'
                  sx={{ fontSize: '19px', fontWeight: 510 }}
                >
                  {inventorydetailsrow.flight_code} Flight (
                  {inventorydetailsrow.d_date})
                </Typography>
                <Typography
                  id='modal-modal-description'
                  sx={{ fontSize: '15px', color: '#A9A9A9' }}
                >
                  Inventory Flight Details
                </Typography>
              </div>
              <div style={{ marginTop : '5px',marginLeft : "20px"}}>
                <Chip
                  label={`Seats Pending : ${seatsSums.pending ?? 0}`}
                  style={{backgroundColor : '#d77676',color : 'white'}}
                  variant='filled'
                  size='medium'
                />
                &nbsp; &nbsp;
                <Chip
                  label={`Seats Booked : ${seatsSums.confirm ?? 0}`}
                  color='success'
                  variant='filled'
                  size='medium'
                />
              </div>
            </div>
            <IconButton aria-label='delete' onClick={handleClosemodal}>
              <Cancel fontSize='large'/>
            </IconButton>
          </div>

          <CardContent
            sx={{
              height: '60vh',
              width: '100%',
              borderRadius: 2,
              mt: 1,
              padding: 0,
              background: 'white',
            }}
          >
            <StripedDataGrid
              loading={false} // Set loading to false or true depending on your needs
              rows={flightInventory?.map((item, index) => ({
                ...item,

                id: index + 1,
              }))}
              columns={detailscolumns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 7,
                  },
                },
              }}
              pageSizeOptions={[7]}
              getRowClassName={(params) =>
                params.row.is_available == 1
                  ? 'void'
                  : params.indexRelativeToCurrentPage % 2 === 0
                  ? 'even'
                  : 'odd'
              }
            />
          </CardContent>
        </Card>
      </Modal>
      <Modal
        open={preOpenmodal}
        // onClose={handleClosemodal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', gap: 4 }}>
              <div>
                <Typography
                  id='modal-modal-title'
                  sx={{ fontSize: '19px', fontWeight: 510 }}
                >
                  {preinventorydetailsrow.flight_code} Flight (
                  {preinventorydetailsrow.d_date})
                </Typography>
                <Typography
                  id='modal-modal-description'
                  sx={{ fontSize: '15px', color: '#A9A9A9' }}
                >
                  Pre-login Inventory Flight Details
                </Typography>
              </div>
              <div style={{ marginTop: '5px', marginLeft: '20px' }}>
                <Chip
                  label={`Total Seats Booked : ${preSeatsSums.confirm ?? 0}`}
                  color='success'
                  variant='filled'
                  size='medium'
                />
              </div>
            </div>
            <IconButton aria-label='delete' onClick={handlepreClosemodal}>
              <Cancel fontSize='large'/>
            </IconButton>
          </div>

          <CardContent
            sx={{
              height: '60vh',
              width: '100%',
              borderRadius: 2,
              mt: 1,
              padding: 0,
              background: 'white',
            }}
          >
            <StripedDataGrid
              loading={false} // Set loading to false or true depending on your needs
              rows={preflightInventory?.map((item, index) => ({
                ...item,

                id: index + 1,
              }))}
              columns={predetailscolumns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 7,
                  },
                },
              }}
              pageSizeOptions={[7]}
              getRowClassName={(params) =>
                params.row.is_available == 1
                  ? 'void'
                  : params.indexRelativeToCurrentPage % 2 === 0
                  ? 'even'
                  : 'odd'
              }
            />
          </CardContent>
        </Card>
      </Modal>
      <Modal
        open={openpassengermodal}
        // onClose={handleClosemodal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography
                id='modal-modal-title'
                sx={{ fontSize: '19px', fontWeight: 510 }}
              >
                {prow.flight_code} Flight ({prow.d_date})
              </Typography>
              <Typography
                id='modal-modal-description'
                sx={{ fontSize: '15px', color: '#A9A9A9' }}
              >
                Passengers Details
              </Typography>
            </div>
            <IconButton aria-label='delete' onClick={handlePassengerClosemodal}>
              <Cancel />
            </IconButton>
          </div>

          <CardContent
            sx={{ borderRadius: 2, mt: 1, padding: 0, background: 'white' }}
          >
            <Inventorypassengers
              passengerdetails={passengerdetails}
              setRefresh={setRefresh}
            />
          </CardContent>
        </Card>
      </Modal>
      <Modal
        open={openprepassengermodal}
        // onClose={handleClosemodal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography
                id='modal-modal-title'
                sx={{ fontSize: '19px', fontWeight: 510 }}
              >
                {preProw.flight_code} Flight ({preProw.d_date})
              </Typography>
              <Typography
                id='modal-modal-description'
                sx={{ fontSize: '15px', color: '#A9A9A9' }}
              >
                Pre-Login Passengers Details
              </Typography>
            </div>
            <IconButton
              aria-label='delete'
              onClick={handlePrePassengerClosemodal}
            >
              <Cancel />
            </IconButton>
          </div>

          <CardContent
            sx={{ borderRadius: 2, mt: 1, padding: 0, background: 'white' }}
          >
            <Prepassengerdetails
              prePassengerdetails={prePassengerdetails}
              flightcode={preProw?.flight_code}
              setPreRefresh={setPreRefresh}
            />
          </CardContent>
        </Card>
      </Modal>
    </div>
  )
}
