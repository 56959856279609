import { getHsnCodeApi, getLedgerListApi, getPrimaryGroupMasterApi, getSubGroupMasterApi, getTaxSlabApi } from "../services/admin.configService";

export async function getTaxSlab() {
    try {
      const res = await getTaxSlabApi();
      if (res !== 204 && res !== 404) {
        return(res?.data);
      }
    } catch (error) {
      console.log("err ",error);
    }
  }

 export  async function getLedger() {
    try {
      const res = await getLedgerListApi();
      if (res !== 204 && res !== 404) {
        return(res?.data);
      }
    } catch (error) {
      console.log("err ",error);
    }
  }

 export  async function getPrimaryGroupMaster() {
    try {
      const res = await getPrimaryGroupMasterApi();
      if (res !== 204 && res !== 404) {
        return(res?.data);
      }
    } catch (error) {
      console.log("err ",error);
    }
  }

 export async function getSubGroupMaster() {
    try {
      const res = await getSubGroupMasterApi();
      if (res !== 204 && res !== 404) {
        return(res?.data);
      }
    } catch (error) {
      console.log("err ",error);
    }
  }

 export async function getHsnCode() {
    try {
      const res = await getHsnCodeApi();
      if (res !== 204 && res !== 404) {
        return(res?.data);
      }
    } catch (error) {
      console.log("err ",error);
    }
  }


