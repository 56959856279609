export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const WEEKS = [
  { id: 0, label: "Sun" },
  { id: 1, label: "Mon" },
  { id: 2, label: "Tue" },
  { id: 3, label: "Wed" },
  { id: 4 ,label: "Thu" },
  { id: 5, label: "Fri" },
  { id: 6, label: "Sat" },
];


export const BOOKING_POLICY_FIT = [
  { id: 1, policy: 'Cancellation within 24 hours of departure is treated as NO SHOW.' },
  { id: 2, policy: 'In case of NO SHOW only taxes are to be refunded as per invoice. (Excluding YR & YQ)' },
  { id: 3, policy: 'Cancellation before 24 hours of departure - charges will be 60 euros per coupon.' },
  { id: 4, policy: 'Date change charges - 60 euros per coupon.' }
];

export const BOOKING_POLICY_GIT = [
  { id: 1, policy: 'Group Utilisation: 90%' },
  { id: 2, policy: '*Ticket Cancellation charges: After issuance - Non-refundable' },
  { id: 3, policy: 'Free release of 10% seats: D-25, 25 days before STD.' },
  { id: 4, policy: '* Naming List required: D-7, 7 days before STD.' },
  { id: 5, policy: 'As a special case Name Change after issuance of tickets before 48 hours of travel @ Rs.12,500/- per person.' }
];
