import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  gridClasses,
} from '@mui/x-data-grid'
import {
  getChargehead,
  getCustomerbooking,
  getDisabledate,
  getPrebookingPassengerUpdate,
} from '../../../services/configService'
import {
  ArrowRightAlt,
  Cancel,
  Delete,
  Download,
  Edit,
  List,
  Save,
  SyncAlt,
  Upload,
} from '@mui/icons-material'
import {
  Button,
  Tooltip,
  Grid,
  Popover,
  Typography,
  Card,
  IconButton,
  CardContent,
  TextField,
  Modal,
  Box,
} from '@mui/material'
import { currencyFormatter, handleChangeGlobal } from '../../../utils/common'
import TableMenu from '../../../components/table_modal/TableMenu'
import dayjs from 'dayjs'
import { SnackbarContext } from '../../../context/Snackbar'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 330,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 2,
}

export default function Chargehead({isUpdate}) {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [chargehead, setChargehead] = React.useState([])
  const [disableDate, setDisableDate] = React.useState({
    disable_from_date:""
  })
  const [updateDate, setUpdateDate] = React.useState({})
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openchangedatemodal, setOpenchangedatemodal] = React.useState(false)
  const handleChangedateClosemodal = () => setOpenchangedatemodal(false)

  async function getChargeheadlist() {
    try {
      const res = await getChargehead()
      if (res !== 204 && res !== 401) {
        setChargehead(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
 

  const handleChangedateOpenmodal = (row) => {
    setOpenchangedatemodal(true)
    setUpdateDate(row)
    console.log('id', id, row)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleChangedates = async () => {

    const sendData = {
      id: updateDate.id,
      disable_from_date: dayjs(disableDate?.disable_from_date).format('YYYY-MM-DD'),
      is_disable: 1,
    }

    try {
      const res = await getDisabledate(sendData)
      console.log('res', res)
      if (res !== 406) {
        setOpenchangedatemodal(false)
        getChargeheadlist()
        setOn(true)
        setSeverity('success')
        setMes('Disable date set successfully')
      }
    
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  React.useEffect(() => {
    getChargeheadlist()
  }, [isUpdate])

  const columns = [
    {
      field: 'id',
      headerName: 'ID',

      width: 80,
      valueGetter: (params) => params.value ?? 'N.A',
    },

    {
      field: 'charge_master_code',
      headerName: 'Charge Code',
      flex: 1,
      width: 150,
    },

    {
      field: 'orginDest',
      headerName: 'Orgin & Destination',
      minWidth: 150,
      disableColumnMenu: 'true',
      flex: 1,
      renderCell: (params) => (
        <div>
          <Tooltip
            title={
              <div style={{ fontSize: '13px' }}>
              <div>
                {params?.row?.is_return === 1
                  ? 'Round trip'
                  : params?.row?.is_return === 0
                  ? 'One-way trip'
                  : 'Multi-City'}
              </div>
              <div>{params?.row?.origin_code}</div>
              <div>{params?.row?.destination_code}</div>
            </div>
            }
          >
            <div
              style={{
                display: 'flex',
                gap: '6px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontWeight: '600' }}>
                {params?.row?.origin_code}
              </div>
              {params?.row?.is_return == 1 ? (
                <SyncAlt />
              ) : (
                <ArrowRightAlt />
              )}
              <div style={{ fontWeight: '600' }}>
                {params?.row?.destination_code}
              </div>
              {params?.row?.is_return == 3 && <ArrowRightAlt />}
              <div style={{ fontWeight: '600' }}>
                {params?.row?.return_destination}
              </div>
            </div>
          </Tooltip>
        </div>
      ),
    },
    // { field: 'is_return', headerName: 'Group Type', flex: 1, width: 150 },
    { field: 'amount', headerName: 'Amount', flex: 1, width: 150 },
    {
      field: 'effective_date',
      headerName: 'Effective Date',
      flex: 1,
      width: 300,
      valueGetter: (params) => dayjs(params.value).format('DD-MM-YYYY'),
    },
    {
      field: 'created_date',
      headerName: 'Created Date',
      flex: 1,
      width: 150,
      valueGetter: (params) => dayjs(params.value).format('DD-MM-YYYY'),
    },
    {
      field: 'disable_from_date',
      headerName: 'Disable Date',
      flex: 1,
      width: 150,

      valueGetter: (params) =>
        params.value ? dayjs(params?.value).format('DD-MM-YYYY') : 'N.A',
    },
    // { field: 'updated_date', headerName: 'Updated Date', flex: 1, width: 150 },

    // Add more dummy columns here

    // {
    //   type: 'actions',
    //   headerName: 'Passenger',
    //   minWidth: 120,
    //   cellClassName: 'actions',
    //   flex: 1,
    //   getActions: ({ row, id }) => {
    //     return [
    //       <div>
    //         <Button
    //           // onClick={()=>handleSaveSession(row)}
    //           onClick={(e) => handleClick(e, row)}
    //           variant='outlined'
    //           color='success'
    //           startIcon={<Edit />}
    //         >
    //           Edit
    //         </Button>
    //       </div>,
    //     ]
    //   },
    // },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
          {!row.is_disable ? (
          <TableMenu
            data={[
              {
                id: 1,
                title: 'Set Disable Dates',
                icon: <Edit fontSize='small' color='success' />,
                // disable: row?.pdf ? true : false,
                fn: () => handleChangedateOpenmodal(row),
              },
            ]}
          />
        ) : null}
        </>,
        ]
      },
    },
  ]

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 9rem)',
        backgroundColor: 'white',
      }}
    >
      <StripedDataGrid
        loading={false} // Set loading to false or true depending on your needs
        rows={chargehead?.map((item, index) => ({
          ...item,

          sno: index + 1,
        }))}
        columns={columns}
        getRowClassName={(params) =>
            params.row.is_disable == 1
            ? 'cancel'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
      />

      <Modal
        open={openchangedatemodal}
        onClose={handleChangedateClosemodal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography
                id='modal-modal-title'
                sx={{ fontSize: '19px', fontWeight: 510 }}
              >
                Disable Date
              </Typography>
              <Typography
                id='modal-modal-description'
                sx={{ fontSize: '15px', color: '#A9A9A9' }}
              >
                Change & Update Disable Dates.
              </Typography>
            </div>
            <IconButton
              aria-label='delete'
              onClick={handleChangedateClosemodal}
            >
              <Cancel />
            </IconButton>
          </div>

          <CardContent sx={{ background: '#edf1f4', borderRadius: 2, mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Disable date'
                    // className='form_style'
                    format='DD-MM-YYYY'
                    name='disable_from_date'
                    value={dayjs(disableDate.disable_from_date)}
                    onChange={(newDate) => {
                      setDisableDate((prevDetails) => ({
                        ...prevDetails,
                        disable_from_date: newDate,
                      }))
                    }}
                    // error={error && error['starting_from']}
                    slotProps={{
                      textField: {
                        size: 'small',
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </CardContent>
          <Button
            onClick={handleChangedates}
            startIcon={<Save />}
            variant='contained'
            sx={{ background: '#01357E', float: 'right', mt: 1 }}
          >
            save
          </Button>
        </Card>
      </Modal>
    </div>
  )
}
