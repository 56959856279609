import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { ArrowForward } from '@mui/icons-material'
import CustomTextArea from '../common/CustomTextArea'
import { postInquires } from '../../services/configService'
import { SnackbarContext } from '../../context/Snackbar'
import { useMediaQuery, useTheme } from '@mui/material'

import { useNavigate } from 'react-router-dom'
const Inqueries = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [isFlipped, setIsFlipped] = React.useState(false)
  const [data, setData] = React.useState({
    full_name: '',
    phone_no: '',
    email: '',
  })
  const [messages, setMessages] = React.useState('')

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const cardContainerStyle = {
    perspective: '1000px',
  }

  const cardStyle = {
    // width: '300px',
    // height: '1000px',
    position: 'relative',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.6s',
    transform: isFlipped ? 'rotateY(180deg)' : 'none',
  }

  const cardFaceStyle = {
    position: 'absolute',
    marginTop: '20px',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
  }

  const cardBackStyle = {
    ...cardFaceStyle,
    transform: 'rotateY(180deg)',
  }

  const handleBookingnevigate = () => {
    navigate('/')
  }

  const generateMessage = async () => {
    if (data.phone_no?.length < 10) {
      setSeverity('error')
      setMes('Enter Valid Number')
      setOn(true)
      return 0
    }
    const sendData = {
      full_name: data.full_name,
      email: data.email,
      phone: data.phone_no,
      message: messages,
    }

    try {
      const res = await postInquires(sendData)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Message sent Successfully')
        setOn(true)
        setData({
          full_name: '',
          phone_no: '',
          email: '',
        })
        setMessages('')
        setIsFlipped(!isFlipped)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('Please check your details')
      setOn(true)
    }
  }
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {isSmallScreen ? (
        <Grid container spacing={4} sx={{ background: 'lightgrey' }}>
          <Grid item md={7} xs={12}>
            <div
              style={{
                // height: '100%',
                marginTop: '40px',

                //  marginLeft: '50px',
              }}
            >
              <Card
                sx={{
                  // width: '400px',
                  borderRadius: 6,
                  background: 'white',
                  color: 'black',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  //  marginLeft: '43px',
                  margin: '20px',
                }}
              >
                <CardContent sx={{ padding: '20px' }}>
                  <p
                    style={{
                      marginTop: '30px',
                      fontSize: '2.3rem',
                      lineHeight: 1,
                      fontWeight: 600,
                    }}
                  >
                    Fly with Zeal Azerbaijan Airlines and Book Your Next
                    Adventure Today
                  </p>
                  <p
                    style={{
                      marginBottom: '20px',
                      fontSize: '1.50rem',
                      fontWeight: 600,
                    }}
                  >
                    {/* Zeal Azerbaijan Airlines and Book Your Next Adventure */}
                  </p>

                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      {' '}
                      <p
                        style={{
                          marginBottom: '10px',
                          fontSize: '15px',
                          fontWeight: 500,
                          color: 'black',
                        }}
                      >
                        Discover seamless travel with GSA for Azerbaijan
                        Airlines in India where every journey is an adventure
                        waiting to unfold. Whether you are jet-setting for
                        business or pleasure, our commitment to comfort, safety,
                        and unparalleled service ensures your travel is not just
                        a trip, but a memorable experience.
                      </p>
                    </Grid>

                    <Grid item md={12}>
                      <video width='99%' height='auto' autoPlay loop muted>
                        <source src='/images/zeal.mp4' type='video/mp4' />
                      </video>{' '}
                      <br />
                      <br />
                      <p
                        style={{
                          marginBottom: '20px',
                          fontSize: '15px',
                          fontWeight: 500,
                          color: 'black',
                        }}
                      >
                        <strong>Why Choose Us?</strong>
                        <br />
                        <br />
                        <ul style={{ marginLeft: '20px' }}>
                          <li>
                            Best Price Guarantee: Find the lowest fares on
                            flights to Delhi-Baku-Delhi and Mumbai-Baku-Mumbai.
                          </li>
                          <li>
                            Exclusive Offers: Enjoy special discounts and
                            rewards when you book directly with us.
                          </li>
                          <li>
                            Easy Booking: Quick and hassle-free booking process.
                          </li>
                          <li>
                            24/7 Support: Our team is here around the clock to
                            assist you.
                          </li>
                        </ul>
                      </p>
                    </Grid>

                    <Grid item md={12}>
                      <p
                        style={{
                          marginBottom: '40px',
                          fontSize: '15px',
                          fontWeight: 500,
                          color: 'black',
                        }}
                      >
                        <strong>Book Now and Save!</strong>
                        <br />
                        <br />
                        Don't miss out on our exclusive deals. Secure your seat
                        today and embark on your next journey with Azerbaijan
                        Airlines in India Join millions of travelers who trust
                        Azerbaijan Airlines for their journeys. Whether you’re
                        planning ahead or seeking last-minute travel, your
                        adventure begins with us. Explore our routes, discover
                        our offers, and start your journey today.
                      </p>
                    </Grid>
                    <Grid item md={12}>
                      {/* <video width='660' height='340' autoPlay loop muted>
               <source src='/images/zeal.mp4' type='video/mp4' />
             </video> */}
                      {/* <video width='100%' height='400' autoPlay loop muted>
               <source
                 src='/images/zeal.mp4'
                 type='video/mp4'
               />
               
               Your browser does not support the video tag.
             </video> */}
                    </Grid>
                    <Grid item md={12}>
                      <Button
                        variant='contained'
                        sx={{
                          float: 'right',
                          color: 'white',
                          background: 'black',
                          borderRadius: 7,
                          fontWeight: 900,

                          '&:hover': {
                            color: 'black',
                            backgroundColor: 'lightgrey',
                          },
                        }}
                        onClick={handleBookingnevigate}
                        endIcon={<ArrowForward />}
                      >
                        Book Now
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            {/* <div style={cardBackStyle}>
         <Card
           sx={{
             // width: '380px',
             borderRadius: 6,
             background: '#3457D5',
             color: 'white',
           }}
         >
           <CardContent sx={{ padding: '40px' }}>
             <p
               style={{
                 marginTop: '30px',
                 fontSize: '4rem',
                 lineHeight: 1,
               }}
             >
               Thank
             </p>
             <p
               style={{
                 marginBottom: '20px',
                 fontSize: '3.50rem',
                 fontWeight: 600,
               }}
             >
               You.
             </p>

             <Grid container spacing={2}>
               <Grid item md={12}>
                 {' '}
                 <p
                   style={{
                     marginBottom: '40px',
                     fontSize: '25px',
                     fontWeight: 600,
                     color: '#ffffff87',
                   }}
                 >
                   for being so nice
                 </p>
               </Grid>
               <Grid item md={12}></Grid>
               <Grid item md={12}></Grid>
               <Grid item md={12}>
                 {' '}
                 <p
                   style={{
                     marginBottom: '40px',
                     fontSize: '20px',
                   }}
                 >
                   We'll be in touch
                   <br />
                   Shortly
                 </p>
               </Grid>
               <Grid item md={12}></Grid>
               <Grid item md={12}></Grid>
               <Grid item md={12}></Grid>
               <Grid item md={12}></Grid>
               <Grid item md={12}>
                 <Button
                   variant='contained'
                   sx={{
                     float: 'right',
                     color: '#3457D5',
                     background: 'white',
                     borderRadius: 7,
                     fontWeight: 500,
                   }}
                   endIcon={<ArrowForward />}
                 >
                   Next
                 </Button>
               </Grid>
             </Grid>
           </CardContent>
         </Card>
       </div> */}
          </Grid>
          <Grid item md={4} xs={12}>
            <div
              style={{
                // height: '100%',
                marginTop: '40px',
                
                //  marginLeft: '70px',
                // marginBottom: '27px',
              }}
            >
              <Card
                sx={{
                  borderRadius: 6,
                  // border: "2px solid",
                  color: 'white',
                  background: '#3457D5',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  margin: '20px',
                }}
              >
                <CardContent sx={{ padding: '25px' }}>
                  <h1 style={{ marginBottom: '40px', marginTop: '30px' }}>
                    Get in touch
                  </h1>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} >
                      <Typography
                        variant='body2'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 600,
                          marginBottom: '5px',
                          // color: '#3457D5',
                        }}
                      >
                        Full Name
                      </Typography>
                      <TextField
                        fullWidth
                        className='form_style'
                        id='outlined-basic'
                        //  label='Name'
                        variant='outlined'
                        size='small'
                        name='full_name'
                        value={data.full_name}
                        // error={error && error['full_name']}
                        onChange={(e) => handleChange(e, 'full_name')}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography
                        variant='body2'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 600,
                          marginBottom: '5px',
                          // color: '#3457D5',
                        }}
                      >
                        Phone No.
                      </Typography>
                      <TextField
                        className='form_style'
                        id='outlined-basic'
                        // label='Number'
                        variant='outlined'
                        size='small'
                        fullWidth
                        name='phone_no'
                        
                        value={data.phone_no}
                        // error={error && error['phone_no']}
                        onChange={(e) => {
                          e.target.value.length < 11 &&
                            handleChange(e, 'phone_no')
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography
                        variant='body2'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 600,
                          marginBottom: '5px',
                          // color: '#3457D5',
                        }}
                      >
                        Email
                      </Typography>
                      <TextField
                        className='form_style'
                        id='outlined-basic'
                        // label='Email'
                        variant='outlined'
                        size='small'
                        fullWidth
                        name='email'
                        sx={{ color: 'white' }}
                        value={data.email}
                        // error={error && error['email']}
                        onChange={(e) => handleChange(e, 'email')}
                      />
                    </Grid>
             
                    <Grid item md={12} xs={12}>
                      <Typography
                        variant='body2'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 600,
                          marginBottom: '15px',
                          // color: '#3457D5',
                        }}
                        fullWidth
                      >
                        Message
                      </Typography>
                      <CustomTextArea
                        placeholder={'Message...'}
                        name={'remark'}
                        value={messages}
                        onChange={(e) => setMessages(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Button
                        variant='contained'
                        sx={{
                          
                          // background: '#3457D5',
                          borderRadius: 7,
                          float: 'right',
                          color: '#3457D5',
                          background: 'white',
                          borderRadius: 7,
                          fontWeight: 900,

                          '&:hover': {
                            color: 'black',
                            backgroundColor: 'lightgrey',
                          },
                        }}
                        endIcon={<ArrowForward />}
                        onClick={generateMessage}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>

          {/* <Grid item md={4} xs={4} ></Grid> */}
        </Grid>
      ) : (
        <Grid container spacing={4} sx={{ background: 'lightgrey' }}>
          <Grid item md={7} xs={12}>
            <div
              style={{
                // height: '100%',
                marginTop: '40px',

                marginLeft: '50px',
              }}
            >
              <Card
                sx={{
                  // width: '400px',
                  borderRadius: 6,
                  background: 'white',
                  color: 'black',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  marginLeft: '43px',
                }}
              >
                <CardContent sx={{ padding: '20px' }}>
                  <p
                    style={{
                      marginTop: '30px',
                      fontSize: '2.3rem',
                      lineHeight: 1,
                      fontWeight: 600,
                    }}
                  >
                    Fly with Zeal Azerbaijan Airlines and Book Your Next
                    Adventure Today
                  </p>
                  <p
                    style={{
                      marginBottom: '20px',
                      fontSize: '1.50rem',
                      fontWeight: 600,
                    }}
                  >
                    {/* Zeal Azerbaijan Airlines and Book Your Next Adventure */}
                  </p>

                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      {' '}
                      <p
                        style={{
                          marginBottom: '10px',
                          fontSize: '15px',
                          fontWeight: 500,
                          color: 'black',
                        }}
                      >
                        Discover seamless travel with GSA for Azerbaijan
                        Airlines in India where every journey is an adventure
                        waiting to unfold. Whether you are jet-setting for
                        business or pleasure, our commitment to comfort, safety,
                        and unparalleled service ensures your travel is not just
                        a trip, but a memorable experience.
                      </p>
                    </Grid>

                    <Grid item md={12}>
                      <video width='99%' height='400' autoPlay loop muted>
                        <source src='/images/zeal.mp4' type='video/mp4' />
                      </video>{' '}
                      <br />
                      <br />
                      <p
                        style={{
                          marginBottom: '20px',
                          fontSize: '15px',
                          fontWeight: 500,
                          color: 'black',
                        }}
                      >
                        <strong>Why Choose Us?</strong>
                        <br />
                        <br />
                        <ul style={{ marginLeft: '20px' }}>
                          <li>
                            Best Price Guarantee: Find the lowest fares on
                            flights to Delhi-Baku-Delhi and Mumbai-Baku-Mumbai.
                          </li>
                          <li>
                            Exclusive Offers: Enjoy special discounts and
                            rewards when you book directly with us.
                          </li>
                          <li>
                            Easy Booking: Quick and hassle-free booking process.
                          </li>
                          <li>
                            24/7 Support: Our team is here around the clock to
                            assist you.
                          </li>
                        </ul>
                      </p>
                    </Grid>

                    <Grid item md={12}>
                      <p
                        style={{
                          marginBottom: '40px',
                          fontSize: '15px',
                          fontWeight: 500,
                          color: 'black',
                        }}
                      >
                        <strong>Book Now and Save!</strong>
                        <br />
                        <br />
                        Don't miss out on our exclusive deals. Secure your seat
                        today and embark on your next journey with Azerbaijan
                        Airlines in India Join millions of travelers who trust
                        Azerbaijan Airlines for their journeys. Whether you’re
                        planning ahead or seeking last-minute travel, your
                        adventure begins with us. Explore our routes, discover
                        our offers, and start your journey today.
                      </p>
                    </Grid>
                    <Grid item md={12}>
                      {/* <video width='660' height='340' autoPlay loop muted>
                  <source src='/images/zeal.mp4' type='video/mp4' />
                </video> */}
                      {/* <video width='100%' height='400' autoPlay loop muted>
                  <source
                    src='/images/zeal.mp4'
                    type='video/mp4'
                  />
                  
                  Your browser does not support the video tag.
                </video> */}
                    </Grid>
                    <Grid item md={12}>
                      <Button
                        variant='contained'
                        sx={{
                          float: 'right',
                          color: 'white',
                          background: 'black',
                          borderRadius: 7,
                          fontWeight: 900,

                          '&:hover': {
                            color: 'black',
                            backgroundColor: 'lightgrey',
                          },
                        }}
                        onClick={handleBookingnevigate}
                        endIcon={<ArrowForward />}
                      >
                        Book Now
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            {/* <div style={cardBackStyle}>
            <Card
              sx={{
                // width: '380px',
                borderRadius: 6,
                background: '#3457D5',
                color: 'white',
              }}
            >
              <CardContent sx={{ padding: '40px' }}>
                <p
                  style={{
                    marginTop: '30px',
                    fontSize: '4rem',
                    lineHeight: 1,
                  }}
                >
                  Thank
                </p>
                <p
                  style={{
                    marginBottom: '20px',
                    fontSize: '3.50rem',
                    fontWeight: 600,
                  }}
                >
                  You.
                </p>

                <Grid container spacing={2}>
                  <Grid item md={12}>
                    {' '}
                    <p
                      style={{
                        marginBottom: '40px',
                        fontSize: '25px',
                        fontWeight: 600,
                        color: '#ffffff87',
                      }}
                    >
                      for being so nice
                    </p>
                  </Grid>
                  <Grid item md={12}></Grid>
                  <Grid item md={12}></Grid>
                  <Grid item md={12}>
                    {' '}
                    <p
                      style={{
                        marginBottom: '40px',
                        fontSize: '20px',
                      }}
                    >
                      We'll be in touch
                      <br />
                      Shortly
                    </p>
                  </Grid>
                  <Grid item md={12}></Grid>
                  <Grid item md={12}></Grid>
                  <Grid item md={12}></Grid>
                  <Grid item md={12}></Grid>
                  <Grid item md={12}>
                    <Button
                      variant='contained'
                      sx={{
                        float: 'right',
                        color: '#3457D5',
                        background: 'white',
                        borderRadius: 7,
                        fontWeight: 500,
                      }}
                      endIcon={<ArrowForward />}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div> */}
          </Grid>
          <Grid item md={4} xs={12}>
            <div
              style={{
                // height: '100%',
                marginTop: '40px',
                marginLeft: '70px',
                marginBottom: '27px',
              }}
            >
              <Card
                sx={{
                  borderRadius: 6,
                  // border: "2px solid",
                  color: 'white',
                  background: '#3457D5',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                <CardContent sx={{ padding: '25px' }}>
                  <h1 style={{ marginBottom: '40px', marginTop: '30px' }}>
                    Get in touch
                  </h1>

                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Typography
                        variant='body2'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 600,
                          marginBottom: '5px',
                          // color: '#3457D5',
                        }}
                      >
                        Full Name
                      </Typography>
                      <TextField
                        className='form_style'
                        id='outlined-basic'
                        label='Name'
                        variant='outlined'
                        size='small'
                        fullWidth
                        name='full_name'
                        value={data.full_name}
                        // error={error && error['full_name']}
                        onChange={(e) => handleChange(e, 'full_name')}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Typography
                        variant='body2'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 600,
                          marginBottom: '5px',
                          // color: '#3457D5',
                        }}
                      >
                        Phone No.
                      </Typography>
                      <TextField
                        className='form_style'
                        id='outlined-basic'
                        label='Number'
                        variant='outlined'
                        size='small'
                        fullWidth
                        name='phone_no'
                        value={data.phone_no}
                        // error={error && error['phone_no']}
                        onChange={(e) => {
                          e.target.value.length < 11 &&
                            handleChange(e, 'phone_no')
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Typography
                        variant='body2'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 600,
                          marginBottom: '5px',
                          // color: '#3457D5',
                        }}
                      >
                        Email
                      </Typography>
                      <TextField
                        className='form_style'
                        id='outlined-basic'
                        label='Email'
                        variant='outlined'
                        size='small'
                        fullWidth
                        name='email'
                        sx={{ color: 'white' }}
                        value={data.email}
                        // error={error && error['email']}
                        onChange={(e) => handleChange(e, 'email')}
                      />
                    </Grid>
                    {/* <Grid item md={12}>
                <Typography
                  variant='body2'
                  sx={{
                    fontSize: '13px',
                    fontWeight: 600,
                    marginBottom: '5px',
                    color: '#3457D5',
                  }}
                >
                  Enquiry for?
                </Typography>
                <TextField
                  id='outlined-basic'
                  //  label='Phone No.'
                  variant='standard'
                  size='medium'
                  fullWidth
                />
              </Grid> */}
                    <Grid item md={12}>
                      <Typography
                        variant='body2'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 600,
                          marginBottom: '15px',
                          // color: '#3457D5',
                        }}
                      >
                        Message
                      </Typography>
                      <CustomTextArea
                        placeholder={'Message...'}
                        name={'remark'}
                        value={messages}
                        onChange={(e) => setMessages(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Button
                        variant='contained'
                        sx={{
                          float: 'right',
                          // background: '#3457D5',
                          borderRadius: 7,
                          float: 'right',
                          color: '#3457D5',
                          background: 'white',
                          borderRadius: 7,
                          fontWeight: 900,

                          '&:hover': {
                            color: 'black',
                            backgroundColor: 'lightgrey',
                          },
                        }}
                        endIcon={<ArrowForward />}
                        onClick={generateMessage}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>

          {/* <Grid item md={4} xs={4} ></Grid> */}
        </Grid>
      )}
    </>
  )
}

export default Inqueries
