import React, { useContext, useEffect, useState } from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import NotificationsIcon from '@mui/icons-material/Notifications'
import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  AccountCircle,
  CircleNotifications,
  Dashboard,
  Delete,
  Email,
  Logout,
} from '@mui/icons-material'
import {
  changeProfilePicApi,
  clearNotificationApi,
  logout,
  readNotification,
  userAuthServer,
} from '../../services/configService'
import useAuth from '../../hooks/useAuth'
import { NavLink, useNavigate } from 'react-router-dom'
import { SnackbarContext } from '../../context/Snackbar';

import TableModal from '../table_modal/TableModal'
import style from './header.module.css'



const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    style={{ paddingTop: '300px' }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(2),
    minWidth: 200,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

const NAMES = {
  1 : "Admin",
  2  : "User",
  3 : "Sales",
  4 : "Finance"
}


const ProfileMenu = React.memo(() => {
  const theme = useTheme()
  const { setAuth, auth } = useAuth()
  const { notificationlist, getnotifications,setOn,setNotificationlist, setSeverity, setMes } = useContext(SnackbarContext)
  const navigate = useNavigate()
  const [showDpModal, setShowDpModal] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorEll, setAnchorEll] = React.useState(null)
  const [error, setError] = useState('')
  const openn = Boolean(anchorEll);
  const [isLoading, setIsLoading] = useState(false);
  const [count,setCount] = useState(0)

  const handleNotify = (event,id) => {
    setAnchorEll(event.currentTarget)
  }

  const handleReadNoti = async(id) => {
    const sendData = {
      id
    }
    try {
      const res =await readNotification(sendData);
      if(res !== 401 && res !==406){
        getnotifications('read notification /profilemenu')
        console.log("read successfully")
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleOpenDpModal = () => {
    setShowDpModal(true)
  }

  const handleCloseDp = () => {
    setShowDpModal(false)
  }

  const handleClosenotify = () => {
    setAnchorEll(null)
    // getnotifications() // need check
  }

  const open = Boolean(anchorEl)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    
  }

  const handledashBoardClose = () => {
    setAnchorEl(null)
    navigate('/dashboard');
   
  }

  // console.log("!@#$%",notificationlist);

  const handelLogout = async () => {
    const res = await logout()
    if (res !== 204 && res !== 401) {
      if (res.status) {
        setAuth({
          isAuthenticated: res.isAuthenticated,
          user: null,
          role: 0,
        })
        setNotificationlist([])
        navigate('/', { replace: true })
      }
    }
    setAnchorEl(null)
  }

  const handleClear = async () => {
    try {
      const res = await clearNotificationApi()
      if (res !== 406 && res !== 204) {
        // getnotifications(); // need check
        setNotificationlist([]);
      }
    } catch (error) {}
  }

  async function dpUpdate() {
    try {
      setIsLoading(true)
      const res = await userAuthServer();
      if (res === 401) {
      } else if (res.status) {
        setAuth((pre) => ({
          ...pre,
          details : res?.data?.details,
        }));
      }
    } catch (error) {
      console.log("error in app.js auth ", error.message);
    }
    finally{
      setIsLoading(false)
    }
  }

  const handleDpChange = async (e) => {
    const dp = e.target.files[0]
    try {
      setIsLoading(true)
      const data = new FormData()
      data.append('file', dp)
      const res = await changeProfilePicApi(data, setError)
      if (res !== 406) {
        setOn(true);
      setSeverity("success");
      setMes("Profile updated successfully");
      console.log("done")
       await dpUpdate()
      // window.location.reload(); 
      }
    } catch (error) {
      setOn(true);
      setSeverity("error");
      setMes(error.message);
    }
    finally{
      setIsLoading(false)
    }
  }

  const handleNotificationnav = (type, query_code, id) => {
    handleReadNoti(id);
    switch (type) {
        case 1:
        case 2:
            navigate(`/query-list/${query_code}`, { replace: false });
            setAnchorEll(null);
            break;
        case 3:
            if (auth?.role === 4) {
                navigate(`/booking-payment/${query_code}`, { replace: false });
            } else {
                navigate(`/booking/${query_code}`, { replace: false });
            }
            setAnchorEll(null);
            break;
        case 4:
        case 6:
            navigate(`/booking?query_code=${query_code}`, { replace: true });
            setAnchorEll(null);
            break;
        default:
            navigate('/', { replace: true });
    }
    // Refresh the page
    window.location.reload();
};

  useEffect(() => {
    getnotifications('/profilemenu useeffect')
  }, [])

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {isSmallScreen ? (
        <Grid container flex alignItems={'center'} justifyContent={'flex-end'}>
          <div style={{ display: 'flex', placeContent: 'end', gap: '5px' }}>
            <div>
              <IconButton
                aria-label='more'
                id='long-button2'
                aria-controls={openn ? 'long-menu' : undefined}
                aria-expanded={openn ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleNotify}
                sx={{ marginTop: '5px' }}
              >
                <Badge
                  id='notification'
                  badgeContent={
                     notificationlist.filter((item) => item.is_read === 0).length
                  }
                  color='primary'
                  max={9}
                >
                  <NotificationsIcon
                    sx={{ color: 'white' }}
                    style={{ marginBottom: '6px' }}
                  />
                </Badge>
              </IconButton>
              <Menu
                sx={{ height: '75%' }}
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEll}
                open={openn}
                onClose={handleClosenotify}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ListSubheader
                  component='h3'
                  sx={{
                    fontWeight: 600,
                    fontSize: 15,
                    color: '#172b4d',
                    borderBottom: '1px solid rgba(0,0,0,0.4)',
                    width: '100%',
                    backgroundColor: 'aliceblue',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                  className='nested-list-subheade'
                >
                  Notifications <CircleNotifications />
                </ListSubheader>
                {notificationlist.length < 1 ? (
                  <List
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '360px',
                      // height: "20px !mportant",
                      flexDirection: 'column',
                      fontSize: '26px',
                      color: 'rgba(0,0,0,.4)',
                    }}
                  >
                    <p>
                      <img
                        src='../images/wolf.svg'
                        alt='Avatar'
                        width={130}
                        height={120}
                        style={{ borderRadius: '50%' }}
                      />
                    </p>
                    <p style={{ color: '#172B4D' }}>No notification</p>
                  </List>
                ) : (
                  notificationlist.map((item, index) => (
                    <List
                      key={index}
                      sx={{
                        width: '360px',
                        marginTop: '0px',

                        padding: 0,
                      }}
                    >
                      <ListItem sx={{ padding: '0px 6px' }} onClick={()=>handleReadNoti(item.notify_id)}>
                        <ListItemAvatar
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Avatar
                            sx={{
                              bgcolor: item.is_read === 0 ? 'green' : '',
                              width: 32,
                              height: 32,
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Email fontSize='small' />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item?.message}
                          primaryTypographyProps={{
                            color: '#6c6c6c',
                            fontWeight: 'medium',
                            fontSize: '12px',
                          }}
                          secondary={
                            <>
                              <Typography fontSize={12} color={'#879112'}>
                                {item.type == 1
                                  ? 'Query Confirmation'
                                  : 'Quote Created'}{' '}
                                — {item.notify_date}
                              </Typography>
                            </>
                          }
                          secondaryTypographyProps={{
                            fontWeight: 'medium',
                            color: 'black',
                          }}
                        />
                      </ListItem>
                    </List>
                  ))
                )}
              </Menu>
            </div>

            {/* profile menu */}
            <Grid item id='profile'>
              <img
                style={{
                  width: '36px',
                  height: '36px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  marginRight: '10px',
                  marginTop: '8px',
                  objectFit :"cover"
                }}
                src={auth?.details?.profile_link ?? '/images/dp.png'}
                alt='dp'
                onClick={handleClick}
              />
              {/* <Button
           sx={{ float: 'right', color: 'white', width:"50px" }}
           id='demo-customized-button'
           aria-controls={open ? 'demo-customized-menu' : undefined}
           aria-haspopup='true'
           aria-expanded={open ? 'true' : undefined}
           disableElevation
           onClick={handleClick}
    
         >
           
         </Button> */}
              <StyledMenu
                id='demo-customized-menu'
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleOpenDpModal} disableRipple>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <img
                      style={{
                        width: '36px',
                        height: '36px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        objectFit :"cover"
                      }}
                      src={auth?.details?.profile_link ?? '/images/dp.png'}
                      alt='dp'
                      onClick={handleOpenDpModal}
                    />
                    <p>Upload Pic</p>
                  </div>
                </MenuItem>
                <MenuItem onClick={handledashBoardClose} disableRipple>
                    <p style={{ display: 'flex', alignItems: 'center',color : 'gray' }}>
                      <span>
                        <Dashboard style={{ marginTop: '7px' }} />
                      </span>
                      <span>Dashboard</span>
                    </p>
                </MenuItem>
                {/* <MenuItem onClick={handleClose} disableRipple>
                    <p style={{ display: 'flex', alignItems: 'center',color : 'gray' }}>
                      <span>
                        <AccountCircle style={{ marginTop: '7px' }} />
                      </span>
                      <span>My Profile</span>
                    </p>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <p style={{ display: 'flex', alignItems: 'center',color : 'gray' }}>
                      <span>
                        <Settings style={{ marginTop: '7px' }} />
                      </span>
                      <span>Settings</span>
                    </p>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <p style={{ display: 'flex', alignItems: 'center',color : 'gray' }}>
                      <span>
                        <SupportAgent style={{ marginTop: '7px' }} />
                      </span>
                      <span>Support</span>
                    </p>
                </MenuItem> */}
                {/* <Divider /> */}
                <MenuItem
                  onClick={handelLogout}
                  disableRipple
                  sx={{
                    borderTop: '1px solid #32453245 !important',
                    color: 'tomato !important',
                  }}
                >
                  <span
                    style={{ paddingTop: '8px', color: 'tomato !important' }}
                  >
                    <Logout color='tomato !important' />
                  </span>
                  <span>Logout</span>
                </MenuItem>
              </StyledMenu>
            </Grid>
            <TableModal
              handleShowModal={showDpModal}
              handleCloseModal={handleCloseDp}
            >
              <form method='put' encType='multipart/form-data'>
                <div className={style.dpContainer}>
                  <label htmlFor='fileToUpload'>

                    <div
                      className={style.profilePic}
                      style={{
                        backgroundImage: `url(${isLoading ? '/images/loading1.gif' :  auth?.details?.profile_link ?? '/images/dp.png'})`
                      }}
                    >
                      <span
                        className={`${style.glyphicon} ${style.glyphiconCamera}`}
                      ></span>
                      <span>Change Image</span>
                    </div>
                  </label>
                  <input
                    type='File'
                    name='fileToUpload'
                    id='fileToUpload'
                    accept='image/*'
                    onChange={(e) => handleDpChange(e)}
                  />
                </div>
              </form>
            </TableModal>
          </div>
        </Grid>
      ) : (
        <Grid
          container
          flex
          md={3}
          lg={3}
          alignItems={'center'}
          justifyContent={'flex-end'}
          gap={2}
        > 
       
          <div>
            <IconButton
              aria-label='more'
              id='long-button2'
              aria-controls={openn ? 'long-menu' : undefined}
              aria-expanded={openn ? 'true' : undefined}
              aria-haspopup='true'
              onClick={handleNotify}
            >
              <Badge
                id='notification'
                badgeContent={
                 notificationlist.filter((item) => item.is_read === 0).length
                }
                color='primary'
                max={9}
              >
                <NotificationsIcon
                  sx={{ color: 'white' }}
                  style={{ marginBottom: '6px' }}
                />
              </Badge>
            </IconButton>
            <Menu
              className='menu-notification'
              sx={{ height: '75%',marginLeft : "22px",padding :"0px !important" }}
              id='long-menu'
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEll}
              open={openn}
              onClose={handleClosenotify}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <ListSubheader
                component='h3'
                sx={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: '#172b4d',
                  borderBottom: '1px solid rgba(0,0,0,0.4)',
                  width: '100%',
                  backgroundColor: 'aliceblue',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '10px',
                }}
                className='nested-list-subheade'
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  Notifications <CircleNotifications />
                </div>
                <Button
                  size='small'
                  sx={{ color :"white",backgroundColor : "#3d5b7a", '&:hover' : {
                    bgcolor : "#05203c"
                  } }}
                  startIcon={<Delete />}
                  onClick={handleClear}
                >
                  Clear
                </Button>
              </ListSubheader>
              {notificationlist.length < 1 ? (
                <List
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '360px',
                    // height: "20px !mportant",
                    flexDirection: 'column',
                    fontSize: '26px',
                    color: 'rgba(0,0,0,.4)',
                  }}
                >
                  <p>
                    <img
                      src='../images/wolf.svg'
                      alt='Avatar'
                      width={110}
                      height={100}
                      style={{ borderRadius: '50%' }}
                    />
                  </p>
                  <p style={{ color: '#172B4D' }}>No notification</p>
                </List>
              ) : (
                <List
                  sx={{
                    width: '360px',
                    marginTop: '0px',
                    padding: 0,
                  }}
                >
                  { notificationlist.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{ padding: '0px 6px' }}
                      onClick={() =>
                        handleNotificationnav(item.type, item.query_code,item.notify_id)
                      }
                    >
                      <ListItemAvatar
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: item.is_read === 0 ? 'green' : '',
                            width: 32,
                            height: 32,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Email fontSize='small' />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item?.message}
                        primaryTypographyProps={{
                          color: '#6c6c6c',
                          fontWeight: 'medium',
                          fontSize: '12px',
                        }}
                        secondary={
                          <>
                            <Typography fontSize={12} color={'#879112'}>
                              {item.type == 1
                                ? 'Query Confirmation'
                                : 'Quote Created'}{' '}
                              — {item.notify_date}
                            </Typography>
                          </>
                        }
                        secondaryTypographyProps={{
                          fontWeight: 'medium',
                          color: 'black',
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Menu>
          </div>

          {/* profile menu */}
          <Grid item id='profile'>
            <img
              style={{
                width: '36px',
                height: '36px',
                borderRadius: '50%',
                cursor: 'pointer',
                objectFit :"cover"
              }}
              src={auth?.details?.profile_link ?? '/images/dp.png'}
              alt='dp'
              onClick={handleOpenDpModal}
            />
            <Button
              sx={{ float: 'right', color: 'white' }}
              id='demo-customized-button'
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Hi, {auth?.details?.name.split(" ")[0] ?? NAMES[auth?.role] }
            </Button>
            <StyledMenu
              id='demo-customized-menu'
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleOpenDpModal} disableRipple>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <img
                    style={{
                      width: '36px',
                      height: '36px',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      objectFit :"cover"
                    }}
                    src={auth?.details?.profile_link ?? '/images/dp.png'}
                    alt='dp'
                    onClick={handleOpenDpModal}
                  />
                  <p>Upload Pic</p>
                </div>
              </MenuItem>
              <MenuItem onClick={handledashBoardClose} disableRipple>
                    <p style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                      <span>
                        <Dashboard style={{ marginTop: '7px', marginLeft:"10px" }} />
                      </span>
                      <span>Dashboard</span>
                    </p>
                </MenuItem>
              {/* <MenuItem onClick={handleClose} disableRipple style={{cursor : "not-allowed"}}>
                  <p style={{ display: 'flex', alignItems: 'center',color :"GrayText",cursor : "not-allowed", opacity : 0.7 }}>
                    <span>
                      <AccountCircle style={{ marginTop: '7px',color : 'gray' }} />
                    </span>
                    <span>My Profile</span>
                  </p>
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple style={{cursor : "not-allowed"}}>
                  <p style={{ display: 'flex', alignItems: 'center',color :"gray",cursor : "not-allowed", opacity : 0.7}}>
                    <span>
                      <Settings style={{ marginTop: '7px',color : 'gray' }} />
                    </span>
                    <span>Settings</span>
                  </p>
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple style={{cursor : "not-allowed"}}>
                  <p style={{ display: 'flex', alignItems: 'center',color :"gray",cursor : "not-allowed", opacity : 0.7 }}>
                    <span>
                      <SupportAgent style={{ marginTop: '7px',color : 'gray' }} />
                    </span>
                    <span>Support</span>
                  </p>
              </MenuItem> */}
              {/* <Divider /> */}
              <MenuItem
                onClick={handelLogout}
                disableRipple
                sx={{
                  borderTop: '1px solid #32453245 !important',
                  color: 'tomato !important',
                }}
              >
                <span style={{ paddingTop: '8px', color: 'tomato !important' }}>
                  <Logout color='tomato !important' />
                </span>
                <span>Logout</span>
              </MenuItem>
            </StyledMenu>
          </Grid>
          <TableModal
            handleShowModal={showDpModal}
            handleCloseModal={handleCloseDp}
          >
            <form method='put' encType='multipart/form-data'>
              <div className={style.dpContainer}>
                <label htmlFor='fileToUpload'>
                  <div
                    className={style.profilePic}
                    style={{
                      backgroundImage: `url(${isLoading ? '/images/loading1.gif' :  auth?.details?.profile_link ?? '/images/dp.png'})`
                    }}
                  >
                    <span
                      className={`${style.glyphicon} ${style.glyphiconCamera}`}
                    ></span>
                    <span>Change Image</span>
                  </div>
                </label>
                <input
                  type='File'
                  name='fileToUpload'
                  id='fileToUpload'
                  accept='image/*'
                  onChange={(e) => handleDpChange(e)}
                />
              </div>
            </form>
          </TableModal>
        </Grid>
      )}
    </>
  )
})

export default ProfileMenu
