import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import { Payment, Send } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { Modal } from '@mui/material'
import { SnackbarContext } from '../../../context/Snackbar'
import {
  getAllOrganizationListApi,
  getTicketlist,
} from '../../../services/configService'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

export default function Organizations({
  refreshorglist,
  setRefreshorglist,
  setSelectedOrgDltId,
}) {
  const navigate = useNavigate()
  const [data, setData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)

  const [isChecked, setIsChecked] = React.useState([])
  const [error, setError] = React.useState('')
  const [editable, SetEditable] = React.useState(false)
  const [mappedRowData, setMappedRowdata] = React.useState([])
  const [paymentdetails, setPaymentdetails] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  // const handleOpen = () => setOpen(true);

  async function getData() {
    try {
      const res = await getAllOrganizationListApi()
      if (res.status !== 204) {
        setData(res.data)
        console.log('res.data', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCheckboxChange = (event, row) => {
    console.log('event.target.checked', event.target.checked)

    setIsChecked((prev) => {
      let newChecked = [...prev]

      if (event.target.checked) {
        const newObject = {
          ...row,
        }
        newChecked.push(newObject)
      } else {
        newChecked = newChecked.filter((item) => item.id !== row.id)
      }

      return newChecked
    })
    SetEditable(true)
  }
  console.log(
    'isChecked',
    isChecked.map((item) => item.id)
  )

  const handleLinkClick = (data) => {
    console.log('hello', data)
    navigate('/org-particular-users', { state: data })
  }

  React.useEffect(() => {
    getData()
  }, [])
  React.useEffect(() => {
    if (refreshorglist === true) {
      getData()
      setRefreshorglist(false)
      setSelectedOrgDltId([])
    }
  }, [refreshorglist])

  React.useEffect(() => {
    const orgId = isChecked.map((item) => item.id)
    setSelectedOrgDltId(orgId)
  }, [isChecked])

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 55,
      cellClassName: 'actions',
      renderCell: (params) => {
        return (
          <Checkbox
            // checked={isChecked[params.row.p_id]}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        )
      },
    },

    {
      field: 'name',
      headerName: 'Organization Name',
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        const maxWords = 4

        // Split the amount_type into an array of words
        const words = params.row?.name?.split(' ')

        // If the word count exceeds the limit, truncate the text and add ellipsis
        const truncatedText =
          words?.length > maxWords
            ? words?.slice(0, maxWords).join(' ') + '...'
            : params.row?.name
        return (
          <Button
            onClick={() => handleLinkClick(params.row)}
            style={{ textDecoration: 'underline', color: '#184E81' }} // Add this line
          >
            <strong style={{ color: '#184E81' }}>{truncatedText}</strong>
          </Button>
        )
      },
    },
    {
      field: 'user_count',
      headerName: 'User',
      flex: 1,
      minWidth: 160,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row.user_count}</p>
      },
    },
    {
      field: 'created',
      headerName: 'Created',
      flex: 1,
      minWidth: 190,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row.created
              ? dayjs(params.row.created).format('ddd, DD-MM-YYYY')
              : 'N.A'}
          </p>
        )
      },
    },
    {
      field: 'updated',
      headerName: 'Last Update',
      flex: 1,
      minWidth: 190,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row.updated
              ? dayjs(params.row.updated).format('ddd, DD-MM-YYYY')
              : 'N.A'}
          </p>
        )
      },
    },
  ]

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Modal
        open={loader}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='loader-container'>
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
          <p
            style={{
              marginLeft: '10px',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            Please wait!!
          </p>
        </div>
      </Modal>

      <StripedDataGrid
        rows={data?.map((item, index) => ({
          ...item,
          // sno: index + 1,
        }))}
        getRowId={(row) => row.id}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowClassName={(params) =>
          params.row.is_mapped == 1
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableRowSelectionOnClick
      />
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Makepaymentsuccess paymentdetails={paymentdetails} />
        </Box>
      </Modal> */}
    </Box>
  )
}
