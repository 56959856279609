import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent:  "center",
        alignItems: "center",
        minHeight: "100vh",
        background: 'url("https://wallpapers.com/images/featured/airplane-k2bvoms91kvb0tfp.jpg")' ,
        backgroundRepeat: "unset",
        backgroundSize: "cover",
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6" marginBottom={2}>
              The page you’re looking for doesn’t exist.
            </Typography>
            <Button variant="contained">
              <Link to="/">Back Home</Link>
            </Button>
          </Grid>
          <Grid xs={6}>
            <img
              src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
              className="bgRemove"
              alt=""
              width={500}
              height={250}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
