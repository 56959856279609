import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'

import Card from '@mui/material/Card'

import Makepaymentlisting from './Makepaymentlisting'

const Makepaymentsmain = () => {
    
  
  return (
    <Card sx={{ height: '100%' }} elevation={0}>
      <DashboardLayout
        heading='Make Payment'
        pathName='Make Payment'
       
      >
        <Makepaymentlisting/>
      </DashboardLayout>
    </Card>
  )

}
export default Makepaymentsmain
