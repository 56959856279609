import React, { useContext } from 'react'
import {
  Divider,
  IconButton,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  Modal,
  Autocomplete,
  Checkbox,
  Paper,
} from '@mui/material'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import MyStatefulEditor from '../../../components/Text Editor/Texteditor'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {
  AccountBox,
  AccountCircle,
  Add,
  Create,
  DeleteSweepTwoTone,
  DownloadTwoTone,
  Group,
  GroupAdd,
  Info,
  Padding,
  Settings,
  VerifiedUser,
} from '@mui/icons-material'
import Box from '@mui/material/Box'
import MuiPhoneNumber from 'mui-phone-number'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'

import dayjs from 'dayjs'
import { downloadAttachment } from '../../../utils/common'
import { useParams } from 'react-router-dom'
import {
  deleteCollaborator,
  getAllCollaboratorListApi,
  getCollaboratorListApi,
  imapCreateCollaboratorApi,
  imapInternalNote,
  imapPostReplyApi,
  updateCollaborator,
  updateTicketStatus,
  userImapThread,
} from '../../../services/configService'
import { useLocation } from 'react-router-dom'
import { SnackbarContext } from '../../../context/Snackbar'
import CustomTextArea from '../../../components/common/CustomTextArea'
import MultiSelect from '../../../components/quoteMailService/MultiSelect'
import './loaderstyle.css'
import { set } from 'date-fns'
import Reactquilleditor from '../../../components/Text Editor/Reactquilleditor'
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const loaderstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #2A67AC',
  boxShadow: 24,
  p: 2,
}
const ImapThreads = () => {
  const { id } = useParams()
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)

  const { state } = useLocation()
  const [outerValue, setOuterValue] = React.useState(0)
  const [innerValue, setInnerValue] = React.useState(0) // Separate state for inner Tabs
  const [data, setData] = React.useState([])
  const [collaboratorId, setCollaboratorId] = React.useState('')
  const [ticketstatus, setTicketstatus] = React.useState(state?.closed_status)
  const [upcollaboratorId, setUpcollaboratorId] = React.useState('')
  const [dltcollaboratorId, setDltcollaboratorId] = React.useState('')
  const [collaboratorData, setCollaboratorData] = React.useState([])
  const [collaboratorDataCopy, setCollaboratorDataCopy] = React.useState([])
  const [allCollaboratorData, setAllCollaboratorData] = React.useState([])
  const [collaboratorEmail, setCollaboratorEmail] = React.useState([])
  const [cancelEmail, setCancelEmail] = React.useState([])
  const [ccEmailId, setCCEmailId] = React.useState([])
  const [matchingemails, setMatchingemails] = React.useState([])
  const [cancelccemails, setCancelccemails] = React.useState([])
  const [deactiveCollaborator, setDeactiveCollaborator] = React.useState([])
  const [error, setError] = React.useState({})
  const [newCollaboratorsdata, setNewCollaboratorsdata] = React.useState({
    name: '',
    email: '',
    phone: '',
    internal_note: '',
    reply_to: '',
  })
  const [loader, setLoader] = React.useState(false)

  const [internoteTitle, setInternoteTitle] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [openManageCollaborator, setOpenManageCollaborator] =
    React.useState(false)

  const [editorHtml, setEditorHtml] = React.useState('')

  const handleChangeCollaborator = (e, v) => {
    setCollaboratorId(v?.id)
  }

  const handleChangePhone = (e, v, v1, v2) => {
    let x = e
    let num = x.replace(/\D/g, '')
    // let dialCode = num.slice(0, 2)
    setNewCollaboratorsdata((pre) => ({
      ...pre,
      phone: num,
    }))
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setNewCollaboratorsdata((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleClose = () => {
    setOpen(false)
    setCollaboratorId('')
  }
  const handleCloseManageCollaborator = () => {
    setOpenManageCollaborator(false)
  }
  const handleCollaboratorStatus = (id) => {
    setUpcollaboratorId(id)
    setCollaboratorData((prevCollaborators) =>
      prevCollaborators.map((collaborator) =>
        collaborator.id === id
          ? {
              ...collaborator,
              status: collaborator.status === '0' ? '1' : '0',
            }
          : collaborator
      )
    )
  }
  console.log('collaboratorData', state)
  async function handleupdateCollaborator(ccemail) {
    const data = {
      thread_id: state?.thread_id,
      // cc_id:
      //   collaboratorData.map(({ collaborator_id }) => collaborator_id) || id,
      // status: collaboratorData.map(({ status }) => status),
      data: collaboratorData || ccemail,
    }
    try {
      const res = await updateCollaborator(data)
      if (res.status !== 204 && res.status !== 406) {
        getCollaboratorData()
        // setUpcollaboratorId('')
        setSeverity('success')
        setMes('successfully changed')
        setOn(true)
        setOpenManageCollaborator(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function handleCollaboratorDelete(id) {
    // const data = {
    //   thread_id: state?.thread_id,
    //   cc_id: id,
    // }
    try {
      const res = await deleteCollaborator(id)
      if (res.status !== 204 && res.status !== 406) {
        getCollaboratorData()

        setSeverity('error')
        setMes('Collaborator Deleted Successfully')
        setOn(true)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // const handleCollaboratorDelete = (id) => {
  //   console.log('id', id)
  //   setDltcollaboratorId(id)
  //   handleupdateCollaborator(id)
  // }
  const handleControl = () => setCollaboratorId('')

  async function getData() {
    try {
      const res = await userImapThread(state.ticket_no || state.thread_id)
      if (res.status !== 204) {
        setData(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function getCollaboratorData() {
    try {
      const res = await getCollaboratorListApi(
        state.ticket_no || state.thread_id
      )
      if (res.status !== 204 && res.status !== 406) {
        setCollaboratorData(res.data)
        setCollaboratorDataCopy(
          // res.data?.filter((item) => item.status === 'active')
          res.data
        )
        // const emailIds = res.data
        //   ?.filter((item) => item.status === 'active')
        //   ?.map((item) => item.default_email_id)
        // setCollaboratorEmail(emailIds)
        // console.log('exammmmmmm', emailIds)
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function getAllCollaboratorData() {
    try {
      const res = await getAllCollaboratorListApi()
      if (res.status !== 204 && res.status !== 406) {
        setAllCollaboratorData(res.data?.filter((item) => item.status === '0'))
        // const emailIds = res.data
        //   ?.filter((item) => item.status === 'active')
        //   ?.map((item) => item.default_email_id)
        // setCollaboratorEmail(emailIds)
        // console.log('exammmmmmm', emailIds)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOuterChange = (event, newValue) => {
    setOuterValue(newValue)
  }

  const handleInnerChange = (event, newValue) => {
    setInnerValue(newValue)
  }

  const handleTextChange = (html) => {
    setEditorHtml(html)
  }

  const adminmessage = data
    ?.map((item) => {
      if (item.type === 'R') {
        return {
          message: item.mail_body,
          created_at: item.last_updated,
          poster: item.poster,
          type: item.type,
          title: item.title,
        }
      }
      return null // or filter out falsy values later
    })
    .filter((item) => item !== null)

  const usermessage = data
    ?.map((item) => {
      if (item.type === 'M') {
        return {
          message: item.mail_body,
          created_at: item.last_updated,
          poster: item.poster,
          type: item.type,
          title: item.title,
        }
      }
      return null // or filter out falsy values later
    })
    .filter((item) => item !== null)
  const internoteThread = data
    ?.map((item) => {
      if (item.type === 'N') {
        return {
          message: item.mail_body,
          created_at: item.last_updated,
          poster: item.poster,
          type: item.type,
          title: item.title,
        }
      }
      return null // or filter out falsy values later
    })
    .filter((item) => item !== null)

  const messages = [
    ...adminmessage.map((msg) => ({ ...msg, isAdmin: true })),
    ...usermessage.map((msg) => ({ ...msg, isAdmin: false })),
    ...internoteThread.map((msg) => ({ ...msg, isAdmin: true })),
  ]

  messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

  const generatePostReply = async () => {
    if (newCollaboratorsdata.reply_to === '') {
      setSeverity('error')
      setMes('Please select Reply to')
      setOn(true)
      return
    } else if (editorHtml === '') {
      setSeverity('error')
      setMes('Please write something...')
      setOn(true)
      return
    }
    setLoader(true)
    const ccEmails = newCollaboratorsdata.reply_to === 1 ? ccEmailId : []
    const recipients =
      newCollaboratorsdata.reply_to === 1 || newCollaboratorsdata.reply_to === 2
        ? state.email_id
        : []
    const status = newCollaboratorsdata.reply_to === 3 ? '' : 1

    const data = {
      thread_id: state?.thread_id,
      mail_body: editorHtml,
      cc_user: ccEmails,
      Recipients: recipients,
      status: status,
    }

    try {
      const res = await imapPostReplyApi(data, setError)

      if (res !== 204 && res !== 406) {
        // Handle success response
        setNewCollaboratorsdata({ reply_to: '' })
        setEditorHtml('')
        getData()

        setSeverity('success')
        setMes('Successfully sent the reply')
        setOn(true)
        setLoader(false)
      }
    } catch (error) {
      console.error('Error in generate quote', error)
      setSeverity('warning')
      setMes('Failed to send the reply.')
      setOn(true)
      setLoader(false)
    } finally {
      setLoader(false)
      // Ensure loader is stopped in all cases
    }
  }
  const changeTicketStatus = async (e) => {
    setTicketstatus(e.target.value)
    const data = {
      ticket_no: state.ticket_no,
      status: e.target.value,
    }

    try {
      const res = await updateTicketStatus(data)

      if (res !== 204 && res !== 406) {
        // const mailRes = await quoteMailService(mailData, setError)
        // console.log('mailRes', mailRes)

        setSeverity('success')
        setMes('successfully')
        setOn(true)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('')
      setOn(true)
      // console.log('error in generate quote ', error)
    } finally {
    }
  }
  const generateInternalNote = async () => {
    if (editorHtml === '') {
      setSeverity('error')
      setMes('Please write something...')
      setOn(true)
      return // Ensure early return to stop further execution when content is empty
    }

    setLoader(true)
    const data = new FormData()

    data.append('title', internoteTitle)
    data.append('type', 'N')
    data.append('thread_id', state?.thread_id)
    data.append('content', editorHtml)

    try {
      const res = await imapInternalNote(data)

      if (res !== 204 && res !== 406) {
        setInternoteTitle('')
        setEditorHtml('')
        getData()

        setSeverity('success')
        setMes('Successfully sent the reply')
        setOn(true)
      }
    } catch (error) {
      console.error('Error in generate quote', error)
      setSeverity('warning')
      setMes('Failed to send the reply.')
      setOn(true)
    } finally {
      setLoader(false) // Stop the loader in all cases
    }
  }

  const createNewCollaborator = async (change) => {
    const data = {
      thread_id: state?.thread_id,
      email: newCollaboratorsdata.email || change.default_email_id || '',
      name: newCollaboratorsdata.name || change.name || '',
      phone: newCollaboratorsdata.phone || change.phone || '', // not required
      internal_note:
        newCollaboratorsdata.internal_note || change.internal_note || '', // not required
    }

    try {
      const res = await imapCreateCollaboratorApi(data, setError)

      if (res !== 204 && res !== 406) {
        // const mailRes = await quoteMailService(mailData, setError)

        setNewCollaboratorsdata({
          name: '',
          email: '',
          phone: '',
          internal_note: '',
        })
        setOpen(false)
        setOpenManageCollaborator(true)
        getCollaboratorData()
        setSeverity('success')
        setMes('Collaborator added successfully')
        setOn(true)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('')
      setOn(true)
      // console.log('error in generate quote ', error)
    } finally {
    }
  }
  const handleNewCollaboratorModal = () => {
    setOpen(true)
    // Add your logic for adding new collaborators here
  }

  const handleManageCollaborator = () => {
    setOpenManageCollaborator(true)
    // Add your logic for managing collaborators here
  }

  const handleContinue = () => {
    const change = allCollaboratorData.find(
      (item) => item.id === collaboratorId
    )

    if (data) {
      createNewCollaborator(change)
    }
    // setOpenManageCollaborator(true)
    // setOpen(false)
    setCollaboratorId('')
  }
  const actions = [
    {
      icon: <GroupAdd />,
      name: 'Add New Collaborators',
      onClick: handleNewCollaboratorModal,
    },
    {
      icon: <Settings />,
      name: 'Manage Collaborators',
      onClick: handleManageCollaborator,
    },
  ]

  const selectedCollaborator = allCollaboratorData?.find(
    (item) => item.id === collaboratorId
  )

  React.useEffect(() => {
    getData()
    getCollaboratorData()
    getAllCollaboratorData()
  }, [])

  React.useEffect(() => {
    const emailIds = collaboratorDataCopy
      ?.filter((item) => item.status === '1')
      .map((item) => item.default_email_id)
    setCollaboratorEmail(emailIds)
  }, [collaboratorDataCopy])

  React.useEffect(() => {
    const userccCollaborator = collaboratorData
      ?.filter((item) => item.status === '0')
      ?.map((item) => item.default_email_id)
    const deactiveCollaborator = collaboratorData?.filter(
      (item) => item.status === '1'
    )

    setDeactiveCollaborator(deactiveCollaborator)

    if (userccCollaborator) {
      setCCEmailId(userccCollaborator)
    } else {
      setCCEmailId([]) // Or handle this case accordingly
    }
  }, [collaboratorData])

  React.useEffect(() => {
    const matchingEmails = deactiveCollaborator.filter((item) =>
      ccEmailId.includes(item.default_email_id)
    )

    // Update the status of matching emails to 0
    matchingEmails.forEach((item) => {
      item.status = 0
    })
    setMatchingemails(matchingEmails) // Update the state with the updated matchingEmails

    console.log(matchingEmails)
    // handleupdateCollaborator(matchingEmails)
  }, [ccEmailId, deactiveCollaborator])
  React.useEffect(() => {
    const cancelemails = collaboratorData.filter((item) =>
      cancelEmail.includes(item.default_email_id)
    )

    // Update the status of matching emails to 0
    cancelemails.forEach((item) => {
      item.status = 1
    })
    setCancelccemails(cancelemails) // Update the state with the updated matchingEmails

    console.log(cancelemails)
  }, [cancelEmail])

  React.useEffect(() => {
    if (matchingemails.length > 0) {
      handleupdateCollaborator(matchingemails)
    }
  }, [matchingemails])
  React.useEffect(() => {
    if (cancelccemails.length > 0) {
      handleupdateCollaborator(cancelccemails)
    }
  }, [cancelccemails])

  React.useEffect(() => {
    if (error.duplicate_entry) {
      setSeverity('warning')
      setMes(
        error.duplicate_entry
          ? error.duplicate_entry
          : // : error.pan_id
            // ? error.pan_id
            // : error.gst_id
            // ? error.gst_id
            'Error'
      )
      setOn(true)
    }
  }, [error])

  return (
    <DashboardLayout
      heading='Mail Threads Listing'
      pathName='Mail Threads Listing'
    >
      <Card>
        <Modal
          open={loader}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={loaderstyle}>
            <div class='loader'></div>
          </Box>
        </Modal>
        <CardContent>
          <Typography variant='h6'>
            Email for Testing IMAP Functionality
          </Typography>
          <CardContent
            sx={{
              background: '#e9f4ff',
              borderTop: '1px solid lightgrey',
              marginTop: '8px',
              padding: '6px',
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Status:
                  </Typography>
                  <Typography style={{ fontWeight: '500', color: '#002244' }}>
                    {state?.closed_status === 1 ? 'Closed' : 'Open'}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Priority:
                  </Typography>
                  <Typography style={{ fontWeight: '500', color: '#002244' }}>
                    {state?.priority}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Department:
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Create Date:
                  </Typography>
                  <Typography style={{ fontWeight: '500', color: '#002244' }}>
                    {dayjs(state?.created).format('DD-MM-YYYY')}
                  </Typography>
                </div>
              </Grid>
              <Grid item md={6}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>User:</Typography>
                  <Typography style={{ fontWeight: '500', color: '#002244' }}>
                    {state?.poster}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>Email:</Typography>
                  <Typography style={{ fontWeight: '500', color: '#002244' }}>
                    {state?.email_id}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Source:
                  </Typography>
                  <Typography style={{ fontWeight: '500', color: '#002244' }}>
                    {state?.source}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent
            sx={{
              background: '#e9f4ff',
              marginTop: '8px',
              padding: '6px',
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Closed By:
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    SLA Plan:
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Close Date:
                  </Typography>
                </div>
              </Grid>
              <Grid item md={6}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Help Topic:
                  </Typography>
                  <Typography style={{ fontWeight: '500', color: '#002244' }}>
                    {state?.title}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Last Message:
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Last Response:
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </CardContent>

        <CardContent>
          <Box sx={{ width: '100%', padding: '0px' }}>
            <Box
              sx={{ borderBottom: 1, borderColor: 'divider', padding: '0px' }}
            >
              <Tabs
                value={outerValue}
                onChange={handleOuterChange}
                aria-label='basic tabs example'
              >
                <Tab label='Ticket Theads' {...a11yProps(0)} />
                {/* <Tab label='Task' {...a11yProps(1)} /> */}
                {/* <Tab label='Item Three' {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <CustomTabPanel
              value={outerValue}
              index={0}
              sx={{ Padding: '0px' }}
            >
              <CardContent sx={{ padding: '1px', borderRadius: '0px' }}>
                {messages.length == 0 ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                    variant='h5'
                    color={'GrayText'}
                  >
                    No Chat Found
                  </Typography>
                ) : (
                  messages.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: item.isAdmin ? 'flex-start' : 'flex-end',
                      }}
                    >
                      {/* <Typography
                          variant='h6'
                          sx={{
                            marginLeft: '7px',
                            color: item.isAdmin ? '#002244' : '#002244',
                            fontWeight: 500,
                            fontSize: '15px',
                            marginBottom: '10px',
                            marginLeft: item.isAdmin ? '0' : '48px',
                          }}
                        >
                          {item.isAdmin ? 'Admin' : 'User'}
                        </Typography> */}

                      <div
                        style={{ display: 'flex', gap: '10px', width: '100%' }}
                      >
                        {item.isAdmin && <AccountCircle fontSize='large' />}
                        <Card elevation={5} sx={{ width: '100%' }}>
                          <CardContent
                            sx={{
                              background:
                                item.type === 'M'
                                  ? '#C3D9FF'
                                  : item.type === 'N'
                                  ? '#ffffc1'
                                  : '#FFE0B3',
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Typography
                                // sx={{
                                //   background: item.isAdmin ? '#F0F8FF' : '#D0F0C0',
                                //   border: item.isAdmin
                                //     ? '1px solid #008E97'
                                //     : '1px solid #00853E',
                                //   borderRadius: '15px',
                                //   padding: '4px 10px',
                                //   cursor: 'pointer',
                                //   fontSize: '14px',
                                //   marginRight: item.isAdmin ? '40px' : '0',
                                //   marginLeft: item.isAdmin ? '0' : '40px',
                                //   color: 'black',
                                //   fontWeight: 500,
                                //   width: 'fit-content',
                                // }}
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '15px',
                                  color: item.isAdmin ? '#002244' : '#002244',
                                }}
                              >
                                {item.isAdmin
                                  ? 'CargoDash Support '
                                  : item.poster}
                              </Typography>
                              <Typography
                                variant='caption'
                                display='block'
                                sx={{
                                  marginLeft: '10px',
                                  fontWeight: 550,
                                  fontSize: '15px',

                                  // marginLeft: item.isAdmin ? '0' : '0px',
                                }}
                              >
                                posted{' '}
                                {dayjs(item.created_at).format(
                                  'DD/MM/YY hh:mm:ss a'
                                )}
                              </Typography>
                              <Typography
                                variant='caption'
                                display='block'
                                sx={{
                                  marginLeft: '10px',
                                  // fontWeight: 550,
                                  fontSize: '15px',

                                  // marginLeft: item.isAdmin ? '0' : '0px',
                                }}
                              >
                                (
                                {item.title !== 'null'
                                  ? item.title
                                  : 'No Title'}
                                )
                              </Typography>
                            </div>
                          </CardContent>
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: 500 }}
                              // color='text.secondary'
                              gutterBottom
                            >
                              {/* <pre>{item.message}</pre> */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.message.replace(/\n/g, '<br/>'),
                                }}
                              />
                            </Typography>
                          </CardContent>
                        </Card>
                        {!item.isAdmin && <AccountCircle fontSize='large' />}
                      </div>

                      <div style={{ display: 'flex', gap: 4 }}>
                        {item.file && (
                          <IconButton
                            aria-label='delete'
                            size='small'
                            color='info'
                            onClick={() => {
                              downloadAttachment(item.file, 'Attachment File')
                            }}
                          >
                            <DownloadTwoTone />
                          </IconButton>
                        )}
                      </div>
                      <Divider sx={{ marginBottom: '15px' }} />
                    </div>
                  ))
                )}
              </CardContent>

              <CardContent sx={{ padding: '0px' }}>
                <Box sx={{ width: '100%', padding: '0px' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      padding: '0px',
                    }}
                  >
                    <Tabs
                      value={innerValue}
                      onChange={handleInnerChange}
                      aria-label='basic tabs example'
                    >
                      <Tab label='Post Reply' {...a11yProps(0)} />
                      <Tab label='Post Internal Note' {...a11yProps(1)} />
                      {/* <Tab label='Item Three' {...a11yProps(2)} /> */}
                    </Tabs>
                  </Box>
                  <CustomTabPanel
                    value={innerValue}
                    index={0}
                    sx={{ Padding: '0px' }}
                  >
                    <Grid container spacing={2} style={{ margin: '16px 0' }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={2}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography style={{ fontWeight: 'bold' }}>
                          From:
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={8} lg={9}>
                        <FormControl sx={{ width: '300px' }} size='small'>
                          <InputLabel id='from-select-label'>From</InputLabel>
                          <Select
                            className='form_style'
                            size='small'
                            labelId='from-select-label'
                            id='from-select'
                            label='From'
                            value={10}
                            // onChange={handleChange}
                          >
                            <MenuItem value={10}>
                              j2sales@zeal-global.com
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={2} lg={2}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          From:
                        </Typography>
                      </Grid> */}

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={2}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography style={{ fontWeight: 'bold' }}>
                          Recipients:
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={8}
                        lg={9}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <FormControl
                          sx={{ width: '300px', marginRight: '10px' }}
                          size='small'
                        >
                          <InputLabel id='recipients-select-label'>
                            Recipients
                          </InputLabel>
                          <Select
                            className='form_style'
                            size='small'
                            labelId='recipients-select-label'
                            id='recipients-select'
                            label='Recipients'
                            value={1}
                            // onChange={handleChange}
                          >
                            <MenuItem value={1}> {state?.email_id}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={2}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography style={{ fontWeight: 'bold' }}>
                          Collaborators:
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={8}
                        lg={9}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <FormControl
                          sx={{ width: '300px', marginRight: '10px' }}
                          size='small'
                        >
                          <MultiSelect
                            userList={collaboratorEmail}
                            label='Select Email Id'
                            emailId={ccEmailId}
                            setEmailId={setCCEmailId}
                            setCancelEmail={setCancelEmail}
                          />
                        </FormControl>

                        <Box
                          sx={{
                            transform: 'translateZ(0px)',
                            // flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <SpeedDial
                            ariaLabel='SpeedDial basic example'
                            icon={<Group />}
                            direction='right'
                          >
                            {actions.map((action) => (
                              <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.onClick}
                              />
                            ))}
                          </SpeedDial>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={2}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography style={{ fontWeight: 'bold' }}>
                          Reply To:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={8} lg={9}>
                        <FormControl sx={{ width: '300px' }} size='small'>
                          <InputLabel id='reply-to-select-label'>
                            Reply To
                          </InputLabel>
                          <Select
                            name='reply_to'
                            className='form_style'
                            size='small'
                            labelId='reply-to-select-label'
                            id='reply-to-select'
                            label='Reply To'
                            value={newCollaboratorsdata.reply_to}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>All Active Recipients</MenuItem>
                            <MenuItem value={2}>
                              Ticket Owner ({state?.email_id})
                            </MenuItem>
                            <MenuItem value={3}>
                              - Do Not Email Reply -
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <CardContent>
                      <Reactquilleditor
                        theme='snow'
                        placeholder='Write your reply here...'
                        setEditorHtml={setEditorHtml}
                        editorHtml={editorHtml}
                        handleChange={handleTextChange}
                      />
                    </CardContent>
                    <CardContent
                      sx={{ display: 'flex', gap: 5, alignItems: 'center' }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        Ticket Status:
                      </Typography>
                      <FormControl sx={{ width: '300px' }} size='small'>
                        <InputLabel id='response-select-label'>
                          Ticket status
                        </InputLabel>
                        <Select
                          className='form_style'
                          size='small'
                          labelId='response-select-label'
                          id='response-select'
                          label='Ticket status'
                          value={ticketstatus}
                          onChange={changeTicketStatus}
                        >
                          <MenuItem value={0}>Open (Currently)</MenuItem>
                          {/* <MenuItem value={1}>Resolved</MenuItem> */}
                          <MenuItem value={1}>Closed</MenuItem>
                        </Select>
                      </FormControl>
                    </CardContent>
                    <Button
                      variant='contained'
                      sx={{ float: 'right' }}
                      onClick={generatePostReply}
                    >
                      Post Reply
                    </Button>
                  </CustomTabPanel>
                  <CustomTabPanel value={innerValue} index={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '30px',
                        margin: '16px',
                      }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        Internal Note:
                      </Typography>
                      <TextField
                        id='outlined-basic'
                        label='Note title - summary of the note (optional)'
                        variant='outlined'
                        value={internoteTitle}
                        onChange={(e) => setInternoteTitle(e.target.value)}
                        sx={{ width: '400px' }}
                        size='small'
                      />
                    </div>

                    <CardContent>
                      <Reactquilleditor
                        theme='snow'
                        placeholder='Write Message...'
                        setEditorHtml={setEditorHtml}
                        editorHtml={editorHtml}
                        handleChange={handleTextChange}
                      />
                    </CardContent>
                    <Button
                      variant='contained'
                      sx={{ float: 'right' }}
                      onClick={generateInternalNote}
                    >
                      Post Note
                    </Button>
                  </CustomTabPanel>
                </Box>
              </CardContent>
            </CustomTabPanel>
            <CustomTabPanel value={outerValue} index={1}>
              Task
            </CustomTabPanel>
          </Box>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            color={'#2A67AC'}
            borderBottom={'1px solid #C8C8C8'}
          >
            Add a Collaborators
          </Typography>
          <Typography
            sx={{
              mt: 2,
              fontSize: '15px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
            bgcolor={'#d9edf7'}
            color={'#3a87ad'}
            border={'1px solid #bce8f1'}
          >
            {' '}
            <Info />
            Search existing users or add a new user.
          </Typography>
          <CardContent
            sx={{ padding: '0px', marginTop: '10px', marginBottom: '20px' }}
          >
            <Autocomplete
              disablePortal
              options={allCollaboratorData}
              getOptionLabel={(option) =>
                `${option.name} ${option.default_email_id}`
              }
              fullWidth
              sx={{
                // width: 'min(400px,100%)',
                // background: '#f2f2f2',
                borderRadius: 2,
              }}
              onChange={(e, v) => handleChangeCollaborator(e, v)}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <Typography variant='subtitle2' color='initial'>
                    {option.name}
                  </Typography>{' '}
                  &nbsp;
                  <Typography variant='subtitle2' color='#3a87ad'>
                    ({option.default_email_id})
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Search By Name or Email'
                  // error={fieldError?.agent}
                  // helperText={
                  //   fieldError?.agent && 'Please select Agent'
                  // }
                  fullWidth
                />
              )}
              size='small'
            />

            {!collaboratorId && (
              <Typography
                sx={{
                  mt: 2,
                  fontSize: '15px',
                  fontWeight: '500',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
                bgcolor={'#eee'}
                color={'#000'}
                border={'1px dotted #bbb'}
              >
                {' '}
                <Create />
                Create New User
              </Typography>
            )}
          </CardContent>
          {!collaboratorId && (
            <CardContent>
              <Grid container spacing={2} sx={{ gap: 2, marginLeft: '10px' }}>
                <Grid
                  itm
                  md={11}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Email Address:
                  </Typography>

                  <TextField
                    label='Email'
                    size='small'
                    sx={{ width: '350px' }}
                    name='email'
                    value={newCollaboratorsdata.email}
                    // error={error && error['full_name']}
                    onChange={(e) => handleChange(e, 'email')}
                  />
                </Grid>
                <Grid
                  itm
                  md={11}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>Name:</Typography>
                  <TextField
                    label='Name'
                    size='small'
                    sx={{ width: '350px' }}
                    name='name'
                    value={newCollaboratorsdata.name}
                    // error={error && error['full_name']}
                    onChange={(e) => handleChange(e, 'name')}
                  />
                </Grid>
                <Grid
                  itm
                  md={11}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>Phone No:</Typography>
                  <MuiPhoneNumber
                    className='form_style'
                    variant='outlined'
                    name='phone'
                    value={newCollaboratorsdata.phone}
                    defaultCountry={'in'}
                    onChange={(e, v) => handleChangePhone(e, v)}
                    // error={error && error['contact']}
                    size='small'
                    sx={{ width: '350px' }}
                  />
                </Grid>
                <Grid
                  itm
                  md={11}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Internal Note:
                  </Typography>
                  <div style={{ padding: '0px', width: '350px' }}>
                    <CustomTextArea
                      placeholder={'Message...'}
                      name={'internal_note'}
                      value={newCollaboratorsdata.internal_note}
                      onChange={(e) => handleChange(e, 'internal_note')}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          )}
          {collaboratorId && (
            <CardContent sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <AccountBox sx={{ color: 'lightgrey', fontSize: '100px' }} />
                </Grid>
                <Grid item md={9}>
                  <Typography variant='h6' color={'#3a87ad'}>
                    {selectedCollaborator?.name}
                  </Typography>
                  <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                    &lt;{selectedCollaborator?.default_email_id}&gt;
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      fontsize: '18px',
                      fontWeight: 'bold',
                      borderBottom: '2px dotted #000',
                    }}
                  >
                    Contact Information
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,

                      mt: 1,
                    }}
                  >
                    Phone Number-{' '}
                    <p style={{ fontWeight: 500 }}>
                      {!selectedCollaborator?.phone === null
                        ? selectedCollaborator?.phone
                        : 'NA'}
                    </p>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    Internal Note-{' '}
                    <p style={{ fontWeight: 500 }}>
                      {!selectedCollaborator?.internal_note === null
                        ? selectedCollaborator?.internal_note
                        : 'NA'}
                    </p>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item md={6}>
                  <Button
                    color='error'
                    sx={{ border: '1px solid red' }}
                    onClick={handleClose}
                    size='small'
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <div style={{ display: 'flex', gap: 10, float: 'right' }}>
                    <Button
                      color='inherit'
                      sx={{ border: '1px solid #000' }}
                      onClick={handleControl}
                      size='small'
                    >
                      Add New User
                    </Button>
                    <Button
                      color='info'
                      size='small'
                      sx={{ border: '1px solid #3a87ad' }}
                      onClick={handleContinue}
                    >
                      Continue
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          )}
          {!collaboratorId && (
            <Button
              sx={{
                float: 'right',
                background: '#3a87ad',
                '&:hover': {
                  color: 'black',
                  backgroundColor: '#d9edf7',
                  // transition: 'backgroundColor 0.3s ease',
                },
              }}
              variant='contained'
              onClick={createNewCollaborator}
            >
              Add User
            </Button>
          )}
        </Card>
      </Modal>
      <Modal
        open={openManageCollaborator}
        onClose={handleCloseManageCollaborator}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            color={'#2A67AC'}
            borderBottom={'1px solid #C8C8C8'}
          >
            Collaborators
          </Typography>

          <CardContent
            sx={{
              background: '#edf1f4',
              width: '100%',
              height: '70vh',
              overflowY: 'auto',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
              marginTop: '10px',
            }}
          >
            {collaboratorData.map((item) => (
              <Paper
                key={item.id}
                sx={{
                  width: '100%',
                  marginBottom: 2,
                  display: 'flex',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                }}
              >
                <Grid container spacing={2} alignItems='center' padding={1}>
                  <Grid item md={1} xs={1}>
                    <Checkbox
                      checked={item.status === '0'}
                      onChange={() =>
                        handleCollaboratorStatus(item.collaborator_id)
                      }
                    />
                  </Grid>
                  <Grid item md={9} xs={9}>
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 500,
                      }}
                    >
                      <AccountBox
                        sx={{ marginRight: 1, color: 'lightgrey' }}
                        fontSize='large'
                      />
                      <span>
                        <strong style={{ color: '#3a87ad' }}>
                          {item?.name}
                        </strong>
                        <br />
                        <p>{item?.default_email_id}</p>
                      </span>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={2}
                    xs={2}
                    sx={{ textAlign: { md: 'right', xs: 'left' } }}
                  >
                    <IconButton
                      aria-label='delete'
                      color='error'
                      size='large'
                      sx={{ paddingTop: { md: 0, xs: 2 } }}
                      // onClick={() => handleSubUserDelete(item)}
                      onClick={() =>
                        handleCollaboratorDelete(item.collaborator_id)
                      }
                    >
                      <DeleteSweepTwoTone />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </CardContent>
          <Button
            variant='contained'
            sx={{ float: 'right', mt: 1 }}
            onClick={handleupdateCollaborator}
          >
            Save changes
          </Button>
        </Card>
      </Modal>
    </DashboardLayout>
  )
}

export default ImapThreads
