import React from 'react'
import './policystyle.css'
const Termandcondition = () => {
  return (
    <>
      <header className='bg-gray-900 py-6 px-4 md:px-6'>
        <div className='container1 mx-auto'>
          <h1 className='text-2xl font-bold text-white'>Terms & Conditions</h1>
        </div>
      </header>
      <div className='container1 mx-auto my-12 px-4 md:px-6'>
        <div className='grid grid-cols-1 md:grid-cols-[200px_1fr] gap-8'>
          <div className='space-y-12'>
            <section id='introduction'>
              <h2 className='text-2xl font-bold mb-4'>Introduction</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                This website is operated by Zeal Global Services Limited
                (hereinafter referred to as the ‘Company’ or ‘Zeal Global’).
                Zeal Global offers this website, including all information,
                tools, and services available from this site to you, the user,
                conditioned upon your acceptance of all terms, conditions,
                policies, and notices stated here. By visiting our site and/or
                purchasing something from us, you engage in our “Service” and
                agree to be bound by the following terms and conditions. These
                Terms of Service apply to all users of the site, including,
                without limitation, users who are browsers, vendors, customers,
                merchants, and/or contributors of content. Please read these
                Terms carefully before accessing or using our website. By
                accessing or using any part of the site, you agree to be bound
                by these Terms. If you do not agree to all the terms and
                conditions of this agreement, then you may not access the
                website or use any services. If these Terms of Service are
                considered an offer, acceptance is expressly limited to these
                Terms of Service. Any new features or tools that are added to
                the current store shall also be subject to the Terms of Service.
                You can review the most current version of the Terms of Service
                at any time on this page. We reserve the right to update,
                change, or replace any part of these Terms of Service by posting
                updates and/or changes to our website. It is your responsibility
                to check this page periodically for changes. Your continued use
                of or access to the website following the posting of any changes
                constitutes acceptance of those changes.
              </p>
            </section>
            <section id='definitions'>
              <h2 className='text-2xl font-bold mb-4'>General Conditions</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                This website is operated by Zeal Global Services Limited
                (hereinafter referred to as the ‘Company’ or ‘Zeal Global’).
                Zeal Global offers this website, including all information,
                tools, and services available from this site to you, the user,
                conditioned upon your acceptance of all terms, conditions,
                policies, and notices stated here. By visiting our site and/or
                purchasing something from us, you engage in our “Service” and
                agree to be bound by the following terms and conditions. These
                Terms of Service apply to all users of the site, including,
                without limitation, users who are browsers, vendors, customers,
                merchants, and/or contributors of content. Please read these
                Terms carefully before accessing or using our website. By
                accessing or using any part of the site, you agree to be bound
                by these Terms. If you do not agree to all the terms and
                conditions of this agreement, then you may not access the
                website or use any services. If these Terms of Service are
                considered an offer, acceptance is expressly limited to these
                Terms of Service. Any new features or tools that are added to
                the current store shall also be subject to the Terms of Service.
                You can review the most current version of the Terms of Service
                at any time on this page. We reserve the right to update,
                change, or replace any part of these Terms of Service by posting
                updates and/or changes to our website. It is your responsibility
                to check this page periodically for changes. Your continued use
                of or access to the website following the posting of any changes
                constitutes acceptance of those changes.
              </p>
            </section>
            <section id='user-accounts'>
              <h2 className='text-2xl font-bold mb-4'>
                Accuracy, Completeness, and Timeliness of Information:
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                We are not responsible if the information made available on this
                site is not accurate, complete, or current. The material on this
                site is provided for general information only and should not be
                relied upon as the sole basis for making decisions without
                consulting primary, more accurate, more complete, or more timely
                sources of information. Any reliance on the material on this
                site is at your own risk. This site may contain certain
                historical information. Historical information, by its nature,
                is not current and is provided for your reference only. We
                reserve the right to modify the contents of this site at any
                time, but we have no obligation to update any information on our
                site. You agree that it is your responsibility to monitor
                changes to our site.
              </p>
            </section>
            <section id='content'>
              <h2 className='text-2xl font-bold mb-4'>
                Modifications to the Terms and Conditions and Price
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                Prices for our products are subject to change without notice. We
                reserve the right at any time to modify or discontinue the
                Service (or any part or content thereof) without notice. We
                shall not be liable to you or any third-party for any
                modification, price change, suspension, or discontinuance of the
                Service.
              </p>
            </section>
            <section id='intellectual-property'>
              <h2 className='text-2xl font-bold mb-4'>Products</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                Certain products or services may be available exclusively online
                through the website. These products or services may have limited
                quantities and are subject to return or exchange only according
                to our Return Policy. We have made every effort to display as
                accurately as possible the colors and images of our products
                that appear at the store. However, we cannot guarantee that your
                computer monitor’s display of any color will be accurate. We
                reserve the right, but are not obligated, to limit the sales of
                our products or services to any person, geographic region, or
                jurisdiction. We may exercise this right on a case-by-case
                basis. We reserve the right to limit the quantities of any
                products or services that we offer. All descriptions of products
                or product pricing are subject to change at any time without
                notice, at the sole discretion of us. We reserve the right to
                discontinue any product at any time. Any offer for any product
                or service made on this site is void where prohibited. We do not
                warrant that the quality of any products, services, information,
                or other material purchased or obtained by you will meet your
                expectations, or that any errors in the Service will be
                corrected.
              </p>
            </section>
            <section id='termination'>
              <h2 className='text-2xl font-bold mb-4'>
                Billing & Account Information
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                We retain the right to decline any order you place with us. In
                our sole discretion, we may limit or cancel quantities purchased
                per person, per household, or per order. These restrictions may
                encompass orders placed under the same customer account, the
                same credit card, and/or orders utilizing identical billing
                and/or shipping addresses. Should we alter or cancel an order,
                we will make an effort to notify you by reaching out to the
                email and/or billing address/phone number provided at the time
                of order placement. The right to restrict or prohibit orders,
                which, in our sole judgment, seem to be placed by dealers,
                resellers, or distributors, is reserved. You commit to providing
                current, complete, and accurate purchase and account information
                for all transactions made at our store. It is your
                responsibility to promptly update your account and other
                information, including your email address and credit card
                details, to facilitate the completion of transactions and enable
                us to contact you as needed. For more details, please review our
                Returns Policy.
              </p>
            </section>
            <section id='disclaimer-of-warranties'>
              <h2 className='text-2xl font-bold mb-4'>Optional Tools</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                We may grant you access to third-party tools, over which we
                neither monitor nor have any control nor input. You acknowledge
                and agree that we provide access to such tools “as is” and “as
                available” without any warranties, representations, or
                conditions of any kind, and without any endorsement. We shall
                bear no liability whatsoever arising from or relating to your
                use of optional third-party tools. Your use of optional tools
                offered through the site is entirely at your own risk and
                discretion, and you should ensure that you are familiar with and
                approve of the terms on which tools are provided by the relevant
                third-party provider(s). Additionally, we may, in the future,
                introduce new services and/or features through the website
                (including the release of new tools and resources). Such new
                features and/or services shall also be subject to these Terms of
                Service.
              </p>
            </section>
            <section id='limitation-of-liability'>
              <h2 className='text-2xl font-bold mb-4'>Third-Party Links</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                Certain content, products, and services available via our
                Service may include materials from third parties. Third-party
                links on this site may direct you to third-party websites that
                are not affiliated with us. We are not responsible for examining
                or evaluating the content or accuracy, and we do not warrant and
                will not have any liability or responsibility for any
                third-party materials or websites, or for any other materials,
                products, or services of third parties. We are not liable for
                any harm or damages related to the purchase or use of goods,
                services, resources, content, or any other transactions made in
                connection with any third-party websites. Please carefully
                review the third party’s policies and practices and ensure that
                you understand them before engaging in any transaction.
                Complaints, claims, concerns, or questions regarding third-party
                products should be directed to the third party.
              </p>
            </section>
            <section id='indemnification'>
              <h2 className='text-2xl font-bold mb-4'>
                Submissions on the website
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                If, at our request, you submit specific content (such as contest
                entries), or without a request from us, you submit creative
                ideas, suggestions, proposals, plans, or other materials,
                whether online, by email, by postal mail, or otherwise
                (collectively referred to as ‘comments’), you agree that we may,
                at any time, without restriction, edit, copy, publish,
                distribute, translate, and otherwise use, in any medium, any
                comments that you forward to us. We are and shall be under no
                obligation: (1) to maintain any comments in confidence; (2) to
                pay compensation for any comments; or (3) to respond to any
                comments. We may, but have no obligation to, monitor, edit, or
                remove content that we determine, in our sole discretion, to be
                unlawful, offensive, threatening, libelous, defamatory,
                pornographic, obscene, or otherwise objectionable or violating
                any party’s intellectual property or these Terms of Service. You
                agree that your comments will not violate any right of any
                third-party, including copyright, trademark, privacy,
                personality, or other personal or proprietary rights. You
                further agree that your comments will not contain libelous or
                otherwise unlawful, abusive, or obscene material, or contain any
                computer virus or other malware that could affect the operation
                of the Service or any related website. You may not use a false
                e-mail address, pretend to be someone other than yourself, or
                otherwise mislead us or third parties as to the origin of any
                comments. You are solely responsible for any comments you make
                and their accuracy. We take no responsibility and assume no
                liability for any comments posted by you or any third party.
              </p>
            </section>
            <section id='governing-law'>
              <h2 className='text-2xl font-bold mb-4'>Prohibited Uses</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                In addition to other prohibitions as set forth in the Terms of
                Service, you are prohibited from using the site or its content:
                (a) for any unlawful purpose; (b) to solicit others to perform
                or participate in any unlawful acts; (c) to violate any
                international, federal, provincial, or state regulations, rules,
                laws, or local ordinances; (d) to infringe upon or violate our
                intellectual property rights or the intellectual property rights
                of others; (e) to harass, abuse, insult, harm, defame, slander,
                disparage, intimidate, or discriminate based on gender, sexual
                orientation, religion, ethnicity, race, age, national origin, or
                disability; (f) to submit false or misleading information; (g)
                to upload or transmit viruses or any other type of malicious
                code that will or may be used in any way that will affect the
                functionality or operation of the Service or of any related
                website, other websites, or the Internet; (h) to collect or
                track the personal information of others; (i) to spam, phish,
                pharm, pretext, spider, crawl, or scrape; (j) for any obscene or
                immoral purpose; or (k) to interfere with or circumvent the
                security features of the Service or any related website, other
                websites, or the Internet. We reserve the right to terminate
                your use of the Service or any related website for violating any
                of the prohibited uses.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Errors on the website</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                Occasionally there may be information on our site or in the
                Service that contains typographical errors, inaccuracies, or
                omissions that may relate to product descriptions, pricing,
                promotions, offers, product shipping charges, transit times, and
                availability. We reserve the right to correct any errors,
                inaccuracies, or omissions, and to change or update information
                or cancel orders if any information in the Service or on any
                related website is inaccurate at any time without prior notice
                (including after you have submitted your order). We undertake no
                obligation to update, amend, or clarify information in the
                Service or on any related website, including without limitation,
                pricing information, except as required by law. No specified
                update or refresh date applied in the Service or on any related
                website should be taken to indicate that all information in the
                Service or on any related website has been modified or updated.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>
                Warranties & Limitations of Liabilities
              </h2>
              <p className='text-gray-700 dark:text-gray-400'>
                We do not guarantee, represent, or warrant that your use of our
                service will be uninterrupted, timely, secure, or error-free. We
                do not warrant that the results obtained from the service will
                be accurate or reliable. You acknowledge that we may, from time
                to time, remove the service for indefinite periods or cancel the
                service at any time without notice. Your use of, or inability to
                use, the service is at your sole risk. The service, along with
                all products and services delivered through it, is provided ‘as
                is’ and ‘as available,’ without any representation, warranties,
                or conditions, either express or implied. This includes all
                implied warranties or conditions of merchantability,
                merchantable quality, fitness for a particular purpose,
                durability, title, and non-infringement. In no case shall Zeal
                Global Ventures Private Limited, our directors, officers,
                employees, affiliates, agents, contractors, interns, suppliers,
                service providers, or licensors be liable for any injury, loss,
                claim, or any direct, indirect, incidental, punitive, special,
                or consequential damages. This includes lost profits, revenue,
                savings, data, replacement costs, or any similar damages,
                whether based in contract, tort (including negligence), strict
                liability, or otherwise, arising from your use of the service or
                any products procured using the service. This also includes any
                claim related in any way to your use of the service or any
                product, even if advised of the possibility of such damages. In
                states or jurisdictions where the exclusion or limitation of
                liability for consequential or incidental damages is not
                allowed, our liability shall be limited to the maximum extent
                permitted by law.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Indemnification</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                You agree to indemnify, defend, and hold harmless Zeal Global
                Services Limited, our parent, subsidiaries, affiliates,
                partners, officers, directors, agents, contractors, licensors,
                service providers, subcontractors, suppliers, interns, and
                employees from any claim or demand, including reasonable
                attorneys’ fees, made by any third-party due to or arising out
                of your breach of these Terms of Service or the documents they
                incorporate by reference, or your violation of any law or the
                rights of a third-party.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Severability</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                If any provision of these Terms of Service is determined to be
                unlawful, void, or unenforceable, such provision shall
                nonetheless be enforceable to the fullest extent permitted by
                applicable law, and the unenforceable portion shall be deemed
                severed from these Terms of Service. This determination shall
                not affect the validity and enforceability of any other
                remaining provisions.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Errors on the website</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                Occasionally there may be information on our site or in the
                Service that contains typographical errors, inaccuracies, or
                omissions that may relate to product descriptions, pricing,
                promotions, offers, product shipping charges, transit times, and
                availability. We reserve the right to correct any errors,
                inaccuracies, or omissions, and to change or update information
                or cancel orders if any information in the Service or on any
                related website is inaccurate at any time without prior notice
                (including after you have submitted your order). We undertake no
                obligation to update, amend, or clarify information in the
                Service or on any related website, including without limitation,
                pricing information, except as required by law. No specified
                update or refresh date applied in the Service or on any related
                website should be taken to indicate that all information in the
                Service or on any related website has been modified or updated.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Termination</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                The obligations and liabilities of the parties incurred prior to
                the termination date shall survive the termination of this
                agreement for all purposes. These Terms of Service are effective
                unless and until terminated by either you or us. You may
                terminate these Terms of Service at any time by notifying us
                that you no longer wish to use our Services or when you cease
                using our site. If, in our sole judgment, you fail, or we
                suspect that you have failed, to comply with any term or
                provision of these Terms of Service, we also may terminate this
                agreement at any time without notice, and you will remain liable
                for all amounts due up to and including the date of termination;
                and/or accordingly may deny you access to our Services (or any
                part thereof).
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Changes to Terms</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                You can review the most current version of the Terms of Service
                at any time on this page. We reserve the right, at our sole
                discretion, to update, change, or replace any part of these
                Terms of Service by posting updates and changes to our website.
                It is your responsibility to check our website periodically for
                changes. Your continued use of or access to our website or the
                Service following the posting of any changes to these Terms of
                Service constitutes acceptance of those changes.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Governing Law</h2>
              <p className='text-gray-700 dark:text-gray-400'>
                These Terms of Service and any separate agreements whereby we
                provide you Services shall be governed by and construed in
                accordance with the laws of India and the jurisdiction of
                Mumbai, Maharashtra.
              </p>
            </section>
            <section id='contact-information'>
              <h2 className='text-2xl font-bold mb-4'>Contact Information</h2>
              <p className='text-gray-700 dark:text-gray-400'>
              If you have any questions regarding our Terms & Conditions, you may contact us at <strong>info@zeal-global.com</strong>
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default Termandcondition
