import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const ProtectedRoute = ({allowedRoles,children}) => {

  const { auth } = useContext(AuthContext);
  const location = useLocation();

  if (auth.isAuthenticated) {
    if (allowedRoles.includes(auth.role)) {
      return children ? children :  <Outlet />
    } else {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  }else {
    return <Navigate to="/login" state={{from : location}} replace />;
  }
};

export default ProtectedRoute;
