import React, { useEffect } from "react";
import styles from "./layout.module.css";
import { Grid, Hidden } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";

const Form = ({ children }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  
  useEffect(()=>{
    if (auth.isAuthenticated) {
      navigate(from,{ replace: true });
    }
  },[auth.isAuthenticated])

  return (
    <>
      {!auth.isAuthenticated && (
        <Grid container spacing={0} className={styles.container}>
           <Hidden xsDown>
          <Grid  item xs={12} sm={6} md={6} lg={6} xl={6} className={styles.imageContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                height: "100vh",
                background:
                  'linear-gradient(rgba(0, 0, 0, 0 ),rgba(0, 0, 0, 0.4)),url("./images/ticket.png")',
                backgroundSize: "cover",
              }}
            >
              <h2 style={{ color: "#F9DA34", textAlign: "center", marginBottom:"10px" }}>
              "Together we Travel, Together we Explore."
              </h2>
            </div>
          </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={styles.mainDiv}>
            {children}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Form;
