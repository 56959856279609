import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import MyStatefulEditor from '../../../../components/Text Editor/Texteditor'

const Createtermandcondition = () => {
    const [editorContent, setEditorContent] = React.useState('');
    
    const handleEditorChange = (htmlString) => {
        console.log('HTML Content:', htmlString);
        setEditorContent(htmlString);
      };

  return (
    
    <DashboardLayout
      heading='Term and Condition'
      pathName='Term and Condition'
      
    >
         <Card sx={{ height: '80vh' }} elevation={0}>
            <Typography variant='h6' sx={{ fontWeight: 'bold', fontSize: '20px', margin:'15px', color:'#0066b2' }}>
                Create Term & Policy
            </Typography>
            <CardContent>
            <MyStatefulEditor onChange={handleEditorChange}/>
            </CardContent>
            

         </Card>
      
    </DashboardLayout>
 
  )
}

export default Createtermandcondition