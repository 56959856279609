import React, { useState } from 'react'
import RichTextEditor from 'react-rte'
import './style.css'

const MyStatefulEditor = ({ editorContentdata, setEditorContentdata, onChange }) => {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue())

  const handleChange = (value) => {
    console.log('value', value)
    setValue(value)
    if (onChange) {
      onChange(value.toString('html'))
    }
  }
  React.useEffect(() => {
    if (editorContentdata === true) {
      setValue(RichTextEditor.createEmptyValue())
      setEditorContentdata(false)
    }
  }, [editorContentdata])

  return (
    <RichTextEditor
      placeholder='Enter your text here...'
      value={value}
      onChange={handleChange}
      className='custom-editor'
    />
  )
}

export default MyStatefulEditor
