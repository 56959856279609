import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'

import Card from '@mui/material/Card'
import { Create } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Mappinglisting from './Mappinglisting'
import { useLocation } from 'react-router-dom'

const Mappingmain = () => {
    const { state } = useLocation()
  
  return (
    <Card sx={{ height: '100%' }} elevation={0}>
      <DashboardLayout
        heading='Create Mapping'
        pathName='Create Mapping'
       
      >
        <Mappinglisting state={state}/>
      </DashboardLayout>
    </Card>
  )

}
export default Mappingmain
