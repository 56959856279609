import React from "react";
import {Typography}  from "@mui/material"

const CustomTextArea = ({name,value,onChange,placeholder,error=false,row=4}) => {
  const textAreaStyle = {
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    border: "2px solid",
    borderColor: error ? "#d32f2f"  : "gray",
  };
  
  return (
    <div>
      <textarea
      className="form_style"
        placeholder={placeholder}
        rows={row}
        style={textAreaStyle}
        name={name}
        value={value}
        onChange={onChange}
      />
       <Typography  variant="subtitle2" color="error">{error && '*Remark is required'}</Typography>
    </div>
  );
};

export default CustomTextArea;
