import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";

const TableMenu = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <GridMoreVertIcon />
        </IconButton>
        <Menu
          id="menulist"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {data.map((item) => (
            <MenuItem key={item.id} onClick={item.fn} disabled={item.disable}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <Typography variant="subtitle2" color="initial" onClick={handleClose}>
                {item.title}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
};

export default TableMenu;
