import { Box, styled } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";

const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
  background: 'linear-gradient(to right, #64b5f6, #4caf50)',
  height: '8px',
  animation: '$moveGradient 5s infinite linear', // Use $ to reference the keyframe animation
  position: 'relative',
  overflow: 'hidden',
  marginTop: "-2px !important",
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to right, #4caf50, #64b5f6)',
    animation: '$moveGradient 5s infinite linear', // Use $ to reference the keyframe animation
  },
  '@keyframes moveGradient': {
    '0%': {
      backgroundPosition: '200% 0',
    },
    '100%': {
      backgroundPosition: '-200% 0',
    },
  },
}));

const CustomLinearProgress = ({ progress }) => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* Use the custom gradient progress component */}
      <GradientLinearProgress
        variant="determinate"
        value={progress}
        // progress={progress}
      />
    </Box>
  );
};

export default CustomLinearProgress;
