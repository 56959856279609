import { Button, CircularProgress } from "@mui/material";
import React from "react";

const LoadingBtn = ({onClick,btnIcon,loading,disabled=false,text,style,variant="contained",color,loadingTxt=true,className,fullWidth}) => {

  return (
    <>
      <Button
        variant={variant}
        onClick={onClick}
        color={color}
        disabled={loading || disabled}
        className={className}
        startIcon={!loading && btnIcon}
        style={style}
        fullWidth={fullWidth}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={25} style={{ marginRight: 10 }} />
            {/* <span>{loadingTxt && ""}</span> */}
          </div>
        ) : (
          text
        )}
      </Button>
    </>
  );
};

export default LoadingBtn;
