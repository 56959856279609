import React from "react";
import styles from "./loader.module.css";

const Loader = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.loader}>
          <div className={styles.wait}>
            <img src="../images/logo.png" alt="logo" />
          </div>
          <div className={`${styles.iata_code} ${styles.departure_city}`}>
            DEL
          </div>
          <div className={styles.planeContainer}>
            <div className={styles.plane}>
              <img
                src="https://zupimages.net/up/19/34/4820.gif"
                className={styles.plane_img}
                alt="loading"
              />
            </div>
            <div className={styles.earth_wrapper}>
              <div className={styles.earth}></div>
            </div>
          </div>
          <div className={`${styles.iata_code} ${styles.arrival_city}`}>
            BAKU
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
