import React, { useState } from "react";
import {Button} from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "@emotion/styled";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  })

const UploadBtn = ({setter,text,varient}) => {

  const [selectedFileName,setSelectedFileName] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFileName(e.target.files[0].name);
    setter(e.target.files[0]);
  };

  return (
    <Button
      component="label"
      variant={varient}
      startIcon={<CloudUploadIcon />}
      color={selectedFileName ? 'success' : 'submit'}
      size="small"
      sx={{width : '140px',boxShadow : "none"}}
    >
      {selectedFileName ? selectedFileName?.slice(0,10)  : text}
      <VisuallyHiddenInput
        type="file"
        onChange={handleFileChange}
      />
    </Button>
  );
};

export default UploadBtn;
