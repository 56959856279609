import { Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import LoadingBtn from "../common/LoadingBtn";
import { NavLink, useNavigate } from "react-router-dom";
import { Home } from "@mui/icons-material";

const DashboardLayout = ({
  children,
  text,
  button = false,
  btnIcon,
  onClick,
  btnText,
  isLoading = false,
  backBtn = false,
  pathName,
  heading,
  pathName1 = null,
  pathLink,
  // btnBg = "#39A7FF",
}) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        width: "100%",
        padding: "10px",
        height: "100%",
        bgcolor: "#f2f4f6",
        overflow: "auto",
      }}
      elevation={0}
    >
      <CardContent style={{ padding: "0px 10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height : "39px"
          }}
        >
          <div>
            <Typography
              fontWeight={500}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "15px",
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard")}
              >
                <Home fontSize="small" sx={{ marginTop: "4px",color :"gray" }} />
              </span>
              &nbsp;/&nbsp;
              <NavLink to={pathLink}>
                <span style={{ color: pathName1 ? "grey" : "black",marginBottom: "10px", fontSize : pathName1 ?  "15px" : "18px",fontWeight : "500" }}>
                  {pathName}
                </span>
              </NavLink>
              {pathName1 && (
                <span style={{ color: "black" ,fontSize : "18px" }}>
                  &nbsp;&nbsp;/&nbsp;{pathName1}
                </span>
              )}
            </Typography>
            {/* <Typography
              variant="h6"
              textAlign="left"
              sx={{ fontWeight: "500", fontSize: "22px", marginTop: "0px" }}
            >
              {heading}
            </Typography> */}
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            {backBtn && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#504f37",
                  color: "white",
                  width: "7rem",
                  height: "39px",
                }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            )}
            {button && (
              <div style={{ display: "flex", gap: "5px" }}>
                <LoadingBtn
                  color={'submit'}
                  variant="contained"
                  style={{
                    // backgroundColor: btnBg,
                    minWidth: "7rem",
                    color: "white",
                    height: "39px",
                  }}
                  btnIcon={btnIcon}
                  onClick={onClick}
                  text={btnText}
                  loading={isLoading}
                  
                >
                  {btnText}
                </LoadingBtn>
              </div>
            )}
          </div>
        </div>
      </CardContent>

      <CardContent
        style={{
          boxShadow: "rgba(99, 99, 99, 0.4) 10px 20px 8px 0px",
          padding: "0px",
          border: "1px solid rgba(0,0,0,0.17)",
          marginTop: "12px",
          overflow: "auto",
          borderRadius : "6px"
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default DashboardLayout;
