import React, { useEffect, useState } from 'react'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { getSubGroupMasterApi } from '../../../services/admin.configService'
import {
  getPrimaryGroupMaster,
  getSubGroupMaster,
} from '../../../utils/invoiceUtils'
import { handleChangeGlobal } from '../../../utils/common'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'

const CreateLedger = () => {
  const [subGroupList, setSubGroupList] = useState([])
  const [primaryGroupList, setPrimaryGroupList] = useState([])
  const [showPrimarygroup, setShowPrimarygroup] = useState('')
  const [ledgerData, setLedgerData] = useState({
    ledger_name: '',
    sub_group_id: '',
    group_type: '',
  })
  const [error, setError] = useState()

  const handleSave = () => {}

  async function getPreData() {
    try {
      const primary = await getPrimaryGroupMaster()
      const subGroup = await getSubGroupMaster()
      setSubGroupList(subGroup)
      setPrimaryGroupList(primary)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (ledgerData.sub_group_id) {
      const primaryGroup = primaryGroupList.find(
        (item) => item.id == ledgerData.sub_group_id
      )

      setShowPrimarygroup(primaryGroup)
      console.log('lsubgroup', primaryGroup)
    }
  }, [ledgerData.sub_group_id])

  console.log('ledger data ', ledgerData)

  useEffect(() => {
    getPreData()
  }, [])

  return (
    <>
      <DashboardLayout
        heading='Create Ledger'
        pathName='Create Ledger'
        text={'Create Ledger'}
        button={true}
        btnText={'Save'}
        onClick={handleSave}
      >
        <Grid container padding={3} width={'100%'}>
          <Grid item md={5}>
            <TextField
              variant='outlined'
              className='form_style'
              label='Ledger Name'
              size='small'
              name='ledger_name'
              value={ledgerData?.ledger_name}
              error={error && error['ledger_name']}
              onChange={(e) =>
                handleChangeGlobal(
                  e,
                  setLedgerData,
                  setError,
                  error,
                  'ledger_name'
                )
              }
              fullWidth
            />
            <FormHelperText style={{ color: 'red', marginLeft: '4px' }}>
              {error && error['ledger_name']}
            </FormHelperText>
          </Grid>
          <Grid container gap={2.5} marginTop={2.5} alignItems={'center'}>
            <Grid item md={5} marginTop={'13px'}>
              <FormControl
                sx={{ marginLeft: '' }}
                error={error && error['sub_group_id']}
                fullWidth
              >
                <InputLabel id='demo-simple-select-label' size='small'>
                  Sub Group Name
                </InputLabel>
                <Select
                  className='form_style'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  size='small'
                  label='Sub Group Name'
                  name='sub_group_id'
                  value={ledgerData.sub_group_id}
                  onChange={(e) =>
                    handleChangeGlobal(e, setLedgerData, setError, error, '')
                  }
                >
                  {subGroupList.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.group_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {error && error['sub_group_id']}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <label style={{ fontSize: '12px' }}>Primary Group</label>
              <input
                value={showPrimarygroup?.group_name}
                disabled
                className='invoice_input_diabled'
              />
            </Grid>
            <Grid item md={3}>
              <label style={{ fontSize: '12px' }}>Group Type</label>
              <input
                value={showPrimarygroup?.group_type}
                disabled
                className='invoice_input_diabled'
              />
            </Grid>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  )
}

export default CreateLedger
