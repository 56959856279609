import React, { useContext, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { updatePnrApi } from "../../../../services/admin.configService";
import { useCSVReader } from "react-papaparse";
import { SnackbarContext } from "../../../../context/Snackbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "8px",
  borderRadius: "0px 0px 6px 6px",
  backgroundColor: "white",
  borderTop: "6px solid rgb(93 137 183)",
};

const UploadModal = ({ setShow, query_id,setError,setQueryId}) => {
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext);

  const { CSVReader } = useCSVReader();

  const sendColumn = [
    "booking_id",
    "passenger_id",
    "pnr",
    "ticket_no"
  ];

  const handleUploadCloseModal = () => {
    setShow(false);
  };

  async function uploadCsvData(csv) {
    const sendJson = csv?.slice(1)?.map((item) => {
      const obj = {};
      const data = item.slice(0,2).concat(item.slice(-2));
      sendColumn.forEach((key, index) => {
        obj[key] = data[index];
      });
      return obj;
    });
    const data = {
      csvData :sendJson.filter(item=>item.booking_id != ""),
      query_id,
    };
    try {
      const res = await updatePnrApi(data, setError);
      // console.log("32442res",res);
      if(res === 406){
        setSeverity("error");
        setMes("Wrong file upload! please check file");
        setOn(true);
      }
      if(res !== 406 && res !== 204) {

        setSeverity("success");
        setMes("PNR upload succesfully");
        setOn(true);
        setQueryId(null);
        setShow(false)
      }
    } catch (error) {
      let c = error.message;
      setSeverity("error");
      setMes(c);
      setOn(true);
      console.log(error);
    }
  }

  return (
    <Grid container style={style}>
      <Grid item>
        <ul
          style={{
            marginLeft: "30px",
            fontSize: "13px",
            color: "rgba(0,0,0,.8)",
            lineHeight: "24px",
            fontWeight: 500,
            marginTop: "10px",
          }}
        >
          <li>
            <Typography variant="subtitle2" color="initial">
              {" "}
              Download the Passenger list.
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle2" color="initial">
              Update the <strong>PNR column</strong>, but ensure that no other
              changes are made.
            </Typography>{" "}
          </li>
          <li>
            <Typography variant="subtitle2" color="initial">
              {" "}
              Match the <strong>Booking ID</strong> first.
            </Typography>{" "}
          </li>
          <li>
            <Typography variant="subtitle2" color="initial">
              Enter the <strong>PNR number</strong> in the PNR column.
            </Typography>{" "}
          </li>
          <li>
            <Typography variant="subtitle2" color="initial">
              Upload the updated <strong>CSV</strong> file by clicking the
              ‘Upload’ button.
            </Typography>{" "}
          </li>
        </ul>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          margin: "15px 10px 10px 0px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "grey",
            color: "white",
            width: "6rem",
            height: "34px",
            "&:hover": {
              backgroundColor: "#a5a3a3",
            },
          }}
          onClick={handleUploadCloseModal}
        >
          Back
        </Button>
        <CSVReader
          onUploadAccepted={(results) => {
            uploadCsvData(results.data);
          }}
        >
          {({ getRootProps }) => (
            <>
              <div>
                <Button
                  variant="container"
                  sx={{ backgroundColor: "#2a6ebd", color: "white",
                  "&:hover": {
                    backgroundColor: "#4b8ad5",
                  },
                
                }}
                  type="button"
                  size="small"
                  {...getRootProps()}
                >
                  upload file
                </Button>
              </div>
            </>
          )}
        </CSVReader>
      </Grid>
    </Grid>
  );
};

export default UploadModal;
