import { ArrowUpward } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";

const colorCodes = {
  red : "#f04438",
  green :"#10b981",
  grey : "#808080",
  yellow : "#ffa500",
  blue :"#6366f1"
}

export default function DataCard(props) {
  const {value} = props;
  const [count, setCount] = useState("0".padStart(value?.length,"0"));

  function animatedCounter(){
    let start = 0;
    const end = Math.trunc(value);

    if (start === end) return end;

    function updateCounter() {
      start += Math.ceil((end - start) / 10);

      if (start >= end) {
        start = end;
      }
      setCount(String(start).padStart(end.toString().length, "0"));
      if (start < end) {
        requestAnimationFrame(updateCounter);
      }
  }
    updateCounter();
  }

  useEffect(()=>{
    if(props.value){
      animatedCounter()
    }
  },[props])



  return (
    <Card
      sx={{ height: "150px"}}
      variant="outlined"
      style={{ boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px" }}
    >
      <CardContent>
        <Grid container justifyContent={"space-between"} >
          <Grid item>
            <p style={{fontWeight :"600",color  :"rgb(108, 115, 127)" ,fontSize : "14px"}}>{props.heading}</p>
            <h6 style={{fontSize : "25px",fontWeight :"600"}}>{count || 0}</h6>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                background: colorCodes[props.color] ?? 'rgb(85, 105, 255)',
                marginRight: 1,
                height :"46px",
                width :"46px"
              }}
              
            >
              <props.icon fontSize="small" sx={{ color: "white" }} />
            </Avatar>
          </Grid>
        </Grid>
        <Grid container marginTop={"7px"} gap={"3px"} alignItems={"center"}>
          <span><ArrowUpward fontSize="small" sx={{ color: "rgb(16, 185, 129)" ,marginTop : "6px"}} /></span>
          <span style={{color : "rgb(16, 185, 129)",fontWeight : "500"}}>{props.value ?? 0}%</span> &nbsp;
          <span style={{fontSize : "12px",fontWeight  :"600",color :"rgb(108, 115, 127)"}}>Since last month</span>
        </Grid>
      </CardContent>
    </Card>
  );
}
