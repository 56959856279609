import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Button, MobileStepper, useTheme } from "@mui/material";
import React from "react";


const CustomMobileStepper = ({totalSteps,activeStep,setActiveStep,handleNext,isLoading}) => {
    const theme = useTheme();
  
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

    return ( 
        <>
             <MobileStepper
              variant="text"
              steps={totalSteps}
              position="static"
              activeStep={activeStep}
              handleNext={handleNext}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={isLoading ||  activeStep > totalSteps}
                >
                {activeStep=== totalSteps-1 ? "Submit" : "Next"}
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
        </>
    )
}



export default CustomMobileStepper