import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';
import { styled } from '@mui/system';
import { downloadAttachment } from '../../../utils/common';
import { Download } from '@mui/icons-material';

const GlowingIcon = styled(CheckCircleIcon)(({ theme }) => ({
    fontSize: 60,
    color: '#FFFFFF',  // White color for the tick
    animation: 'glow 0.6s infinite alternate',
    '@keyframes glow': {
      '0%': {
        filter: 'drop-shadow(0 0 10px green)',
      },
      '100%': {
        filter: 'drop-shadow(0 0 2px green)',
      },
    },
  }));

const Makepaymentsuccess = ({paymentdetails}) => {
  const handleDownload = () => {
    
        downloadAttachment(paymentdetails?.link, "Acknowledgement")
        console.log("hellllllsdsdadsahgdahgsd");
  
    console.log('Download acknowledgement');
  };

  return (
    <Card sx={{ maxWidth: 400, margin: 'auto', mt: 3, textAlign: 'center' }}>
      <CardContent>
        
        <Box display="flex" justifyContent="center" mb={2}>
          <GlowingIcon />
        </Box>
        
        <Typography variant="h5" component="div" gutterBottom>
          Payment Successful
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Your payment has been processed successfully.
        </Typography>
        <Button variant="contained" color="success" sx={{ mt: 3 }} startIcon={<Download />} onClick={handleDownload}>
           Acknowledgement
        </Button>
      </CardContent>
    </Card>
  );
};

export default Makepaymentsuccess;

