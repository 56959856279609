// ParticleComponent.jsx
import React from "react";
import styles from "./particle.module.css";

const ParticleComponent = ({children}) => {
  return (
    <div className={styles.space}>
      <div className={styles.particle}></div>
      <div className={styles.particle}></div>
      <div className={styles.particle}></div>
      <div className={styles.particle}></div>
      {children}
    </div>
  );
};

export default ParticleComponent;
